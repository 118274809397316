<template>
    <div class="users" v-if="$can('user_view')">
      <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
        <span>{{ message }}</span>
      </v-snackbar>
      <v-container>
        <v-layout column>
          <div v-if="view == 'default'">
            <v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
              <v-flex xs12 class="mt-10 mb-2">
                <div class="mx-5">
                  <v-layout row wrap>
                    <v-flex xs12 md4> <h1>Users</h1> </v-flex>
                    <v-flex xs12 md4>
                      <div class="pa-1">
                        <v-text-field
                          class="text_field header"
                          outlined
                          dense
                          label="Search"
                          append-icon="mdi-undo-variant"
                          @click:append="resetSearch()"
                          v-on:keyup.enter="search"
                          v-model="searchTerm"
                          @input="enableSearch()"
                        ></v-text-field>
                      </div>
                        
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn
                          class="secondary white--text text-none"
                          depressed
                          block
                          @click="search"
                          :loading="searchLoader"
                          :disabled="searchButtonDisabled"
                        >
                          Search
                          <v-icon right>mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs12 md2>
                      <div class="pa-1">
                        <v-btn
                        block
                          depressed
                          class="primary text-none"
                          @click="changeView('create')"
                          v-if="$can('user_create')"
                        >
                          Add User
                          <v-icon right>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <div class="mx-5 mt-9">
                <v-progress-linear v-if="userLoader" height="1" indeterminate color="primary"></v-progress-linear>
              </div>
              <v-divider class="mx-5"></v-divider>
              <v-flex xs12 class="mt-5">
                <div v-if="users.length == 0">
                  <v-card elevation="0">
                    <v-layout row wrap>
                      <v-flex xs12 md1>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center">
                              <v-icon large class="text--text">
                                mdi-alert-circle-outline
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                      <v-flex xs12 md11>
                        <v-container fill-height fluid>
                          <v-row align="center" justify="center">
                            <v-col class="text-center"> No User Found </v-col>
                          </v-row>
                        </v-container>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </div>
                <div v-else>
                  <div class="hidden-sm-and-down">
                    <v-card elevation="0" class="pa-2">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead class="header">
                            <tr class="tableHeader">
                              <th class="text-left text--text">Pic</th>
                              <th class="text-left text--text">Name</th>
                              <th class="text-left text--text">Email</th>
                              
                              <th class="text-left text--text">Role(s)</th>
                              <th class="text-left text--text">Account Active</th>
                              <th class="text-left text--text">Account Creation Date</th>
                              <th class="text-right text--text">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in users" :key="item.id">
                              <td>
                              <v-avatar size="48" class="primary" v-if="item.prof_pic==null">
                                    <v-icon class="white--text">mdi-account</v-icon>
                                  </v-avatar>
                                  <v-avatar size="48" v-else>
                                    <v-img
                                        max-height="150"
                                        :src="path+'/storage/profile_pics/'+item.prof_pic"
                                      >
                                      </v-img>
                                  </v-avatar>
                                  </td>
                              <td>{{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}</td>
                              <td>{{ item.email }}</td>
                              
                              <td>
                                <div v-if="item.roles.length != 0">
                                  <div v-for="item in item.roles" :key="item.id">
                                    {{ item.name }}
                                  </div>
                                </div>
                                <div v-else>None</div>
                              </td>
                              <td>
                                <div v-if="item.active == 1">Yes</div>
                                <div v-else>No</div>
                              </td>
                              <td>
                                {{
                                  item.created_at | moment("DD/MM/YYYY - hh:mm a")
                                }}
                              </td>
                              <td>
                                <div align="right">
                                  <v-tooltip top v-if="$can('user_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 universal--text"
                                        @click="showUser(item)"
                                        >
                                        <v-icon small> mdi-eye </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>View</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('user_edit')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 success--text"
                                        @click="editUser(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-pencil </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Edit</span>
                                  </v-tooltip>
  
                                  <v-tooltip top v-if="$can('user_archive')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 error--text"
                                        @click="deleteUser(item)"
                                        :loading="loading && userIndex == item.id"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-delete </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>delete</span>
                                  </v-tooltip>
                                  
                                  <v-tooltip top v-if="$can('user_activate') && item.active == 0">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 red--text"
                                        @click="activateUser(item)"
                                        :loading="activateLoading && userIndex == item.id"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch-off-outline </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Activate Account</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('user_deactivate') && item.active == 1">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 green--text"
                                        @click="deactivateUser(item)"
                                        :loading="deactivateLoading && userIndex == item.id"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-toggle-switch </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Deactivate Account</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="$can('log_view')">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        class="button mr-1 purple--text"
                                        @click="viewLog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <v-icon small> mdi-script-text </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Log</span>
                                  </v-tooltip>
  
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                      
                  </div>
                  <div class="hidden-md-and-up">
                    <v-layout column>
                      <template v-for="(user, index) in users">
                        <div :key="index">
                          <v-flex xs12 class="mb-2">
                            <v-card elevation="0">
                              <div class="pa-5">
                                <v-layout column>
                                  <v-flex xs12>
                                    <v-layout row wrap>
                                      <v-flex xs11>
                                        <div class="title primary--text ml-3">
                                          <b>{{ user.first_name }} {{ user.middle_name }} {{ user.last_name }}</b>
                                        </div>
                                      </v-flex>
                                      <v-flex xs1>
                                        <v-btn
                                          depressed
                                          class="red--text"
                                          :loading="
                                            loading && userIndex == user.id
                                          "
                                          icon
                                          @click="deleteUser(user)"
                                          v-if="$can('user_archive')"
                                        >
                                          <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-divider class="mt-2"></v-divider>
                                  <v-flex xs12 class="mt-1">
                                    <v-layout column>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Email Address</b>
                                        </div>
                                        <div>{{ user.email }}</div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Role(s)</b>
                                        </div>
                                        <div>
                                          <div v-if="user.roles.length != 0">
                                            <div v-for="user in user.roles" :key="user.id">
                                              {{ user.name }}
                                            </div>
                                          </div>
                                          <div v-else>None</div>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Account Active</b>
                                        </div>
                                        <div>
                                          <div v-if="user.active == 1">Yes</div>
                                          <div v-else>No</div>
                                        </div>
                                      </v-flex>
                                      <v-flex xs12 class="mt-1">
                                        <div>
                                          <b>Account Creation Date</b>
                                        </div>
                                        <div>
                                          {{
                                            user.created_at
                                              | moment("DD/MM/YYYY - hh:mm a")
                                          }}
                                        </div>
                                      </v-flex>  
                                      <v-flex xs12 class="mt-5">
                                        <v-layout row wrap>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                v-if="$can('user_view')"
                                                depressed
                                                class="
                                                  primary
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="showUser(user)"
                                                >View
                                                <v-icon right> mdi-eye </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="blue text-none white--text"
                                                block
                                                @click="editUser(user)"
                                                v-if="$can('user_edit')"
                                                >Edit
                                                <v-icon right>
                                                  mdi-border-color
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="green text-none white--text"
                                                block
                                                @click="activateUser(user)"
                                                :loading="activateLoading && userIndex == user.id"
                                                v-if="$can('user_activate') && user.active == 0"
                                                >Activate Account
                                                <v-icon right>
                                                  mdi-toggle-switch-off-outline
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                depressed
                                                class="red text-none white--text"
                                                block
                                                @click="deactivateUser(user)"
                                                :loading="deactivateLoading && userIndex == user.id"
                                                v-if="$can('user_deactivate') && user.active == 1"
                                                >Deactivate Account
                                                <v-icon right>
                                                  mdi-toggle-switch
                                                </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                          <v-flex xs12>
                                            <div class="pa-1">
                                              <v-btn
                                                v-if="$can('log_view')"
                                                depressed
                                                class="
                                                  purple
                                                  text-none
                                                  white--text
                                                "
                                                block
                                                @click="viewLog(user)"
                                                >Log
                                                <v-icon right> mdi-script-text </v-icon>
                                              </v-btn>
                                            </div>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </template>
                    </v-layout>
                  </div>
                </div>
              </v-flex>
            </v-card>
  
            <v-layout column class="mt-5">
              <v-flex xs12>
                <div align="center">
                  <v-pagination
                    v-if="length != 0"
                    :length="length"
                    total-visible="10"
                    v-model="userPagination.current_page"
                    @input="changePage()"
                    circle
                  >
                  </v-pagination>
                </div>
              </v-flex>
              <v-flex xs12>
                <div v-if="length != 0" align="center" class="mt-5">
                  <b class="primary--text">Total: </b
                  >{{ userPagination.total | formatNumber }}
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div v-if="view == 'create'">
            <v-flex class="mt-5">
              <v-card outlined>
                <v-card-title class="tableHeader text--text">
                  <v-spacer></v-spacer>
                  <div v-if="newUser.id == null">Add User</div>
                  <div v-else>Edit User</div>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="changeView('default')">
                    <v-icon class="text--text"> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-layout column>
                      <v-flex xs12>
                        <v-layout row wrap class="mt-5">
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>First Name<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.first_name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Middle Name</div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.middle_name"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Last Name<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.last_name"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>
                            Email Address<span class="error--text">*</span>
                          </div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.email"
                            :disabled="lockEmail"
                            :rules="emailRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="pa-3">
                          <div>Phone Number<span class="error--text">*</span></div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.phone_number"
                            :rules="inputRules"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4 v-if="newUser.id == null">
                        <div class="pa-3">
                          <div>Password*</div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.password"
                            :rules="passwordRules"
                            append-icon="mdi-autorenew"
                            @click:append="generate()"
                          ></v-text-field>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4 v-if="newUser.id != null">
                        <div class="pa-3">
                          <div>Password</div>
                          <v-text-field
                            class="text_field background"
                            outlined
                            dense
                            v-model="newUser.password"
                            append-icon="mdi-autorenew"
                            @click:append="generate()"
                          ></v-text-field>
                          <div>
                            <i
                              >**
                              (leaveBlankToAvoidUnintentionallyUpdatingTheUsersPasswordMsg"
                              )
                            </i>
                          </div>
                        </div>
                      </v-flex>
                    </v-layout>
                      </v-flex>
                      <v-flex xs12 class="mt-5">
                        Role(s)
                      </v-flex>
                      <v-flex xs12 class="mx-3">
                        <v-layout row wrap>
                          <template v-for="(role, index) in formData.roles">
                            <v-flex xs12 md3 :key="index">
                              <v-checkbox
                                v-model="newUser.roles"
                                :value="role.id"
                                :label="role.name"
                              ></v-checkbox>
                            </v-flex>
                          </template>
                        </v-layout>                         
                      </v-flex>
                    </v-layout>
                    
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    class="primary text-none mt-3"
                    @click="save"
                    :disabled="!valid"
                    :loading="loading"
                  >
                    Save
                    <v-icon right> mdi-content-save </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </div>
          <div v-if="view == 'show'">
            <v-card outlined class="mt-5">
              <v-card-title class="tableHeader text--text">
                {{ assignedUser.first_name }} {{ assignedUser.middle_name }} {{ assignedUser.last_name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="pa-3 mt-5">
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div><b>Email:</b></div>
                        </v-flex>
                        <v-flex xs12 md9>
                          {{assignedUser.email}}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div><b>Phone Number:</b></div>
                        </v-flex>
                        <v-flex xs12 md9>
                          {{assignedUser.phone}}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div><b>Account Creation Date:</b></div>
                        </v-flex>
                        <v-flex xs12 md9>
                          {{assignedUser.created_at | moment("DD/MM/YYYY - hh:mm a")}}
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div><b>Account Active:</b></div>
                        </v-flex>
                        <v-flex xs12 md9>
                          <div v-if="assignedUser.active == 1">Yes</div>
                          <div v-else>No</div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-5">
                      <v-layout row wrap>
                        <v-flex xs12 md3>
                          <div><b>Role(s):</b></div>
                        </v-flex>
                        <v-flex xs12 md9>
                          <div v-if="assignedUser.roles.length != 0">
                            <div v-for="item in assignedUser.roles" :key="item.id">
                              {{ item.name }}
                            </div>
                          </div>
                          <div v-else>None</div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-layout>
      </v-container>
      <logData v-if="logDataModel" v-model="logDataModel" />
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  import logData from "@/views/accessControl/logData";
  import Vue from "vue";
  var numeral = require("numeral");
  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
  });
  
  Vue.use(require("vue-moment"));
  
  export default {
    components: {
      logData,
    },
    data() {
      return {
        path: process.env.VUE_APP_API_URL,
        view: "default",
        loading: false,
        activateLoading: false,
        deactivateLoading: false,
  
        valid: true,
        message: "",
        color: "",
        delete: false,
        activate: false,
        confirm: false,
        lockEmail: false,
        userIndex: null,
        snackbar: false,
        inputRules: [
          (v) => !!v || "Input is required"
        ],
        emailRules: [
          (v) => !!v || "Input is required",
          (v) =>
            !v ||
            /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
              v
            ) ||
            "E-mail must be valid",
          (v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 8) || "Minimum 8 characters",
          (v) =>
            /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
          (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
          (v) =>
            /([!@$%.])/.test(v) ||
            "Must have at least one special character [!@#$%.]",
        ],
        searchTerm: null,
        searchLoader: false,
        searchButtonDisabled: false,
        filteredUsers: false,
        newUser: {
          id: null,
          first_name: null,
          middle_name: null,
          last_name: null,
          phone_number: null,
          email: null,        
          password: null,
          roles: []
        },
        assignedUser: null,
        size: 10,
        characters: "a-z,A-Z,0-9,#",
        formData: null,
        logData: {
          message: null,
          model: "App\\Models\\User",
        },
  
      };
    },
    created() {
      if (this.$can("user_view")) {
        this.startUserLoader();
        this.fetchUsers(this.userPagination.current_page);
        this.getFormData();
      }
    },
    methods: {
      ...mapActions([
        "fetchUsers",
        "startUserLoader",
        "stopUserLoader",
        "filterUsers",
  
        "filterLogData",
        "openLogDataModel",
      ]),
      enableSearch() {
        this.searchButtonDisabled = false;
      },
      resetSearch() {
        this.searchTerm = null;
        this.filteredUsers = true;
        this.startUserLoader();
        this.fetchUsers(1);
      },
      showUser(user) {
        this.assignedUser = user;
        this.changeView("show");
      },
      editUser(user) {
        this.lockEmail = true;
        this.newUser.id = user.id;
        this.newUser.first_name = user.first_name;
        this.newUser.middle_name = user.middle_name;
        this.newUser.last_name = user.last_name;
        this.newUser.phone_number = user.phone;
        this.newUser.email = user.email;
  
        if (user.roles.length != 0) {
          for (let i = 0; i < user.roles.length; i++) {
            this.newUser.roles.push(user.roles[i].id)
          }
        }
          
        this.changeView("create");
      },
      viewLog(data) {
        this.logData.message = data.id;
        this.filterLogData(this.logData);
        this.openLogDataModel();
      },
  
      save() {
        if (this.$refs.form.validate()) {
          if (this.newUser.id != null) {
            confirm("Are You Sure You Want to Update User") && (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/user/" + this.newUser.id,
                data: this.newUser,
                method: "PUT",
              })
                .then((resp) => {
                  this.message = "User Updated Successfully";
                  this.color = "orange";
                  this.loading = false;
                  this.snackbar = true;
                  this.confirm = false;
                  this.changeView("default");
                  this.fetchUsers(this.userPagination.current_page);
                })
                .catch((error) => {
                  this.message = "An Error Occurred";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                });
            }
          } else {
            confirm("Are You Sure You Want to Create User?") && (this.confirm = true);
            if (this.confirm) {
              this.loading = true;
              apiCall({
                url: "/api/user",
                data: this.newUser,
                method: "POST",
              })
                .then((resp) => {
                  if(resp.status == 409){
                    this.message = resp.message
                    this.color = "error";
                    this.loading = false;
                    this.snackbar = true;
                    this.alertType = "error";
                  }else{
                    this.message = "User Added Successfully";
                    this.color = "success";
                    this.loading = false;
                    this.snackbar = true;
                    this.changeView("default");
                    this.fetchUsers(1);
                  }
                })
                .catch((error) => {
                  this.message = "An Error Occurred Email";
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchUsers(1);
                });
            }
          }
        }
      },
      search() {
        if (this.searchTerm == null) {
          this.searchButtonDisabled = true;
        } else {
          this.searchLoader = true;
          this.searchButtonDisabled = false;
          this.startUserLoader();
          apiCall({
            url: "/api/user?type=search&search=" + this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterUsers(resp);
              this.searchLoader = false;
              this.stopUserLoader();
              this.filteredUsers = true;
            })
            .catch((error) => {
              console.log(error.response);
              this.searchLoader = false;
              this.stopUserLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        }
      },
      changeView(view) {
        if (view == "default") {
          this.lockEmail = false;
          this.newUser.id = null;
          this.newUser.first_name = null;
          this.newUser.middle_name = null;
          this.newUser.last_name = null;
          this.newUser.phone_number = null;
          this.newUser.email = null;
          this.newUser.password = null;
          this.newUser.roles = [];
          
        } else if (view == "create") {
          this.getFormData();
        } else if (view == "show") {
        }
        this.view = view;
      },
      changePage() {
        this.startUserLoader();
        if (this.filteredUsers == true) {
          this.searchButtonDisabled = false;
          apiCall({
            url:
              "/api/user?page=" +
              this.userPagination.current_page +
              "&type=search&search=" +
              this.searchTerm,
            method: "GET",
          })
            .then((resp) => {
              this.filterUsers(resp);
              this.stopUserLoader();
            })
            .catch((error) => {
              this.stopUserLoader();
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
            });
        } else {
          this.fetchUsers(this.userPagination.current_page);
        }
      },
      deleteUser(item) {
        confirm("Are You Sure You Want to Delete User?") && (this.delete = true);
        if (this.delete) {
          this.loading = true;
          this.userIndex = item.id;
          apiCall({ url: "/api/user/" + item.id, method: "DELETE" })
            .then((resp) => {
              this.message = "User Deleted Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.userIndex = null;
              this.fetchUsers(this.userPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.loading = false;
              this.delete = false;
              this.userIndex = null;
              console.log(error.response);
            });
        }
      },
      activateUser(item) {
        confirm("Are You Sure You Want to Activate User?") && (this.activate = true);
        if (this.activate) {
          this.activateLoading = true;
          this.userIndex = item.id;
          this.newUser.id = item.id;
          apiCall({ url: "/api/user?type=activate", 
            data: this.newUser,
            method: "POST" })
            .then((resp) => {
              this.message = "User Account Activated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.userIndex = null;
              this.fetchUsers(this.userPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.activateLoading = false;
              this.activate = false;
              this.userIndex = null;
              console.log(error.response);
            });
        }
      },
      deactivateUser(item) {
        confirm("Are You Sure You Want to Deactivate User?") && (this.activate = true);
        if (this.activate) {
          this.deactivateLoading = true;
          this.userIndex = item.id;
          this.newUser.id = item.id;
          apiCall({ url: "/api/user?type=deactivate", 
            data: this.newUser,
            method: "POST" })
            .then((resp) => {
              this.message = "User Account Deactivated Succesfully";
              this.color = "success";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.userIndex = null;
              this.fetchUsers(this.userPagination.current_page);
            })
            .catch((error) => {
              this.message = "An Error Occurred";
              this.color = "error";
              this.snackbar = true;
              this.deactivateLoading = false;
              this.activate = false;
              this.userIndex = null;
              console.log(error);
            });
        }
      },
      getFormData() {
        apiCall({
          url: "/api/user?type=adminFormData",
          method: "GET",
        })
          .then((resp) => {
            this.formData = resp;
          })
          .catch((error) => {
            this.message = "An Error Occurred; Couldn't Get Form Data";
            this.color = "error";
          });
      },
      generate() {
        let charactersArray = this.characters.split(",");
        let CharacterSet = "";
        let password = "";
  
        if (charactersArray.indexOf("a-z") >= 0) {
          CharacterSet += "abcdefghijklmnopqrstuvwxyz";
        }
        if (charactersArray.indexOf("A-Z") >= 0) {
          CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }
        if (charactersArray.indexOf("0-9") >= 0) {
          CharacterSet += "0123456789";
        }
        if (charactersArray.indexOf("#") >= 0) {
          CharacterSet += "![]{}()%&*$#^<>~@|";
        }
  
        for (let i = 0; i < this.size; i++) {
          password += CharacterSet.charAt(
            Math.floor(Math.random() * CharacterSet.length)
          );
        }
        this.newUser.password = password;
      },
    },
    computed: {
      ...mapGetters(["users", "userPagination", "userLoader", "logDataModel"]),
      length: function () {
        return Math.ceil(
          this.userPagination.total / this.userPagination.per_page
        );
      },
    },
  };
  </script>
  