<template>
    <v-card id="create">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
      >
        <template v-slot:activator>
          <v-btn large v-model="fab" color="#e4b417" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-question-outline </v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab dark color="green"
              v-bind="attrs"
              v-on="on"
              router to="/faqs"
            >
              <v-icon>mdi-frequently-asked-questions</v-icon>
            </v-btn>
          </template>
          <span>FAQs</span>
      </v-tooltip>
  
      <v-tooltip left v-if="$can('contact_us_create')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab dark color="indigo"
            v-bind="attrs"
            v-on="on"
            @click="changeContactCardState"
          >
            <v-icon>mdi-forum</v-icon>
          </v-btn>
        </template>
          <span>Contact Us</span>
      </v-tooltip>
      </v-speed-dial>
    </v-card>
  </template>
  <style>
    /* This is for documentation purposes and will not be needed in your application */
    #create .v-speed-dial {
      position: fixed;
    }
  
    #create .v-btn--floating {
      position: fixed;
    }
  </style>
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    data: () => ({
      direction: "top",
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",
    }),
  
    watch: {
      top(val) {
        this.bottom = !val;
      },
      right(val) {
        this.left = !val;
      },
      bottom(val) {
        this.top = !val;
      },
      left(val) {
        this.right = !val;
      },
    },
    methods: {
      ...mapActions([
        "changeContactCardState"
      ]),
    },
  
  };
  </script>
  