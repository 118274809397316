<template>
	<div class="newsFeeds" v-if="$can('news_feed_view')">
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="imageDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload Image
					<v-spacer></v-spacer>
					<v-btn icon @click="imageDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="imageForm" v-model="imageValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-2 mt-3" :loading="imageLoading" :rules="fileRules" filled dense show-size chips
								accept="image/*" v-model="imageFile" label="Select Image"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadImage" :loading="imageLoading"
									:disabled="!imageValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>News Feed</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()" v-on:keyup.enter="search"
												v-model="searchTerm" @input="enableSearch()"></v-text-field>
										</div>

									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block @click="search"
												:loading="searchLoader" :disabled="searchButtonDisabled">
												Search
												<v-icon right>mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none" @click="changeView('create')"
												v-if="$can('news_feed_create')">
												Add News Feed
												<v-icon right>mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="newsFeedLoader" height="1" indeterminate color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="newsFeeds.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No News Item(s) Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">#</th>
														<th class="text-left text--text">Subject</th>
														<th class="text-left text--text">Author</th>
														<th class="text-left text--text">Publish Date</th>
														<th class="text-left text--text">Active</th>
														<th class="text-left text--text">Posted By</th>
														<th class="text-left text--text">Date Added</th>														
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in newsFeeds" :key="item.id">
														<td>
															{{ index + 1 }}
														</td>
														<td>{{ item.subject }} </td>
														<td>{{ item.author }}</td>
														<td>{{ item.publish_date }}</td>
														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<td>
															{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}
														</td>
														<td>
															{{
																item.created_at | moment("DD/MM/YYYY - hh:mm a")
															}}
														</td>
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('news_feed_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text" @click="showNewsFeed(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('news_feed_edit')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text" @click="editNewsFeed(item)"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('news_feed_archive')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text" @click="deleteNewsFeed(item)"
																			:loading="loading && newsFeedIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('news_feed_activate') && item.active == 0">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text" @click="activateNewsFeed(item)"
																			:loading="activateLoading && newsFeedIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate NewsFeed</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('news_feed_deactivate') && item.active == 1">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text" @click="deactivateNewsFeed(item)"
																			:loading="deactivateLoading && newsFeedIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate NewsFeed</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>

										<template v-for="(newsFeed, index) in newsFeeds">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title primary--text ml-3">
																				<b>{{ newsFeed.subject }} </b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && newsFeedIndex == newsFeed.id
		" icon @click="deleteNewsFeed(newsFeed)" v-if="$can('news_feed_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Author</b>
																			</div>
																			<div>{{ newsFeed.author }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Publish Date</b>
																			</div>
																			<div>{{ newsFeed.publish_date }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="newsFeed.active == 1">Yes
																				</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Posted By</b>
																			</div>
																			<div>{{ newsFeed.user.first_name }} {{ newsFeed.user.middle_name }} {{ newsFeed.user.last_name }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Date Added</b>
																			</div>
																			<div>
																				{{
																					newsFeed.created_at
																					| moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('news_feed_view')" depressed class="
																							primary
																							text-none
																							white--text
																							" block @click="showNewsFeed(newsFeed)">View
																							<v-icon right> mdi-eye
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="blue text-none white--text" block
																							@click="editNewsFeed(newsFeed)" v-if="$can('news_feed_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="green text-none white--text" block
																							@click="activateNewsFeed(newsFeed)"
																							:loading="activateLoading && newsFeedIndex == newsFeed.id"
																							v-if="$can('news_feed_activate') && newsFeed.active == 0">Activate
																							NewsFeed
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="red text-none white--text" block
																							@click="deactivateNewsFeed(newsFeed)"
																							:loading="deactivateLoading && newsFeedIndex == newsFeed.id"
																							v-if="$can('news_feed_deactivate') && newsFeed.active == 1">Deactivate
																							NewsFeed
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')" depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(newsFeed)">Log
																							<v-icon right>
																								mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="newsFeedPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ newsFeedPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newNewsFeed.id == null">Add News Item</div>
								<div v-else>Edit News Item</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-1">
												<v-flex xs12>
													<div class="mx-1 mt-5">
														<div>Subject<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense v-model="newNewsFeed.subject"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md5>
													<div class="mx-1 mt-5">
														<div>Author</div>
														<v-text-field class="text_field background" outlined dense v-model="newNewsFeed.author"
														></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md5>
													<div class="mx-1 mt-5">
														<div>Source</div>
														<v-text-field class="text_field background" outlined dense v-model="newNewsFeed.source"
														></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md2>
													<div class="mx-1 mt-5">
														<div>Publish Date</div>
														<v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
															v-model="dateOfPublishing" offset-y>
															<template v-slot:activator="{ on, attrs }">
																<v-text-field outlined dense :value="formatDateOfPublishing"
																	slot="activator" readonly v-bind="attrs" v-on="on" class="text_field background"
																	prepend-inner-icon="mdi-calendar"></v-text-field>
															</template>
															<v-date-picker v-model="newNewsFeed.publish_date" :max="dateToday"
																@change="closeDateOfPublishing">
															</v-date-picker>
														</v-menu>
													</div>
												</v-flex>
												<v-flex xs12>
													<div class="mx-1 mt-5">
														<div>Body<span class="error--text">*</span></div>
														<div class="black--text">
															<ckeditor :editor="editor" v-model="newNewsFeed.body" :config="editorConfig"
																:rules="inputRules">
															</ckeditor>
														</div>
													</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedNewsFeed.subject }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2 pa-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<v-layout column>
											<v-flex xs12>
												<div align="center" class="mt-4">
													<div size="200" class="title white--text mx-10" v-if="assignedNewsFeed.image == null">
														<v-card elevation="0" class="mx-1 my-1 primary"
															style="border-radius: 25px;" height="192"
															>
															<div
																class="d-flex flex-column fill-height justify-center align-center text-white">
																<v-icon large
																	color="white">mdi-image-plus</v-icon>
															</div>
														</v-card>													
													</div>
													<div v-else align="center">
														<v-img max-width="290" :src="path + '/storage/news_feed_pics/' + assignedNewsFeed.image"/>
													</div>
												</div>
											</v-flex>
											<v-flex xs12>
												<div align="center">
													<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
														Upload Cover Image
													</v-btn>
												</div>
											</v-flex>
										</v-layout>
									</v-flex>
									<v-flex xs12 md8>
										<v-layout row wrap class="mt-1">
											<v-flex xs12 md6>
												<v-layout column>
													<v-flex xs12 class="mt-5">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div><b>Subject:</b></div>
															</v-flex>
															<v-flex xs12 md9>
																{{ assignedNewsFeed.subject }}
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-5">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div><b>Author:</b></div>
															</v-flex>
															<v-flex xs12 md9>
																{{ assignedNewsFeed.author }}
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-5">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div><b>Active:</b></div>
															</v-flex>
															<v-flex xs12 md9>
																<div v-if="assignedNewsFeed.active == 1">Yes</div>
																<div v-else>No</div>
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 md6>
												<v-layout column>
													<v-flex xs12 class="mt-5">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div><b>Publish Date:</b></div>
															</v-flex>
															<v-flex xs12 md9>
																{{ assignedNewsFeed.publish_date }}
															</v-flex>
														</v-layout>
													</v-flex>
													<v-flex xs12 class="mt-5">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div><b>Posted By:</b></div>
															</v-flex>
															<v-flex xs12 md9>
																{{ assignedNewsFeed.user.first_name }} {{ assignedNewsFeed.user.middle_name }} {{ assignedNewsFeed.user.last_name }}
															</v-flex>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-10">
												<v-layout row wrap>
													<v-flex xs12>
														<div><b>Body</b></div>
													</v-flex>
													<v-flex xs12>
														<div v-html="assignedNewsFeed.body"></div>
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>

							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			activeTab: 'details',
			saleTab: null,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			albumLoader: false,
			imageLoader: false,

			albumDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			newsFeedIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			emailRules: [
				(v) => !!v || "Input is required",
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
						v
					) ||
					"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			images: [],

			searchTerm: null,

			searchLoader: false,

			searchButtonDisabled: false,

			filteredNewsFeeds: false,

			newNewsFeed: {
				id: null,
				subject: null,
				author: null,
				publish_date: null,
				body: null,
				source: null
			},

			assignedNewsFeed: null,

			formData: null,
			dateOfPublishing: false,
			logData: {
				message: null,
				model: "App\\Models\\NewsFeed",
			},
		};
	},
	created() {
		if (this.$can("news_feed_view")) {
			this.startNewsFeedLoader();
			this.fetchNewsFeeds(this.newsFeedPagination.current_page);
			// this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchNewsFeeds",
			"startNewsFeedLoader",
			"stopNewsFeedLoader",
			"filterNewsFeeds",

			"filterLogData",
			"openLogDataModel",
		]),
		closeAlbumDate() {
			this.albumDatePicker = false;
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},

		resetSearch() {
			this.searchTerm = null;
			this.filteredNewsFeeds = true;
			this.startNewsFeedLoader();
			this.fetchNewsFeeds(1);
		},

		showNewsFeed(newsFeed) {
			this.assignedNewsFeed = newsFeed;

			this.changeView("show");
		},
		editNewsFeed(newsFeed) {
			this.newNewsFeed.id = newsFeed.id;
			this.newNewsFeed.subject = newsFeed.subject;
			this.newNewsFeed.author = newsFeed.author;
			this.newNewsFeed.publish_date = newsFeed.publish_date;
			this.newNewsFeed.body = newsFeed.body;
			this.newNewsFeed.source = newsFeed.source;

			this.changeView("create");
		},

		closeDateOfPublishing() {
			this.dateOfPublishing = false;
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				formData.append("id", this.assignedNewsFeed.id)
				formData.append("files", this.imageFile, this.imageFile.name);

				apiCall({
					url: "/api/news-feed?type=image",
					data: formData,
					method: "POST",
				})
					.then((resp) => {
						this.snackbar = true;
						this.message = "Image Uploaded Successfully";
						this.color = "success";
						this.fetchNewsFeeds(this.newsFeedPagination.current_page);
						this.imageLoading = false;
						this.imageDialog = false;
						this.imageFile = null

						this.assignedNewsFeed = resp
					})
					.catch((error) => {
						this.imageLoading = false;
					});
			}
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newNewsFeed.id != null) {
					if(this.newNewsFeed.body == null){
						this.message = "Please add a Body";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are You Sure You Want to Update News Item") && (this.confirm = true);
						if (this.confirm) {
							this.loading = true;
							apiCall({
								url: "/api/news-feed/" + this.newNewsFeed.id,
								data: this.newNewsFeed,
								method: "PUT",
							})
								.then((resp) => {
									this.message = "NewsFeed Updated Successfully";
									this.color = "orange";
									this.loading = false;
									this.snackbar = true;
									this.confirm = false;
									this.changeView("default");
									this.fetchNewsFeeds(this.newsFeedPagination.current_page);
								})
								.catch((error) => {
									this.message = "An Error Occurred";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
								});
						}
					}					
				} else {
					if(this.newNewsFeed.body == null){
						this.message = "Please add a Body";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are You Sure You Want to Add News Item?") && (this.confirm = true);
						if (this.confirm) {
							this.loading = true;
							apiCall({
								url: "/api/news-feed",
								data: this.newNewsFeed,
								method: "POST",
							})
								.then((resp) => {
									if (resp.status == 409) {
										this.message = resp.message
										this.color = "error";
										this.loading = false;
										this.snackbar = true;
										this.alertType = "error";
									} else {
										this.message = "NewsFeed Added Successfully";
										this.color = "success";
										this.loading = false;
										this.snackbar = true;
										this.changeView("default");
										this.fetchNewsFeeds(1);
									}
								})
								.catch((error) => {
									this.message = "An Error Occurred Email";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchNewsFeeds(1);
								});
						}
					}
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startNewsFeedLoader();
				apiCall({
					url: "/api/news-feed?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterNewsFeeds(resp);
						this.searchLoader = false;
						this.stopNewsFeedLoader();
						this.filteredNewsFeeds = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopNewsFeedLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.newNewsFeed.id = null;
				this.newNewsFeed.subject = null;
				this.newNewsFeed.author = null;
				this.newNewsFeed.publish_date = null;
				this.newNewsFeed.body = null;
				this.newNewsFeed.source = null;
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},

		changePage() {
			this.startNewsFeedLoader();
			if (this.filteredNewsFeeds == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/news-feed?page=" +
						this.newsFeedPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterNewsFeeds(resp);
						this.stopNewsFeedLoader();
					})
					.catch((error) => {
						this.stopNewsFeedLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchNewsFeeds(this.newsFeedPagination.current_page);
			}
		},

		deleteNewsFeed(item) {
			confirm("Are You Sure You Want to Delete NewsFeed?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.newsFeedIndex = item.id;
				apiCall({ url: "/api/news-feed/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "NewsFeed Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.newsFeedIndex = null;
						this.fetchNewsFeeds(this.newsFeedPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.newsFeedIndex = null;
						console.log(error.response);
					});
			}
		},

		activateNewsFeed(item) {
			confirm("Are You Sure You Want to Activate NewsFeed?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.newsFeedIndex = item.id;
				this.newNewsFeed.id = item.id;
				apiCall({
					url: "/api/news-feed?type=activate",
					data: this.newNewsFeed,
					method: "POST"
				})
					.then((resp) => {
						this.message = "NewsFeed Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.newsFeedIndex = null;
						this.newNewsFeed.id = null
						this.fetchNewsFeeds(this.newsFeedPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.newsFeedIndex = null;
						this.newNewsFeed.id = null
						console.log(error.response);
					});
			}
		},
		deactivateNewsFeed(item) {
			confirm("Are You Sure You Want to Deactivate NewsFeed?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.newsFeedIndex = item.id;
				this.newNewsFeed.id = item.id;
				apiCall({
					url: "/api/news-feed?type=deactivate",
					data: this.newNewsFeed,
					method: "POST"
				})
					.then((resp) => {
						this.message = "NewsFeed  Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.newsFeedIndex = null;
						this.fetchNewsFeeds(this.newsFeedPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.newsFeedIndex = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/news-feed?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["newsFeeds", "newsFeedPagination", "newsFeedLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.newsFeedPagination.total / this.newsFeedPagination.per_page
			);
		},
		formatDateOfPublishing() {
			if (this.newNewsFeed.publish_date != null) {
				const d = new Date(this.newNewsFeed.publish_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>