import apiCall from '@/utils/api'

const state = {
	questions: [],
	allQuestions: [],
    questionPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    questionLoader: false
};

const getters = {
	questions: (state) => state.questions,
	allQuestions: (state) => state.allQuestions,
    questionLoader: (state) => state.questionLoader,
    questionPagination: (state) => state.questionPagination
}

const actions = {
	async fetchAllQuestions({commit}, page) {
		const response = await apiCall({url: `/api/question?type=all`, method: 'GET' });
		commit('setAllQuestions', response)
        commit('stopLoader', response)
	},
    async fetchQuestions({commit}, page) {
		const response = await apiCall({url: `/api/question?page=${page}`, method: 'GET' });
		commit('setQuestions', response)
        commit('stopLoader', response)
	},
    async filterQuestions({commit, state},resp){
		commit('setQuestions', resp)
	},
	async startQuestionLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopQuestionLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setQuestions: (state, questions) => {
		state.questions = questions.data
		state.questionPagination.current_page = questions.current_page
		state.questionPagination.total = questions.total
		state.questionPagination.per_page = questions.per_page
	},
	setAllQuestions: (state, questions) => {
		state.allQuestions = questions
	},
    startLoader: (state) => state.questionLoader = true,
	stopLoader: (state) => state.questionLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
