<template>
    <div class="welcome" v-if="$can('dashboard_view')">
      <v-layout column>
        <v-flex xs12>
          <v-card outlined>
            <v-progress-linear
              color="primary"
              height="2"
              indeterminate
              v-if="quickStatsLoader"
            ></v-progress-linear>
  
            <v-container>
              <v-layout column>
                <v-flex xs12 v-if="quickStats.length!=0" class="mb-16">
                    <div align="center" class="my-5">
                            <v-layout row wrap>
                                <v-flex xs6>
                                    <div align="right" class="mr-2 mt-1">
                                        <v-img max-width="200" src="img/logo.png"> </v-img>
                                    </div>
                                </v-flex>
                                <v-flex xs6>
                                    <div align="left" class="ml-2">
                                        <v-img max-width="192" src="img/strathmore-logo-black.png"> </v-img>
                                    </div>
                                </v-flex>
                            </v-layout>														
                        </div>
                    <div class="hidden-md-and-up">
                        <div align="center" class="title font-weight-black ml-7 mr-7 mt-5" style="font-family: 'SC Prosper Sans Regular';">
                            <v-layout column>
                                <v-flex xs12>
                                    <b>{{ quickStats.active_program.name }} Program Statistics</b>
                                </v-flex>
                                <v-flex xs12 class="mt-8">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4></v-flex>
                                        <v-flex xs12 md4>
                                            <v-divider class="primary mx-16"></v-divider>
                                            <v-divider class="secondary mx-16"></v-divider>
                                        </v-flex>
                                        <v-flex xs12 md4></v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>														
                        </div>
                        <div style="margin-top: -70px">
                        <!-- <v-img width="30px" src="img/a-1.png">
                        </v-img> -->
                        </div>
                        <div align="right" style="margin-top: -18px">
                        <!-- <v-img width="30px" src="img/a-2.png">
                        </v-img> -->
                        </div>
                    </div>
                    <div class="hidden-sm-and-down">
                        <div align="center" class="display-1 font-weight-black ml-7 mr-7 mt-8" style="font-family: 'SC Prosper Sans Regular';">
                            <v-layout column>
                                <v-flex xs12>
                                    <b>{{ quickStats.active_program.name }} Program Statistics</b>
                                </v-flex>
                                <v-flex xs12 class="mt-8">
                                    <v-layout row wrap>
                                        <v-flex xs12 md4></v-flex>
                                        <v-flex xs12 md4>
                                            <v-divider class="primary mx-16"></v-divider>
                                            <v-divider class="secondary mx-16"></v-divider>
                                        </v-flex>
                                        <v-flex xs12 md4></v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>														
                        </div>
                        <div style="margin-top: -60px">
                        <!-- <v-img width="30px" src="img/a-1.png">
                        </v-img> -->
                        </div>
                        <div align="right" style="margin-top: -16px">
                        <!-- <v-img width="30px" src="img/a-2.png">
                        </v-img> -->
                        </div>
                    </div>
                </v-flex>
                <v-flex xs12 class="mt-16">
                    <v-layout row wrap class="my-16"> 
                      <v-flex xs12 md12>
                        <div align="center" class="ml-7">
                          Business Sectors Applications Analysis
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="405"
                            type="bar"
                            :options="ventureBSAChartOptions"
                            :series="ventureBSASeries"
                          ></apexchart>
                        </div>
                      </v-flex>

                      <v-flex xs12 md12 class="my-16">
                        <div align="center" class="ml-7">
                          Business Category Applications Analysis
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="405"
                            type="bar"
                            :options="ventureBCAChartOptions"
                            :series="ventureBCASeries"
                          ></apexchart>
                        </div>
                      </v-flex>

                      <v-flex xs12 md12 class="my-16">
                        <div align="center" class="ml-7">
                          Business Sector & Category Analysis
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="405"
                            type="bar"
                            :options="ventureBSCChartOptions"
                            :series="ventureBSCSeries"
                          ></apexchart>
                        </div>
                      </v-flex>
                      
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 md6>
                        <div align="center" class="ml-7">
                          User Application Journeys (<b>Total:</b> {{ quickStats.total_users }})
                        </div>
                        <div class="mx-3">
                          <apexchart
                            v-if="showChart"
                            ref="innovationChart"
                            width="100%"
                            height="405"
                            type="bar"
                            :options="userChartOptions"
                            :series="userSeries"
                          ></apexchart>
                        </div>
                      </v-flex>     
                      <v-flex xs12 md3>
                        <div align="center" class="ml-7">
                          Applicant Genders
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="450"
                            type="pie"
                            :options="userGendersChartOptions"
                            :series="userGendersSeries"
                          >
                          </apexchart>
                        </div>
                      </v-flex> 
                      <v-flex xs12 md3>
                        <div align="center" class="ml-7">
                          Applicant Nationalities
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="450"
                            type="pie"
                            :options="userCountriesChartOptions"
                            :series="userCountriesSeries"
                          >
                          </apexchart>
                        </div>
                      </v-flex>
                      
                    </v-layout>
                    <v-layout row wrap>   
                        <v-flex xs12 md6 class="mt-16">
                        <div align="center" class="ml-7">
                          Sectors
                        </div>
                        <div class="mx-3">
                          <apexchart
                            v-if="showChart"
                            ref="innovationChart"
                            width="100%"
                            height="405"
                            type="bar"
                            :options="ventureSectorChartOptions"
                            :series="ventureSectorSeries"
                          ></apexchart>
                        </div>
                      </v-flex>

                      <v-flex xs12 md4>
                        <div align="center" class="ml-7">
                          Marketing Source
                        </div>
                        <div class="mx-3">
                          <apexchart
                            width="100%"
                            height="450"
                            type="pie"
                            :options="ventureMarketingSourceChartOptions"
                            :series="ventureMarketingSourceSeries"
                          >
                          </apexchart>
                        </div>
                      </v-flex>
                      
                    </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import apiCall from "@/utils/api";
  import { mapGetters, mapActions } from "vuex";
  import Vue from "vue";
  
  import VueApexCharts from "vue-apexcharts";
  Vue.use(VueApexCharts);
  
  Vue.component("apexchart", VueApexCharts);
  
  export default {
    components: {
      
    },
    data() {
      return {
        showChart: true,
        path: process.env.VUE_APP_API_URL,
        color: "",
        message: "",
        snackbar: false,
  
        userChartOptions: {
          xaxis: {
            categories: [],
          },
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%",
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          grid: {
            row: {
              colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
        },
        userSeries: [],

        ventureSectorChartOptions: {
          xaxis: {
            categories: [],
          },
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%",
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          grid: {
            row: {
              colors: ['#FFEDED', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
        },
        ventureSectorSeries: [],
  
        userCountriesChartOptions: {
          chart: {
            id: "attendance-ratio",
            width: "100%",
            type: "pie",
          },
          labels: [],
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 1,
            colors: undefined,
          },
          yaxis: {
            show: true,
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0,
              },
              spokes: {
                strokeWidth: 0,
              },
            },
          },
        },
        userCountriesSeries: [],

        userGendersChartOptions: {
          chart: {
            id: "attendance-ratio",
            width: "100%",
            type: "pie",
          },
          labels: [],
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 1,
            colors: undefined,
          },
          yaxis: {
            show: true,
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0,
              },
              spokes: {
                strokeWidth: 0,
              },
            },
          },
        },
        userGendersSeries: [],

        ventureBSAChartOptions: {
            chart: {
              type: "bar",
              height: "400px",
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              width: 2,
            },
            grid: {
              row: {
                colors: ["#fff", "#f2f2f2"],
              },
            },
            xaxis: {
              rotate: -45,
              title: {
                text: "Sector",
              },
            },
            yaxis: {
              title: {
                text: "No of Applications",
              },
              labels: {
                formatter: function (val, index) {
                  return Number(val).toLocaleString();
                },
              },
            },
          },

          ventureBCAChartOptions: {
            chart: {
              type: "bar",
              height: "400px",
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              width: 2,
            },
            grid: {
              row: {
                colors: ["#fff", "#f2f2f2"],
              },
            },
            xaxis: {
              rotate: -45,
              title: {
                text: "Category",
              },
            },
            yaxis: {
              title: {
                text: "No of Applications",
              },
              labels: {
                formatter: function (val, index) {
                  return Number(val).toLocaleString();
                },
              },
            },            
          },

          ventureBSCChartOptions: {
            chart: {
              type: "bar",
              height: "400px",
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              width: 2,
            },
            grid: {
              row: {
                colors: ["#fff", "#f2f2f2"],
              },
            },
            xaxis: {
              rotate: -45,
              title: {
                text: "Category",
              },
            },
            yaxis: {
              title: {
                text: "No of Applications",
              },
              labels: {
                formatter: function (val, index) {
                  return Number(val).toLocaleString();
                },
              },
            },              
          },




        ventureMarketingSourceChartOptions: {
          chart: {
            id: "attendance-ratio",
            width: "100%",
            type: "pie",
          },
          labels: [],
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 1,
            colors: undefined,
          },
          yaxis: {
            show: true,
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0,
              },
              spokes: {
                strokeWidth: 0,
              },
            },
          },
        },
        ventureMarketingSourceSeries: [],

        ventureStepChartOptions: {
          chart: {
            id: "attendance-ratio",
            width: "100%",
            type: "pie",
          },
          labels: [],
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 1,
            colors: undefined,
          },
          yaxis: {
            show: true,
          },
          legend: {
            position: "bottom",
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0,
              },
              spokes: {
                strokeWidth: 0,
              },
            },
          },
        },
        ventureStepSeries: []
      };
    },
    mounted() {},
    created() {
      if (this.$can("dashboard_view")) {
        window.scrollTo(0, 0);
        this.fetchQuickStats();
        this.assignData();
      }
        
    },
    methods: {
      ...mapActions([
        "updateQuickStats",
        "startQuickStatsLoader",
        "stopQuickStatsLoader",
      ]),
      fetchQuickStats() {
        apiCall({
          url: "/api/report?type=quickStats",
          method: "GET",
        })
          .then((resp) => {
            this.updateQuickStats(resp);
            this.showChart = true;
            this.assignData();
          })
          .catch((error) => {
            this.message = "anErrorOccurredCouldntGetFormDataMsg";
            this.color = "error";
          });
      },
      assignData() {
        this.userChartOptions.xaxis.categories =
          this.quickStats.user_xaxis;
        this.userSeries =
          this.quickStats.user_statistics;

        this.ventureSectorChartOptions.xaxis.categories =
          this.quickStats.venture_sector_xaxis;
        this.ventureSectorSeries =
          this.quickStats.venture_sector_statistics;
  
        this.userCountriesChartOptions.labels =
          this.quickStats.user_countries.labels;
        this.userCountriesSeries =
          this.quickStats.user_countries.series;

        this.userGendersChartOptions.labels =
          this.quickStats.user_genders.labels;
        this.userGendersSeries =
          this.quickStats.user_genders.series;

        this.ventureMarketingSourceChartOptions.labels =
          this.quickStats.venture_marketing_sources.labels;
        this.ventureMarketingSourceSeries =
          this.quickStats.venture_marketing_sources.series;

        this.ventureStepChartOptions.labels =
          this.quickStats.venture_steps.labels;
        this.ventureStepSeries =
          this.quickStats.venture_steps.series;

        this.ventureBSAChartOptions.xaxis.categories =
          this.quickStats.venture_business_sector_application_xaxis;

          let series = [];
          for (const key in this.quickStats.venture_business_sector_application_data.values) {
            let x = { name: key, data: this.quickStats.venture_business_sector_application_data.values[key] };
            series.push(x);
          }

        this.ventureBSASeries = series;


        this.ventureBCAChartOptions.xaxis.categories =
          this.quickStats.venture_business_category_applications_xaxis;

          let series2 = [];
          for (const key in this.quickStats.venture_business_category_applications_data.values) {
            let x = { name: key, data: this.quickStats.venture_business_category_applications_data.values[key] };
            series2.push(x);
          }

        this.ventureBCASeries = series2;
        

        this.ventureBSCChartOptions.xaxis.categories =
          this.quickStats.venture_business_sector_category_xaxis;

          let series3 = [];
          for (const key in this.quickStats.venture_business_sector_category_data.values) {
            let x = { name: key, data: this.quickStats.venture_business_sector_category_data.values[key] };
            series3.push(x);
          }

        this.ventureBSCSeries = series3;




      },
    },
    computed: {
      ...mapGetters(["quickStats", "quickStatsLoader"]),
    },
  };
  </script>
  