import apiCall from '@/utils/api'

const state = {
	pastCohorts: [],
	allPastCohorts: [],
    pastCohortPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    pastCohortLoader: false
};

const getters = {
	pastCohorts: (state) => state.pastCohorts,
	allPastCohorts: (state) => state.allPastCohorts,
    pastCohortLoader: (state) => state.pastCohortLoader,
    pastCohortPagination: (state) => state.pastCohortPagination
}

const actions = {
	async fetchAllPastCohorts({commit}, page) {
		const response = await apiCall({url: `/api/past-cohort?type=all`, method: 'GET' });
		commit('setAllPastCohorts', response)
        commit('stopLoader', response)
	},
    async fetchPastCohorts({commit}, page) {
		const response = await apiCall({url: `/api/past-cohort?page=${page}`, method: 'GET' });
		commit('setPastCohorts', response)
        commit('stopLoader', response)
	},
    async filterPastCohorts({commit, state},resp){
		commit('setPastCohorts', resp)
	},
	async startPastCohortLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopPastCohortLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPastCohorts: (state, pastCohorts) => {
		state.pastCohorts = pastCohorts.data
		state.pastCohortPagination.current_page = pastCohorts.current_page
		state.pastCohortPagination.total = pastCohorts.total
		state.pastCohortPagination.per_page = pastCohorts.per_page
	},
	setAllPastCohorts: (state, pastCohorts) => {
		state.allPastCohorts = pastCohorts
	},
    startLoader: (state) => state.pastCohortLoader = true,
	stopLoader: (state) => state.pastCohortLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
