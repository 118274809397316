<template>
	<div class="welcome">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="pdfViewDialog"
        >
            <v-card max-width="600">
				<v-toolbar flat>
					{{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfViewDialog = false">
						<v-icon class="primary--text">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
                <v-card-text>                    
					<div align="right">
						<v-btn icon @click="$refs.myPdfComponent.print()"><v-icon class="primary--text">mdi-printer</v-icon></v-btn>
						<v-btn v-if="docType == 'CR12'" icon @click="deleteCertificate('CR12')" :loading="certLoading"><v-icon class="red--text">mdi-delete</v-icon></v-btn>
						<v-btn v-else-if="docType == 'TCC'" icon @click="deleteCertificate('TCC')" :loading="certLoading"><v-icon class="red--text">mdi-delete</v-icon></v-btn>
						<v-btn v-else-if="docType == 'AFA'" icon @click="deleteCertificate('AFA')" :loading="certLoading"><v-icon class="red--text">mdi-delete</v-icon></v-btn>
						<v-btn v-else icon @click="deleteCertificate('registrationCertificate')" :loading="certLoading"><v-icon class="red--text">mdi-delete</v-icon></v-btn>
					</div>
					<pdf v-if="docType == 'CR12'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newVenture.cr12"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else-if="docType == 'TCC'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newVenture.tax_compliance_certificate"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else-if="docType == 'AFA'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newVenture.audited_financial_accounts"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ newVenture.registration_certificate"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="pdfDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload {{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="pdfForm" v-model="pdfValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-1 mt-3" :loading="pdfLoading" :rules="fileRules" filled dense show-size chips
								accept="application/pdf" v-model="pdfFile" label="Select .PDF File Format ONLY"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadPDF" :loading="pdfLoading"
									:disabled="!pdfValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="imageDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload Picture
					<v-spacer></v-spacer>
					<v-btn icon @click="imageDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="imageForm" v-model="imageValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-1 mt-3" :loading="imageLoading" :rules="fileRules" filled dense show-size chips
								accept="image/*" v-model="imageFile" label="Select Image"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadImage" :loading="imageLoading"
									:disabled="!imageValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-layout column>
			<v-flex xs12>
				<v-img dark height="250" src="img/Cohort 1 & 2.jpg">
					<div align="center" class="font-weight-black display-1 mt-16">
						Women Entrepreneurship Incubator Program
					</div>
					<div align="center" class="mt-2">
						WEIP
					</div>
				</v-img>
			</v-flex>
			<v-flex xs12 class="white">
				<v-container>
					<div class="my-16">
						<div v-if="openForm==false">
							<div v-if="programDetails == null">
								<v-container style="height: 550px;">
									<v-card outlined class="mx-auto my-16" elevation="0" style="border-radius: 25px;">
										<div align="center" class="my-5">
												<v-layout row wrap>
													<v-flex xs6>
														<div align="right" class="mr-2 mt-1">
															<v-img max-width="172" src="img/logo.png"> </v-img>
														</div>
													</v-flex>
													<v-flex xs6>
														<div align="left" class="ml-2">
															<v-img max-width="164" src="img/strathmore-logo-black.png"> </v-img>
														</div>
													</v-flex>
												</v-layout>														
											</div>
											<v-card-text class="mb-16">
											<v-row class="fill-height" align-content="center" justify="center">
												<v-col class="text-subtitle-1 text-center" cols="12">
													Getting ready..
												</v-col>
												<v-col cols="6">
													<v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-container>
							</div>
							<div v-else>
								<v-container style="height: 550px;">
									<v-card outlined class="mx-auto" elevation="0" style="border-radius: 25px;">
										<v-card-text>
											<v-layout column>
												<v-flex xs12>
													<div align="center" class="my-5">
														<v-layout row wrap>
															<v-flex xs6>
																<div align="right" class="mr-2 mt-1">
																	<v-img max-width="200" src="img/logo.png"> </v-img>
																</div>
															</v-flex>
															<v-flex xs6>
																<div align="left" class="ml-2">
																	<v-img max-width="192" src="img/strathmore-logo-black.png"> </v-img>
																</div>
															</v-flex>
														</v-layout>														
													</div>
													<div class="hidden-md-and-up">
														<div align="center" class="title font-weight-black ml-7 mr-7 mt-5" style="font-family: 'SC Prosper Sans Regular';">
															<v-layout column>
																<v-flex xs12>
																	<b>Welcome to the {{ programDetails.program.name }} Program</b>
																</v-flex>
																<v-flex xs12 class="mt-8">
																	<v-layout row wrap>
																		<v-flex xs12 md4></v-flex>
																		<v-flex xs12 md4>
																			<v-divider class="primary mx-16"></v-divider>
																			<v-divider class="secondary mx-16"></v-divider>
																		</v-flex>
																		<v-flex xs12 md4></v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>														
														</div>
														<div style="margin-top: -70px">
														<!-- <v-img width="30px" src="img/a-1.png">
														</v-img> -->
														</div>
														<div align="right" style="margin-top: -18px">
														<!-- <v-img width="30px" src="img/a-2.png">
														</v-img> -->
														</div>
													</div>
													<div class="hidden-sm-and-down">
														<div align="center" class="display-1 font-weight-black ml-7 mr-7 mt-8" style="font-family: 'SC Prosper Sans Regular';">
															<v-layout column>
																<v-flex xs12>
																	<b>Welcome to the {{ programDetails.program.name }} Program</b>
																</v-flex>
																<v-flex xs12 class="mt-8">
																	<v-layout row wrap>
																		<v-flex xs12 md4></v-flex>
																		<v-flex xs12 md4>
																			<v-divider class="primary mx-16"></v-divider>
																			<v-divider class="secondary mx-16"></v-divider>
																		</v-flex>
																		<v-flex xs12 md4></v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>														
														</div>
														<div style="margin-top: -60px">
														<!-- <v-img width="30px" src="img/a-1.png">
														</v-img> -->
														</div>
														<div align="right" style="margin-top: -16px">
														<!-- <v-img width="30px" src="img/a-2.png">
														</v-img> -->
														</div>
													</div>
												</v-flex>
												<v-flex xs12>
													<div v-if="programDetails.application == 0">
														<div class="mt-16" align="center">
															We regret to inform you that applications closed on <b>{{ programDetails.program.application_deadline | moment("Do MMM YYYY")}}</b>.
														</div>
														<div align="center"> However, don’t lose hope! Keep an eye on our social media channels for <b>exciting updates and program-related news.</b></div>
														<div align="center"> There might be other opportunities waiting for you. Stay tuned! 🌟📅🔍</div>
														<div align="center" class="mt-5 mb-10">
															<template>
															<a
																target="_blank"
																href="https://www.facebook.com/StrathmoreUniversity/?fref=ts"
																style="text-decoration: none"
															>
																<v-btn class="mx-2" icon>
																<v-icon size="25px" color="#3b5998"> mdi-facebook </v-icon>
																</v-btn>
															</a>
															<a
																target="_blank"
																href="https://x.com/StrathU"
																style="text-decoration: none"
															>
																<v-btn class="mx-2" icon>
																<v-icon size="25px" color="#1DA1F2"> mdi-twitter </v-icon>
																</v-btn>
															</a>
															<a
																target="_blank"
																href="https://www.youtube.com/@StrathmoreUniversity/videos"
																style="text-decoration: none"
															>
																<v-btn class="mx-2" icon>
																<v-icon size="25px" color="#cc181e"> mdi-youtube </v-icon>
																</v-btn>
															</a>
															<a
																target="_blank"
																href="https://www.instagram.com/strathmore.university/?hl=en"
																style="text-decoration: none"
															>
																<v-btn class="mx-2" icon>
																<v-icon size="25px" color="#8a3ab9"> mdi-instagram </v-icon>
																</v-btn>
															</a>
															</template>
														</div>
													</div>
													<div v-else>
														<div align="center" class="mt-16 mb-2"><b>Program Details</b></div>
														<div class="mt-6">
															<v-layout row wrap>
																<v-flex xs12 md3>

																</v-flex>
																<v-flex xs12 md6>
																	<v-layout row wrap>
																		<v-flex xs7 md6>
																			<b class="ml-4">Application Deadline:</b>
																		</v-flex>
																		<v-flex xs5 md6>
																			<div align="right" class="mr-4">
																				{{ programDetails.program.application_deadline | moment("Do MMM YYYY")}}
																			</div>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 md3>

																</v-flex>
															</v-layout>
														</div>

														<div align="center" class="mb-7 mt-10">
															<v-btn depressed class="text-none mt-5 secondary white--text" @click="openForm = true">
																Get Started <v-icon right>mdi-chevron-right</v-icon>
															</v-btn>	
														</div>
													</div>
												</v-flex>
											</v-layout>
										</v-card-text>
									</v-card>
								</v-container>
							</div>
						</div>
						<div v-else>
							<v-stepper v-model="e1" outlined style="border-radius: 25px;">
								<v-stepper-header>
									<v-stepper-step :complete="e1 > 1" step="1">
										Business Owner Details
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 2" step="2">
										Business Profile
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 3" step="3">
										Product/Service
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 4" step="4">
										Management Team
									</v-stepper-step>

									<v-divider></v-divider>

									<v-stepper-step :complete="e1 > 5" step="5">
										Terms & Conditions
									</v-stepper-step>
								</v-stepper-header>

								<v-stepper-items>
									<v-stepper-content step="1">
										<div v-if="formData == null">
											<v-container style="height: 400px;">
												<v-row class="fill-height" align-content="center" justify="center">
													<v-col class="text-subtitle-1 text-center" cols="12">
														Getting ready..
													</v-col>
													<v-col cols="6">
														<v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
													</v-col>
												</v-row>
											</v-container>
										</div>
										<div v-else>
											<v-layout column>
												<v-flex xs12>
													<div align="center" class="mt-5">
														<v-avatar size="200" class="primary title white--text" v-if="getProfile.prof_pic == null">
															{{ getProfile.first_name[0] }} {{ getProfile.last_name[0] }}
														</v-avatar>
														<v-avatar size="200" class="primary title" v-else>
															<img :src="path + '/storage/profile_pics/' + getProfile.prof_pic">
														</v-avatar>
													</div>
												</v-flex>
												<v-flex xs12>
													<div align="center">
														<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
															Upload Profile Pic
														</v-btn>
													</div>
												</v-flex>
												<v-flex xs12>
													<div class="mx-2 mt-5">
														<v-form ref="profileForm" v-model="personalValid" lazy-validation>
															<v-layout row wrap>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>First Name<span class="red--text">*</span>
																		</div>
																		<v-text-field outlined dense v-model="newUser.first_name" :rules="inputRules"
																			class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																		<div class="font-italic caption">First Name as per your National ID or Passport</div>
																	</div>
																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Middle Name</div>
																		<v-text-field outlined dense v-model="newUser.middle_name"
																			class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																		<div class="font-italic caption">Middle Name as per your National ID or Passport</div>
																	</div>
																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Last Name<span class="red--text">*</span></div>
																		<v-text-field outlined dense v-model="newUser.last_name" :rules="inputRules"
																			class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																		<div class="font-italic caption">Last Name as per your National ID or Passport</div>
																	</div>
																</v-flex>
																<v-flex xs12 md8>
																	<div class="mx-1 mt-5">
																		<div>Email<span class="red--text">*</span></div>
																		<v-text-field outlined dense v-model="newUser.email" :rules="inputRules"
																			class="text_field background" disabled
																			prepend-inner-icon="mdi-email"></v-text-field>
																	</div>
																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Phone Number<span class="red--text">*</span>
																		</div>
																		<v-text-field outlined dense v-model="newUser.phone" :rules="inputRules"
																			class="text_field background" prepend-inner-icon="mdi-phone"></v-text-field>
																		<div class="font-italic caption"> Please give a number we can easily reach you
																			directly. Mobile Number Preferred</div>
																	</div>
																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Gender<span class="red--text">*</span></div>
																		<v-select :items="formData.genders" item-text="name" item-value="id"
																			v-model="newUser.gender_id" :rules="inputRules" class="text_field background" dense
																			outlined prepend-inner-icon="mdi-gender-male-female">
																		</v-select>
																	</div>
																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Nationality<span class="red--text">*</span>
																		</div>
																		<v-select :items="formData.countries" item-text="name" item-value="id"
																			v-model="newUser.country_id" :rules="inputRules" class="text_field background" dense
																			outlined prepend-inner-icon="mdi-flag" @change="checkIfAllowed('nationality', newUser.country_id)">
																		</v-select>
																		<div class="font-italic caption">Please note that ONLY Kenyan nationality
																			holder can apply for this program.</div>
																	</div>

																</v-flex>
																<v-flex xs12 md4>
																	<div class="mx-1 mt-5">
																		<div>Date of Birth<span class="red--text">*</span></div>
																		<v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
																			v-model="dateOfBirth" offset-y>
																			<template v-slot:activator="{ on, attrs }">
																				<v-text-field outlined dense :value="formatDateOfBirth" :rules="inputRules"
																					slot="activator" readonly v-bind="attrs" v-on="on" class="text_field background"
																					prepend-inner-icon="mdi-calendar"></v-text-field>
																			</template>
																			<v-date-picker v-model="newUser.dob" :max="dateToday" @change="closeDateOfBirth">
																			</v-date-picker>
																		</v-menu>
																	</div>

																</v-flex>
																<v-flex xs12 md12>
																	<div class="mx-1 mt-5" >
																		<div>Are you a person living with disability (PLWD)?<span class="red--text">*</span>
																		</div>
																		<v-radio-group class="ml-2" v-model="newUser.isplwd" row>
																			<v-radio label="Yes" :value="1"></v-radio>
																			<v-radio label="No" :value="0"></v-radio>
																		</v-radio-group>
																	</div>
																</v-flex>
																<v-flex xs12 md12 v-if="newUser.isplwd == 1">
																	<div class="mx-1 mt-5">
																		<div>State Nature of Disability <span class="red--text">*</span></div>
																		<v-text-field outlined dense v-model="newUser.nature_of_disability" :rules="inputRules"
																			class="text_field background"
																			prepend-inner-icon="mdi-wheelchair"></v-text-field>
																	</div>
																</v-flex>
																<v-flex xs12 md6 v-if="newUser.isplwd == 1">
																	<div class="mx-1 mt-5">
																		<div>NCPLWD No</div>
																		<v-text-field outlined dense v-model="newUser.ncplwd_no" :rules="inputRules"
																			class="text_field background"
																			prepend-inner-icon="mdi-numeric"></v-text-field>
																			<div class="font-italic caption"> Please enter your National Council for People Living With Disabilities Number</div>
																	</div>
																</v-flex>
																<v-flex xs12 md6 v-if="newUser.isplwd == 1">
																	<div class="mx-1 mt-5">
																		<div>Date of Issuance <span class="red--text">*</span></div>
																		<v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
																			v-model="dateOfIssuance" offset-y>
																			<template v-slot:activator="{ on, attrs }">
																				<v-text-field outlined dense :value="formatDateOfIssuance" :rules="inputRules"
																					slot="activator" readonly v-bind="attrs" v-on="on" class="text_field background"
																					prepend-inner-icon="mdi-calendar"></v-text-field>
																			</template>
																			<v-date-picker v-model="newUser.ncplwd_doi" @change="closeDateOfIssuance">
																			</v-date-picker>
																		</v-menu>
																		<div class="font-italic caption"> Please enter the date of issuance of your People Living With Disabilities Number</div>
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div class="mx-1 mt-5">
																		<div>Bio</div>
																		<div class="black--text">
																			<ckeditor :editor="editor" v-model="newUser.bio" :config="editorConfig"
																				:rules="inputRules">
																			</ckeditor>
																		</div>
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div class="mx-1 mt-10">
																		<div>On a scale of 1 - 5 where 1 (least proficient) and 5 (most proficient), indicate your level of proficiency in the following areas:</div>
																		<div class="black--text">
																			<v-simple-table>
																				<template v-slot:default>
																					<thead class="header">
																						<tr class="">
																							<th class="text-left">Area</th>
																							<th class="text-left">Proficiency Level</th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr>
																							<td>Administration</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.admin_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Managing people</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.managing_people_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Marketing and sales</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.marketing_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Product development</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.product_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Operations</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.operations_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Finance/financial management</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.financial_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																						<tr>
																							<td>Information systems</td>
																							<td>
																								<v-select :items="proficiencyLevels" outlined dense v-model="newUser.information_proficiency" :rules="inputRules"
																								prepend-inner-icon="mdi-swap-vertical" class="background login_field"></v-select>
																							</td>
																						</tr>
																					</tbody>
																				</template>
																			</v-simple-table>
																		</div>
																	</div>
																</v-flex>

																<v-flex xs12 md6>
																	<div class="mx-1 mt-10">
																		<div>LinkedIn URL</div>
																		<v-text-field outlined dense v-model="newUser.linkedin_url"
																			class="text_field background" prepend-inner-icon="mdi-linkedin"></v-text-field>
																	</div>
																</v-flex>
																<v-flex xs12 md6>
																	<div class="mx-1 mt-10">
																		<div>X URL</div>
																		<v-text-field outlined dense v-model="newUser.twitter_url"
																			class="text_field background" prepend-inner-icon="mdi-twitter"></v-text-field>
																	</div>
																</v-flex>
															</v-layout>
														</v-form>
													</div>
												</v-flex>
												<v-flex xs12>
													<div align="right">
														<v-btn depressed class="primary text-none mb-3 mt-10" @click="updateProfile()"
															:loading="loading" :disabled="!personalValid">
															Update and Continue <v-icon right dark>mdi-chevron-right</v-icon>
														</v-btn>
													</div>
												</v-flex>
											</v-layout>
										</div>
									</v-stepper-content>

									<v-stepper-content step="2">
										<v-layout column>

											<v-flex xs12>
												<div align="center" class="mt-5">
													<v-avatar size="200" class="primary title" v-if="newVenture.image == null">
														<v-icon class="white--text">mdi-domain</v-icon>
													</v-avatar>
													<v-avatar size="200" class="primary title" v-else>
														<img :src="path + '/storage/venture_logos/' + newVenture.image">
													</v-avatar>
												</div>
											</v-flex>
											<v-flex xs12>
												<div align="center">
													<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
														Upload Business Logo
													</v-btn>
												</div>
											</v-flex>
											<v-flex xs12>
												<div class="mx-2 mt-5">
													<v-form ref="ventureForm" v-model="valid" lazy-validation>
														<v-layout row wrap>
															<v-flex xs12 md9>
																<div class="mx-1 mt-5">
																	<div>Business Name<span class="red--text">*</span></div>
																	<v-text-field outlined dense v-model="newVenture.name" :rules="inputRules"
																		class="text_field background" prepend-inner-icon="mdi-domain"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md3>
																<div class="mx-1 mt-5">
																	<div>Year Established</div>
																	<v-select :items="years" outlined dense v-model="newVenture.year_established"
																		prepend-inner-icon="mdi-calendar" class="background login_field"></v-select>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Business Ownership<span class="red--text">*</span></div>
																	<v-select :items="formData.genders" item-text="name" item-value="id"
																		v-model="newVenture.gender_id" :rules="inputRules" class="text_field background" dense
																		outlined prepend-inner-icon="mdi-gender-male-female"
																		@change="checkIfAllowed('gender', newVenture.gender_id)">
																	</v-select>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>51% Ownership?<span class="red--text">*</span></div>
																	<v-radio-group class="ml-2" v-model="newVenture.ownership_percent" row @change="checkIfAllowed('ownership', newVenture.ownership_percent)">
																		<v-radio label="Yes" :value="1"></v-radio>
																		<v-radio label="No" :value="0"></v-radio>
																	</v-radio-group>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Number of employees<span class="red--text">*</span></div>
																	<v-text-field outlined dense v-model="newVenture.noofemployees" :rules="inputRules"
																		class="text_field background"
																		prepend-inner-icon="mdi-account-group"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<v-checkbox class="ml-2" v-model="afEmailAddress"
																	label="Email Address Same as Owner?" @change="autoFillEmailAddress()">
																</v-checkbox>
															</v-flex>
															<v-flex xs12 md8>
																<v-checkbox class="ml-2" v-model="afPhoneNumber"
																	label="Phone Number Same as Owner?" @change="autoFillPhoneNumber()">
																</v-checkbox>
															</v-flex>	

															<v-flex xs12 md4>
																<div class="mx-1">
																	<div>Email Address<span class="red--text">*</span></div>
																	<v-text-field outlined dense v-model="newVenture.email_address"
																		class="text_field background" prepend-inner-icon="mdi-email"
																		:rules="emailRules"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1">
																	<div>Phone Number<span class="red--text">*</span></div>
																	<v-text-field outlined dense v-model="newVenture.phone_number" :rules="inputRules"
																		class="text_field background" prepend-inner-icon="mdi-phone"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1">
																	<div>Website</div>
																	<v-text-field outlined dense v-model="newVenture.website_url"
																		class="text_field background" prepend-inner-icon="mdi-web"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>County of Operation<span class="red--text">*</span></div>
																	<v-select :items="formData.counties" item-text="county_name" item-value="id"
																		v-model="newVenture.county_id" :rules="inputRules" class="text_field background" dense
																		outlined prepend-inner-icon="mdi-city-variant-outline">
																	</v-select>
																</div>
															</v-flex>
															<v-flex xs12 md8>
																<div class="mx-1 mt-5">
																	<div>Physical Address</div>
																	<v-text-field outlined dense v-model="newVenture.physical_address"
																		class="text_field background" prepend-inner-icon="mdi-map-marker"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-1 mt-5">
																	<div>Business Overview<span class="red--text">*</span></div>
																	<div class="black--text">
																		<ckeditor :editor="editor" v-model="newVenture.description" :config="editorConfig"
																			:rules="inputRules">
																		</ckeditor>
																	</div>
																	<div class="font-italic caption">Tell us what you do and why your business has
																		potential. Be as short,
																		concise and to the point as possible.</div>
																</div>
															</v-flex>
															<v-flex xs12 md6>
																<div class="mx-1 mt-5">
																	<div>Category<span class="red--text">*</span></div>
																	<v-select :items="categories" outlined dense v-model="newVenture.category"
																		prepend-inner-icon="mdi-shape-outline" class="background login_field"></v-select>
																</div>
															</v-flex>
															<v-flex xs12 md6>
																<div class="mx-1 mt-5">
																	<div>Business Type</div>
																	<v-select :items="businessTypes" outlined dense v-model="newVenture.business_type"
																		prepend-inner-icon="mdi-lightbulb-group" class="background login_field"></v-select>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-1 mt-5">
																	<div>Video Pitch URL</div>
																	<v-text-field outlined dense v-model="newVenture.video_pitch_url"
																		class="text_field background" diasbled></v-text-field>
																</div>
																<div class="font-italic caption">In 2 minutes or less, explain why you should be shortlisted</div>
															</v-flex>

															<v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>Sector(s)<span class="red--text">*</span></div>
																	<v-select v-model="newVenture.sectors" :items="formData.sectors" chips item-value="id"
																		item-text="name" multiple outlined class="chip_text_field background"
																		:rules="inputRules"></v-select>
																</div>
															</v-flex>

															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Does Your Company Have Directors?<span class="red--text">*</span></div>
																	<v-radio-group class="ml-2" v-model="newVenture.has_directors" row :rules="inputRules">
																		<v-radio label="Yes" :value="1"></v-radio>
																		<v-radio label="No" :value="2"></v-radio>
																	</v-radio-group>
																</div>
															</v-flex>

															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Does your company have a CR12 Certificate?<span class="red--text">*</span></div>
																	<v-radio-group class="ml-2" v-model="newVenture.has_cr12_cert" row :rules="inputRules">
																		<v-radio label="Yes" :value="1"></v-radio>
																		<v-radio label="No" :value="2"></v-radio>
																	</v-radio-group>
																</div>
															</v-flex>

															<v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>Turnover, Revenue and Costs<span class="red--text">*</span></div>
																	<v-simple-table>
																		<template v-slot:default>
																			<thead class="header">
																				<tr class="primary">
																					<th class="text-left text--text"></th>
																					<th class="text-left text--text">2021</th>
																					<th class="text-left text--text">2022</th>
																					<th class="text-left text--text">2023</th>
																					<th class="text-left text--text">2024</th>
																					<th class="text-left text--text">2025</th>
																					<th class="text-left text--text">2026</th>
																				</tr>
																			</thead>
																			<tbody>
																				<tr>
																					<td>
																						What was your annual turnover in KES for<span class="red--text">*</span>
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualTO2021" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualTO2022" :rules="inputRules"
																							class="text_field background"></v-text-field>																						
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualTO2023" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																					<td>N/A</td>
																					<td>N/A</td>
																					<td>N/A</td>
																				</tr>
																				<tr>
																					<td>
																						What are your projected annual revenues in KES in the next 3 years?<span class="red--text">*</span>
																					</td>
																					<td>N/A</td>
																					<td>N/A</td>
																					<td>N/A</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualRev2024" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualRev2025" :rules="inputRules"
																							class="text_field background"></v-text-field>																						
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualRev2026" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																				</tr>
																				<tr>
																					<td>
																						What are your projected yearly costs in KES in the next 3 years?<span class="red--text">*</span>
																					</td>
																					<td>N/A</td>
																					<td>N/A</td>
																					<td>N/A</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualCosts2024" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualCosts2025" :rules="inputRules"
																							class="text_field background"></v-text-field>																						
																					</td>
																					<td>
																						<v-text-field outlined dense v-model="newVenture.annualCosts2026" :rules="inputRules"
																							class="text_field background"></v-text-field>
																					</td>
																				</tr>
																			</tbody>
																		</template>
																	</v-simple-table>
																</div>
															</v-flex>
															<!-- <v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>Stage<span class="red--text">*</span></div>
																	<v-radio-group v-model="newVenture.stage_id" row>
																		<template v-for="(
																				option, index
																				) in formData.steps">
																			<div :key="index">
																				<v-radio :label="option.name" :value="option.id"></v-radio>
																			</div>
																		</template>
																	</v-radio-group>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>What type of customers do you serve?<span class="red--text">*</span></div>
																	<v-row>
																		<template v-for="(
																					clientType, index
																					) in formData.clientTypes">
																			<v-col cols="12" sm="4" md="4" :key="index">
																				<v-checkbox v-model="newVenture.client_types" :key="index" :value="clientType.id"
																					:label="clientType.name"></v-checkbox>
																			</v-col>
																		</template>
																	</v-row>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>Where are your customers based?<span class="red--text">*</span></div>
																	<v-row>
																		<template v-for="(
																					clientType, index
																					) in formData.clientLocations">
																			<v-col cols="12" sm="4" md="4" :key="index">
																				<v-checkbox v-model="newVenture.client_locations" :key="index"
																					:value="clientType.id" :label="clientType.name"></v-checkbox>
																			</v-col>
																			</template>
																	</v-row>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-1 mt-5" v-if="formData != null">
																	<div>Have you received funding above USD 5,000 in the last two years?<span
																			class="red--text">*</span>
																	</div>
																	<v-radio-group v-model="newVenture.funding" row>
																		<v-radio label="Yes" :value="1"></v-radio>
																		<v-radio label="No" :value="0"></v-radio>
																	</v-radio-group>
																</div>
															</v-flex> -->
															<v-flex xs12 class="mb-5" >
																<div class="my-5 mx-16">
																	<v-divider></v-divider>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mx-2">
																	<span class="primary--text" style="font-style: italic; font-weight: bolder">
																		File Upload Rules:
																		<ol>
																			<li>Upload each required file as described alongside.</li>
																			<li>Upload files in PDF format ONLY.</li>
																			<li>Maximum file size for each file is 20MB.</li>
																		</ol>
																	</span>
																</div>
															</v-flex>
															<v-flex xs12 md6 v-if="newVenture.has_cr12_cert == 1">
																<div class="mx-1 my-5" align="center">
																	<v-layout row wrap class="mt-2">
																		<v-flex xs12 md6>
																			<div class="mt-2">
																				CR12 Certificate<span class="red--text">*</span>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-btn depressed class="secondary white--text text-none" @click="uploadDocument('CR12')" v-if="newVenture.cr12==null">
																				Select File <v-icon right small>mdi-file-document</v-icon>
																			</v-btn>
																			<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('CR12')" v-if="newVenture.cr12!=null">
																				View File <v-icon right small>mdi-file-pdf-box</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</div>
															</v-flex>
															<v-flex xs12 md6>
																<div class="mx-1 my-5" align="center">
																	<v-layout row wrap class="mt-2">
																		<v-flex xs12 md6>
																			<div class="mt-2">
																				Registration Certificate<span class="red--text">*</span>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-btn depressed class="secondary white--text text-none" @click="uploadDocument('Registration Certificate')" v-if="newVenture.registration_certificate==null">
																				Select File <v-icon right small>mdi-file-document</v-icon>
																			</v-btn>
																			<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('Registration Certificate')" v-if="newVenture.registration_certificate!=null">
																				View File <v-icon right small>mdi-file-pdf-box</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</div>
															</v-flex>
															<v-flex xs12 md6>
																<div class="mx-1 my-5" align="center">
																	<v-layout row wrap class="mt-2">
																		<v-flex xs12 md6>
																			<div class="mt-2">
																				Valid Tax Compliance Certificate<span class="red--text">*</span>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-btn depressed class="secondary white--text text-none" @click="uploadDocument('TCC')" v-if="newVenture.tax_compliance_certificate==null">
																				Select File <v-icon right small>mdi-file-document</v-icon>
																			</v-btn>
																			<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('TCC')" v-if="newVenture.tax_compliance_certificate!=null">
																				View File <v-icon right small>mdi-file-pdf-box</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</div>
															</v-flex>
															<v-flex xs12 md6 v-if="newVenture.category == 'Medium enterprise'">
																<div class="mx-1 my-5" align="center">
																	<v-layout row wrap class="mt-2">
																		<v-flex xs12 md6>
																			<div class="mt-2">
																				Audited Financial Accounts<span class="red--text">*</span>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-btn depressed class="secondary white--text text-none" @click="uploadDocument('AFA')" v-if="newVenture.audited_financial_accounts==null">
																				Select File <v-icon right small>mdi-file-document</v-icon>
																			</v-btn>
																			<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('AFA')" v-if="newVenture.audited_financial_accounts!=null">
																				View File <v-icon right small>mdi-file-pdf-box</v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</div>
															</v-flex>
															<v-flex xs12>
																<div class="mt-8 mb-5 mx-16">
																	<v-divider></v-divider>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>X URL</div>
																	<v-text-field outlined dense v-model="newVenture.x_url" class="text_field background"
																		prepend-inner-icon="mdi-twitter"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>LinkedIn URL</div>
																	<v-text-field outlined dense v-model="newVenture.linkedin_url"
																		class="text_field background" prepend-inner-icon="mdi-linkedin"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Facebook URL</div>
																	<v-text-field outlined dense v-model="newVenture.facebook_url"
																		class="text_field background" prepend-inner-icon="mdi-facebook"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>YouTube URL</div>
																	<v-text-field outlined dense v-model="newVenture.youtube_url"
																		class="text_field background" prepend-inner-icon="mdi-youtube"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>Instagram URL</div>
																	<v-text-field outlined dense v-model="newVenture.instagram_url"
																		class="text_field background" prepend-inner-icon="mdi-instagram"></v-text-field>
																</div>
															</v-flex>
															<v-flex xs12 md4>
																<div class="mx-1 mt-5">
																	<div>TikTok URL</div>
																	<v-text-field outlined dense v-model="newVenture.tiktok_url"
																		class="text_field background" prepend-inner-icon="mdi-alpha-t"></v-text-field>
																</div>
															</v-flex>
														</v-layout>
													</v-form>
												</div>
											</v-flex>
											<v-flex xs12>
												<v-layout row wrap class="mt-10 mb-3">
													<v-flex xs6>
														<div align="left">
															<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 1">
																<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
															</v-btn>
														</div>
													</v-flex>
													<v-flex xs6>
														<div align="right">
															<v-btn depressed class="primary text-none mr-3" @click="saveVenture()" :loading="loading"
																:disabled="!valid">
																Save and Continue <v-icon right dark>mdi-chevron-right</v-icon>
															</v-btn>
														</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>
									</v-stepper-content>

									<v-stepper-content step="3">
										<v-layout column>
											<v-flex x12>
												<div align="right">
													<!-- <v-progress-linear v-model="progressBar" :color="progressColor()"
														class="ml-5" height="25">
														<template v-slot:default="{ value }">
															<strong>
																<div class="black--text text--darken-1 black--darken-1">{{
				value }}%</div>
															</strong>
														</template>
													</v-progress-linear> -->
												</div>
											</v-flex>
											<v-flex xs12>
												<div v-if="questions.length != 0" class="mt-10">
													<template v-for="(question, index) in questions">
														<div :key="index">
															<v-flex xs12 class="my-2">
																<v-card class="mx-auto" elevation="0">
																	<v-card-text>
																		<v-layout row wrap>
																			<v-flex xs1>
																				<div align="center" class="subtitle-1">
																					{{ index + 1 }}.
																				</div>
																			</v-flex>
																			<v-flex xs11>
																				<div class="subtitle-1">
																					<b>{{ question.question }}<span class="red--text"
																							v-if="question.required == 1">*</span></b>
																				</div>
																				<div class="font-italic caption">{{ question.description }}</div>
																				<div v-if="question.question_type.name ==
				'Single Select Multiple Choice Question'
				">
																					<v-radio-group v-model="singleSelect[question.id]" column>
																						<template v-for="(
									option, index
								) in question.multi_choice_options">
																							<div :key="index">
																								<v-radio :label="option.option" :value="option.id"
																									@change="checkOther(question.id, option, question)"></v-radio>
																							</div>
																						</template>
																						<div v-if="otherQuestions[question.id] == true">
																							<v-text-field class="text_field mt-2" outlined
																								v-model="openEnded[question.id]" label="Please specify.."
																								@input="trackInputField(question.id)" required></v-text-field>
																						</div>
																						<div v-if="childQuestions.length != 0" class="mt-3">
																							<template v-for="(question, index) in childQuestions[question.id]">
																								<div :key="index">
																									<v-flex xs12 class="my-2">
																										<v-card :loading="loading" class="mx-auto" elevation="0">
																											<v-card-text>
																												<v-layout row wrap>
																													<v-flex xs1>
																														<div align="center" class="subtitle-1">
																															{{
				index
				+
				1
			}}.
																														</div>
																													</v-flex>
																													<v-flex xs11>
																														<div class="subtitle-1">
																															<b>{{
					question.question
				}}</b>
																														</div>
																														<div v-if="question.question_type.name ==
				'Single Select Multiple Choice Question'
				">
																															<v-radio-group v-model="singleSelect[question.id]" column>
																																<template v-for="(
									option, index
								) in question.multi_choice_options">
																																	<div :key="index">
																																		<v-radio :label="option.option" :value="option.id"
																																			@change="checkOther(question.id, option, question)"></v-radio>
																																	</div>
																																</template>
																																<div v-if="otherQuestions[question.id] == true">
																																	<v-text-field class="text_field mt-2" outlined
																																		v-model="openEnded[question.id]"
																																		label="Please specify.."
																																		@input="trackInputField(question.id)"
																																		required></v-text-field>
																																</div>
																																<div v-if="childQuestions.length != 0">
																																	{{
				childQuestions[question.id]
			}}
																																</div>
																															</v-radio-group>
																														</div>
																														<div v-if="question.question_type.name ==
				'Multi Part Single Select Multiple Choice Question'
				">
																															<div class="mt-4">
																																<template v-for="(
									multi_question, index
								) in question.multi_part_questions">
																																	<div :key="index">
																																		{{
				multi_question.question
			}}
																																		<v-radio-group v-model="multiPartSingleAnswers[multi_question.id]
				" column>
																																			<template v-for="(
										option, index
										) in multi_question.multi_part_options">
																																				<div :key="index">
																																					<v-radio :label="option.option"
																																						:value="option.id"
																																						@change="calculateProgress()"></v-radio>
																																				</div>
																																			</template>
																																		</v-radio-group>
																																	</div>
																																</template>
																															</div>
																														</div>
																														<div v-if="question.question_type.name ==
				'Multi Select Multiple Choice Questions'
				">
																															<div class="mt-4">
																																<template v-for="(
										option, index
									) in question.multi_choice_options">
																																	<v-checkbox v-model="multiSelects" :key="index"
																																		:label="option.option" :value="option.id"
																																		@change="calculateProgress()"></v-checkbox>
																																</template>

																															</div>
																														</div>
																														<div v-if="question.question_type.name ==
				'Multi Part Multi Select Multiple Choice Question'
				">
																															<div class="mt-4">
																																<template v-for="(
									multi_question, index
								) in question.multi_part_questions">
																																	<div :key="index">
																																		{{
				multi_question.question
			}}
																																		<template v-for="(
										option, index
									) in multi_question.multi_part_options">
																																			<v-checkbox v-model="multiPartMultiAnswers"
																																				:key="index" :label="option.option"
																																				:value="option.id"
																																				@change="calculateProgress()"></v-checkbox>
																																		</template>
																																	</div>
																																</template>
																															</div>
																														</div>
																														<div v-if="question.question_type.name ==
				'Open Ended'
				">
																															<div class="mt-3 mb-5" v-if="question.length <= 25">
																																<v-text-field class="text_field" outlined
																																	v-model="openEnded[question.id]"
																																	@input="trackInputField(question.id)"
																																	required></v-text-field>
																															</div>
																														</div>
																													</v-flex>
																												</v-layout>
																											</v-card-text>
																										</v-card>
																									</v-flex>
																								</div>
																							</template>
																						</div>
																					</v-radio-group>
																				</div>
																				<div v-if="question.question_type.name ==
				'Multi Part Single Select Multiple Choice Question'
				">
																					<div class="mt-4">
																						<template v-for="(
									multi_question, index
								) in question.multi_part_questions">
																							<div :key="index">
																								{{ multi_question.question
																								}}
																								<v-radio-group v-model="multiPartSingleAnswers[multi_question.id]
				" column>
																									<template v-for="(
										option, index
										) in multi_question.multi_part_options">
																										<div :key="index">
																											<v-radio :label="option.option" :value="option.id"
																												@change="calculateProgress()">
																											</v-radio>
																										</div>
																									</template>
																								</v-radio-group>
																								<v-text-field v-if="multi_question.question == 'Other'"
																									class="text_field mb-6" outlined v-model="openEnded[question.id]"
																									label="Please specify.." @input="trackInputField(question.id)"
																									required></v-text-field>
																							</div>
																						</template>
																					</div>
																				</div>
																				<div v-if="question.question_type.name ==
				'Multi Select Multiple Choice Questions'
				">
																					<div class="mt-4">
																						<template v-for="(
										option, index
									) in question.multi_choice_options">
																							<v-checkbox v-model="multiSelects" :key="index" :label="option.option"
																								:value="option.id" @change="calculateProgress()"></v-checkbox>
																						</template>

																					</div>
																				</div>
																				<div v-if="question.question_type.name ==
				'Multi Part Multi Select Multiple Choice Question'
				">
																					<div class="mt-4">
																						<template v-for="(
									multi_question, index
								) in question.multi_part_questions">
																							<div :key="index">
																								{{ multi_question.question
																								}}
																								<template v-for="(
										option, index
									) in multi_question.multi_part_options">
																									<v-checkbox v-model="multiPartMultiAnswers" :key="index"
																										:label="option.option" :value="option.id"
																										@change="calculateProgress()"></v-checkbox>
																								</template>
																							</div>
																						</template>
																					</div>
																				</div>
																				<div v-if="question.question_type.name ==
				'Open Ended'
				">
																					<div class="mt-3 mb-5" v-if="question.length <= 25 && question.length != null">
																						<v-text-field class="text_field" outlined v-model="openEnded[question.id]"
																							@input="trackInputField(question.id)" required></v-text-field>
																					</div>
																					<div class="mt-3 mb-5" v-else>
																						<ckeditor :editor="editor" v-model="openEnded[question.id]"
																							:config="editorConfig" :rules="inputRules"
																							@input="trackInputField(question.id)"></ckeditor>
																					</div>
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-card-text>
																</v-card>
															</v-flex>
														</div>
													</template>
												</div>
												<div v-else class="mt-5">
													<v-card elevation="0">
														<v-layout row wrap>

															<v-flex xs12>
																<v-container style="height: 400px;">

																	<v-row class="fill-height" align-content="center" justify="center">
																		<v-col class="text-center">
																			<v-avatar size="200" class="primary title">
																				<v-icon class="white--text" large>mdi-text-box-check</v-icon>
																			</v-avatar>
																		</v-col>
																		<v-col class="text-subtitle-1 text-center" cols="12">
																			Loading Questions..
																		</v-col>
																		<v-col cols="6">
																			<v-progress-linear color="primary" indeterminate rounded
																				height="6"></v-progress-linear>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
											</v-flex>
											<v-flex xs12>
												<v-layout row wrap class="mt-10 mb-3">
													<v-flex xs6>
														<div align="left">
															<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 2">
																<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
															</v-btn>
														</div>
													</v-flex>
													<v-flex xs6>
														<div align="right">
															<v-btn depressed class="primary text-none mr-3" @click="saveAnswers()" :loading="loading">
																Save and Continue <v-icon right dark>mdi-chevron-right</v-icon>
															</v-btn>
														</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>

									</v-stepper-content>

									<v-stepper-content step="4">
										<v-layout column>
											<v-flex xs12>
												<div align="center" class="mt-5">
													<b>{{ teamMembers.length }} of 5 (Max)</b>
												</div>
											</v-flex>
											<v-form v-model="valid" ref="teamMemberForm" lazy-validation>
												<template v-for="(teamMember, index) in teamMembers">
													<div :key="index">
														<v-flex xs12 class="mt-8 pa-5">
															<v-layout row wrap>
																<v-flex xs1 md1>
																	<div align="center" class="mt-2">
																		{{ index + 1 }}.
																	</div>
																</v-flex>
																<v-flex xs11 md10>
																	<v-layout row wrap>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>First Name<span class="red--text">*</span>
																				</div>
																				<v-text-field outlined dense v-model="teamMember.first_name" :rules="inputRules"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>Middle Name
																				</div>
																				<v-text-field outlined dense v-model="teamMember.middle_name"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>Last Name<span class="red--text">*</span>
																				</div>
																				<v-text-field outlined dense v-model="teamMember.last_name" :rules="inputRules"
																					class="text_field background" prepend-inner-icon="mdi-account"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>Role<span class="red--text">*</span>
																				</div>
																				<v-text-field outlined dense v-model="teamMember.role" :rules="inputRules"
																					class="text_field background"
																					prepend-inner-icon="mdi-account-hard-hat"></v-text-field>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>Gender<span class="red--text">*</span></div>
																				<v-select :items="formData.genders" item-text="name" item-value="id"
																					v-model="teamMember.gender_id" :rules="inputRules" class="text_field background" dense
																					outlined prepend-inner-icon="mdi-gender-male-female">
																				</v-select>
																			</div>
																		</v-flex>
																		<v-flex xs12 md4>
																			<div class="mx-1 mt-5">
																				<div>Years Experience
																				</div>
																				<v-text-field outlined dense type="number" v-model="teamMember.years_experience"
																					:rules="inputRules" class="text_field background"
																					prepend-inner-icon="mdi-calendar-multiple-check"></v-text-field>
																			</div>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 md1>
																	<div align="center" class="mt-8 mx-5">
																		<v-btn block depressed class="red white--text" @click="removeTeamMember(index)">
																			<v-icon> mdi-close-circle </v-icon>
																		</v-btn>
																	</div>
																</v-flex>
															</v-layout>
															<div class="mx-10">
																<v-divider class="mt-16"></v-divider>
															</div>
														</v-flex>
													</div>
												</template>
											</v-form>
											<v-flex x12>
												<div align="right">
													<v-btn depressed class="secondary text-none mb-3" @click="addTeamMember()">
														Add Team Member
														<v-icon right> mdi-account-plus </v-icon>
													</v-btn>
												</div>
											</v-flex>
											<v-flex xs12>
												<v-layout row wrap class="mt-10 mb-3">
													<v-flex xs6>
														<div align="left">
															<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 3">
																<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
															</v-btn>
														</div>
													</v-flex>
													<v-flex xs6>
														<div align="right">
															<v-btn depressed class="primary text-none mr-3" @click="saveTeamMembers()"
																:loading="loading">
																Save and Continue <v-icon right dark>mdi-chevron-right</v-icon>
															</v-btn>
														</div>
													</v-flex>
												</v-layout>

											</v-flex>
										</v-layout>
									</v-stepper-content>

									<v-stepper-content step="5">
										<div v-if="completeApplication == false">
											<v-layout column v-if="formData!=null">
												<v-form v-model="termsValid" ref="termsAndConditionsForm" lazy-validation>
													<v-flex xs12>
														<div class="mx-1 mt-5">
															<div>Where did you first learn about the Women Entrepreneurship Incubator Program?<span
																	class="red--text">*</span>
															</div>
															<v-radio-group v-model="newApplication.marketing_source_id" column>
																<template v-for="(
																		option, index
																		) in formData.marketingSources">
																	<div :key="index">
																		<v-radio :label="option.name" :value="option.id"></v-radio>
																	</div>
																</template>
															</v-radio-group>
															<v-flex xs12 md4 v-if="newApplication.marketing_source_id == 4">
																<div>Advert Media<span class="red--text">*</span></div>
																<v-text-field dense v-model="newApplication.advertMedia"
																:rules="inputRules" class="text_field background"></v-text-field>
															</v-flex>
															<v-flex xs12 md4 v-if="newApplication.marketing_source_id == 5">
																<div>Please specify<span class="red--text">*</span></div>
																<v-text-field dense v-model="newApplication.otherSpecify"
																:rules="inputRules" class="text_field background"></v-text-field>
															</v-flex>
														</div>
													</v-flex>
													<v-flex xs12 md12>
														<div class="mx-1 mt-5">
															<div>Do you have any dietary concerns that we should consider? e.g. Diabetic, Vegetarian etc.</div>
															<v-text-field outlined dense v-model="newApplication.dietary_concerns"
																class="text_field background" prepend-inner-icon="mdi-food-off"></v-text-field>
														</div>
													</v-flex>
													<v-flex xs12 md12>
														<div class="mx-1 mt-5">
															<div>In what way do you think you can contribute to the education of your fellow participants in the program?</div>
															<v-text-field outlined dense v-model="newApplication.contribution"
																class="text_field background" prepend-inner-icon="mdi-handshake-outline"></v-text-field>
														</div>
													</v-flex>
													<v-flex xs12>
														<div class="mx-1 mt-5">
															Do you agree to the program <a href="/#/terms-and-conditions" target="_blank" style="text-decoration: none;">Terms and Conditions</a>?<span class="red--text">*</span>
														</div>
														<v-checkbox class="ml-2" v-model="newApplication.terms_conditions" :rules="inputRules"
															label="I hereby declare that the information I have provided is to the best of my knowledge, true, complete and correct."></v-checkbox>
													</v-flex>
													<v-flex xs12>
														<v-layout row wrap class="mt-10 mb-3">
															<v-flex xs6>
																<div align="left">
																	<v-btn depressed class="red text-none white--text ml-3" @click="e1 = 4">
																		<v-icon left dark class="white--text">mdi-chevron-left</v-icon> Back
																	</v-btn>
																</div>
															</v-flex>
															<v-flex xs6>
																<div align="right">
																	<v-btn depressed class="primary text-none mr-3" @click="submitApplication()"
																		:loading="loading" :disabled="!termsValid">
																		Submit and Complete <v-icon right dark>mdi-chevron-right</v-icon>
																	</v-btn>
																</div>
															</v-flex>
														</v-layout>

													</v-flex>
												</v-form>
											</v-layout>
										</div>
										<div v-else>
											<v-container style="height: 400px;">
												<v-row class="fill-height" align-content="center" justify="center">
													<v-col cols="12">
														<div align="center">
															<v-icon size="150px" class="green--text">
																mdi-check-circle
															</v-icon>
														</div>
													</v-col>
													<v-col class="text-subtitle-1 text-center" cols="12">
														Your application has been successfully submitted.
													</v-col>
													<v-col class="caption text-center" cols="12">
														You have the option to make modifications up until the deadline day.
													</v-col>

												</v-row>
											</v-container>
										</div>
									</v-stepper-content>
								</v-stepper-items>
							</v-stepper>
						</div>
					</div>
				</v-container>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import Vue from "vue";
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import { USER_REQUEST } from "@/store/actions/user";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';
import pdf from 'vue-pdf'

export default {
	components: {
		pdf
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			color: "",
			delete: false,
			loading: false,
			certLoading: false,
			valid: true,
			personalValid: true,
			termsValid: true,
			message: "",
			snackbar: false,
			confirm: false,
			programDetails: null,
			openForm: false,
			years: [],
			categories: [
				'Micro enterprise', 
				'Small enterprise', 
				'Medium enterprise'
			],
			businessTypes: [
				'Sole Proprietor',
				'Private Limited Company',
				'Partnership',
				'Social enterprise',
			],

			proficiencyLevels:[
				1,2,3,4,5
			],

			formData: null,
			dateOfBirth: false,
			dateOfIssuance: false,
			afEmailAddress: false,
			afPhoneNumber: false,

			docType: null,
			imageDialog: false,
			imageLoading: false,
			pdfViewDialog: false,
			pdfDialog: false,
			pdfLoading: false,
			inputRules: [(v) => !!v || "Input is required"],
			emailRules: [
				(v) => !!v || "Input is required",
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
						v
					) ||
					"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			fileRules: [
				(v) => !!v || "Kindly Select a File",
				(v) => !v || v.size < 20000000 || "File size should be less than 20MB!",
			],
			imageFile: null,
			imageValid: true,
			pdfFile: null,
			pdfValid: true,

			completeApplication: false,

			e1: 1,

			newUser: {
				id: null,
				first_name: null,
				middle_name: null,
				last_name: null,
				email: null,
				phone: null,
				dob: null,
				bio: null,
				country_id: null,
				gender_id: null,
				isplwd: false,
				ncplwd_doi: null,
				ncplwd_no: null,
				nature_of_disability: null,
				admin_proficiency: null,
				managing_people_proficiency: null,
				marketing_proficiency: null,
				product_proficiency: null,
				operations_proficiency: null,
				financial_proficiency: null,
				information_proficiency: null,

				linkedin_url: null,
				twitter_url: null,
			},
			newVenture: {
				id: null,
				name: null,
				image: null,
				slogan: " ",
				year_established: null,
				description: null,
				video_pitch_url: null,
				physical_address: null,
				email_address: null,
				phone_number: null,
				website_url: null,
				x_url: null,
				linkedin_url: null,
				facebook_url: null,
				youtube_url: null,
				instagram_url: null,
				tiktok_url: null,
				sectors: [],
				stage_id: null,
				funding: null,
				cr12: null,
				registration_certificate: null,
				tcc: null,
				afa: null,
				client_types: [],
				client_locations: [],
				has_directors: null,
				has_cr12_cert: null,
			},
			newApplication: {
				id: null,
				marketing_source_id: null,
				terms_conditions: null,
				advertMedia: null,
				otherSpecify: null,
				contribution: null,
				dietary_concerns: null,
			},

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			progressBar: 0,
			questionCount: 0,

			application: {
				answers: null
			},

			answers: [],

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],
			questions: [],

			otherQuestions: [],
			childQuestions: [],
			counter: 0,

			applicationAnswers: [],
			progressData: {
				id: null,
				progress: null,
			},
			teamMembers: [
				{
					id: null,
					venture_id: null,
					first_name: null,
					middle_name: null,
					last_name: null,
					founder: null,
					shares: null,
					gender_id: null,
					role: null,
					years_experience: null
				}
			],
			ventureTeamMember: {
				venture_id: null,
				venture_team_members: []
			}
		};
	},
	mounted() {
		window.onscroll = () => {
			this.changeColor();
		};
	},
	created() {
		window.scrollTo(0, 0);
		this.changeHeaderColor("white");
		this.changeTitleColor("red--text");
		this.changeButtonColor("red--text hidden-sm-and-down text-none caption");

		this.checkActiveProgram()
		this.assignUser()
		this.populateYears();
	},
	methods: {
		...mapActions([
			"changeHeaderColor",
			"changeTitleColor",
			"changeButtonColor"
		]),
		changeColor() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				this.changeHeaderColor("white");
				this.changeTitleColor("black--text");
				this.changeButtonColor("black--text hidden-sm-and-down text-none caption");
			} else {
				this.changeHeaderColor("white");
				this.changeTitleColor("white--text");
				this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
			}
		},
		uploadDocument(doctype){
			this.docType = doctype
			this.pdfDialog = true
		},
		viewDocument(doctype){
			this.docType = doctype
			this.pdfViewDialog = true
		},
		checkActiveProgram(){
			apiCall({
				url: "/api/program?type=programDetails",
				method: "GET",
			})
				.then((resp) => {
					this.programDetails = resp;
					this.getFormData();
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
		addTeamMember() {
			if(this.teamMembers.length <=4){
				let obj = {}
				obj['id'] = null
				obj['venture_id'] = null
				obj['first_name'] = null
				obj['middle_name'] = null
				obj['last_name'] = null
				obj['founder'] = null
				obj['shares'] = null
				obj['gender_id'] = null
				obj['role'] = null
				obj['years_experience'] = null

				this.teamMembers.push(obj)
			}else{
				this.snackbar = true;
				this.message = "Maximum No. of Team Members Reached";
				this.color = "error";
			}
			
		},
		removeTeamMember(index) {
			if (this.teamMembers.length != 0) {
				this.teamMembers.splice(index, 1);
			}
		},
		populateYears() {
			var max = new Date().getFullYear();
			var min = max - 100;
			var years = [];

			for (var i = max; i >= min; i--) {
				years.push(i);
			}
			this.years = years;
		},
		assignUser() {
			this.newUser.id = this.getProfile.id
			this.newUser.first_name = this.getProfile.first_name
			this.newUser.middle_name = this.getProfile.middle_name
			this.newUser.last_name = this.getProfile.last_name
			this.newUser.email = this.getProfile.email
			this.newUser.phone = this.getProfile.phone
			this.newUser.dob = this.getProfile.dob
			this.newUser.bio = this.getProfile.bio
			if (this.getProfile.country_id == null) {
				this.newUser.country_id = 114
			} else {
				this.newUser.country_id = this.getProfile.country_id
			}
			this.newUser.gender_id = this.getProfile.gender_id
			this.newUser.linkedin_url = this.getProfile.linkedin_url
			this.newUser.twitter_url = this.getProfile.twitter_url

			this.newUser.isplwd = this.getProfile.isplwd
			this.newUser.ncplwd_doi = this.getProfile.ncplwd_doi
			this.newUser.ncplwd_no = this.getProfile.ncplwd_no
			this.newUser.nature_of_disability = this.getProfile.nature_of_disability

			this.newUser.admin_proficiency = this.getProfile.admin_proficiency
            this.newUser.managing_people_proficiency = this.getProfile.managing_people_proficiency
            this.newUser.marketing_proficiency = this.getProfile.marketing_proficiency
            this.newUser.product_proficiency = this.getProfile.product_proficiency
            this.newUser.operations_proficiency = this.getProfile.operations_proficiency
            this.newUser.financial_proficiency = this.getProfile.financial_proficiency
            this.newUser.information_proficiency = this.getProfile.information_proficiency
			console.log('<><>><><><>><>><><><><>'+this.newUser.information_proficiency);
		},
		closeDateOfBirth() {
			this.dateOfBirth = false;
		},
		closeDateOfIssuance() {
			this.dateOfIssuance = false;
		},
		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.imageFile, this.imageFile.name);

				if (this.e1 == 2) {
					apiCall({
						url: "/api/venture?type=image",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Image Uploaded Successfully";
							this.color = "success";

							this.imageLoading = false;
							this.imageDialog = false;
							this.imageFile = null

							this.$nextTick(() => {
								this.newVenture.image = resp.image
							});
						})
						.catch((error) => {
							this.imageLoading = false;
						});
				} else if (this.e1 == 1) {
					apiCall({
						url: "/api/user?type=image",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Image Uploaded Successfully";
							this.color = "success";
							this.$store.dispatch(USER_REQUEST);
							this.imageLoading = false;
							this.imageDialog = false;
							this.imageFile = null
						})
						.catch((error) => {
							this.imageLoading = false;
						});
				}

			}
		},
		uploadPDF(){
			if (this.$refs.pdfForm.validate()) {
				if (this.pdfFile == null) {
					this.message = "Please select a file to upload";
					this.color = "error";
					this.snackbar = true;
					return false;
				}
				if (this.pdfFile.size > 20000000) {
					this.message = "File size should be less than 20MB!";
					this.color = "error";
					this.snackbar = true;
					return false;
				}
				if (this.pdfFile.type != "application/pdf") {
					this.message = "Upload Failed! Please select a .PDF file";
					this.color = "error";
					this.snackbar = true;
					return false;
				}

				this.pdfLoading = true;
				let formData = new FormData();

				// files

				formData.append("files", this.pdfFile, this.pdfFile.name);

				if (this.docType == 'CR12') {
					apiCall({
						url: "/api/venture?type=CR12",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "CR12 Uploaded Successfully";
							this.color = "success";

							this.pdfLoading = false;
							this.pdfDialog = false;
							this.pdfFile = null

							this.$nextTick(() => {
								this.newVenture.cr12 = resp.cr12
							});
						})
						.catch((error) => {
							this.pdfLoading = false;
						});
				} else if (this.docType == 'Registration Certificate') {
					apiCall({
						url: "/api/venture?type=registrationCertificate",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Registration Certificate Uploaded Successfully";
							this.color = "success";
							
							this.pdfLoading = false;
							this.pdfDialog = false;
							this.pdfFile = null

							this.$nextTick(() => {
								this.newVenture.registration_certificate = resp.registration_certificate
							});
						})
						.catch((error) => {
							this.pdfLoading = false;
						});
				} else if (this.docType == 'TCC') {
					apiCall({
						url: "/api/venture?type=TCC",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Tax Compliance Certificate Uploaded Successfully";
							this.color = "success";

							this.pdfLoading = false;
							this.pdfDialog = false;
							this.pdfFile = null

							this.$nextTick(() => {
								this.newVenture.tax_compliance_certificate = resp.tcc
							});
						})
						.catch((error) => {
							this.pdfLoading = false;
						});
				} else if (this.docType == 'AFA') {
					apiCall({
						url: "/api/venture?type=AFA",
						data: formData,
						method: "POST",
					})
						.then((resp) => {
							this.snackbar = true;
							this.message = "Audited Financial Accounts Uploaded Successfully";
							this.color = "success";

							this.pdfLoading = false;
							this.pdfDialog = false;
							this.pdfFile = null

							this.$nextTick(() => {
								this.newVenture.audited_financial_accounts = resp.afa
							});
						})
						.catch((error) => {
							this.pdfLoading = false;
						});
				}

			}
		},
		updateProfile() {
			if (this.$refs.profileForm.validate()) {
				if(this.checkIfAllowed('nationality', this.newUser.country_id) == false){
					//Resp will come from function	
				}else{
					confirm("Are you sure you want to update your profile?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/user/" + this.newUser.id + "?type=user",
							data: this.newUser,
							method: "PUT",
						})
							.then((resp) => {
								window.scrollTo(0, 0);
								this.message = "Profile Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.$store.dispatch(USER_REQUEST);

								this.e1 = 2

								this.getVenture()
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},
		saveVenture() {
			if (this.$refs.ventureForm.validate()) {
				if (this.newVenture.description == null) {
					this.message = "Please add a Business Overview";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				} else if (this.newVenture.sectors.length == 0) {
					this.message = "Please add at least one sector";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				} else if (this.newVenture.cr12 == null && this.newVenture.has_cr12_cert == 1) {
					this.message = "Please upload a CR12 Certificate";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				} else if (this.newVenture.registration_certificate == null) {
					this.message = "Please upload a Registration Certificate";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				} else if (this.newVenture.tax_compliance_certificate == null) {
					this.message = "Please upload a Valid Tax Compliance Certificate";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				} else if(this.checkIfAllowed('gender', this.newVenture.gender_id) == false || 
					this.checkIfAllowed('ownership', this.newVenture.ownership_percent) == false || 
					this.checkIfAllowed('nationality', this.newUser.country_id) == false){
					//Resp will come from function	
				}				
				else {
					confirm("Are you sure you want to save Business Profile?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/venture",
							data: this.newVenture,
							method: "POST",
						})
							.then((resp) => {
								window.scrollTo(0, 0);
								this.message = "Business Profile Saved Successfully";
								this.color = "green";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;

								this.newVenture.id = resp.id

								this.e1 = 3
								this.getQuestions()

							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}

			}
		},
		getFormData() {
			apiCall({
				url: "/api/user?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;

					this.getVenture()
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
		getVenture() {
			apiCall({
				url: "/api/venture?type=owner",
				method: "GET",
			})
				.then((resp) => {
					if (resp.code == 200) {
						this.$nextTick(() => {
							this.newVenture.image = resp.venture.image
						});
						this.newVenture.id = resp.venture.id

						if (resp.venture.name != null) {
							this.newVenture.name = resp.venture.name
						}
						if (resp.venture.email_address != null) {
							this.newVenture.email_address = resp.venture.email_address
						}
						if (resp.venture.phone_number != null) {
							this.newVenture.phone_number = resp.venture.phone_number
						}

						this.newVenture.slogan = resp.venture.slogan
						this.newVenture.year_established = parseInt(resp.venture.year_established)
						this.newVenture.description = resp.venture.description
						// this.newVenture.pitch = resp.venture.pitch
						this.newVenture.video_pitch_url = resp.venture.video_pitch_url
						this.newVenture.physical_address = resp.venture.physical_address

						this.newVenture.website_url = resp.venture.website_url
						this.newVenture.x_url = resp.venture.x_url
						this.newVenture.linkedin_url = resp.venture.linkedin_url
						this.newVenture.facebook_url = resp.venture.facebook_url
						this.newVenture.youtube_url = resp.venture.youtube_url
						this.newVenture.instagram_url = resp.venture.instagram_url
						this.newVenture.tiktok_url = resp.venture.tiktok_url
						this.newVenture.stage_id = resp.venture.step_id
						this.newVenture.funding = resp.venture.funding
						this.newVenture.cr12 = resp.venture.cr12
						this.newVenture.registration_certificate = resp.venture.registration_certificate
						this.newVenture.tax_compliance_certificate = resp.venture.tcc
						this.newVenture.audited_financial_accounts = resp.venture.afa

						this.newVenture.gender_id = resp.venture.gender_id
						this.newVenture.ownership_percent = resp.venture.ownership_percent
						this.newVenture.noofemployees = resp.venture.noofemployees
						this.newVenture.county_id = resp.venture.county_id
						this.newVenture.category = resp.venture.category
						this.newVenture.business_type = resp.venture.business_type
						this.newVenture.annualTO2021 = resp.venture.annualTO2021
						this.newVenture.annualTO2022 = resp.venture.annualTO2022
						this.newVenture.annualTO2023 = resp.venture.annualTO2023
						this.newVenture.annualRev2024 = resp.venture.annualRev2024
						this.newVenture.annualRev2025 = resp.venture.annualRev2025
						this.newVenture.annualRev2026 = resp.venture.annualRev2026
						this.newVenture.annualCosts2024 = resp.venture.annualCosts2024
						this.newVenture.annualCosts2025 = resp.venture.annualCosts2025
						this.newVenture.annualCosts2026 = resp.venture.annualCosts2026

						if(resp.venture.has_directors == 1){
							this.newVenture.has_directors = resp.venture.has_directors
						}else{
							this.newVenture.has_directors = 2
						}
						if(resp.venture.has_cr12_cert == 1){
							this.newVenture.has_cr12_cert = resp.venture.has_cr12_cert
						}else{
							this.newVenture.has_cr12_cert = 2
						}

						this.newVenture.sectors = []
						this.newVenture.client_locations = []
						this.newVenture.client_types = []

						for (var x = 0; x < resp.venture.venture_sectors.length; x++) {
							this.newVenture.sectors.push(resp.venture.venture_sectors[x].sector_id)
						}
						for (var x = 0; x < resp.venture.venture_client_locations.length; x++) {
							this.newVenture.client_locations.push(resp.venture.venture_client_locations[x].client_location_id)
						}
						for (var x = 0; x < resp.venture.venture_client_types.length; x++) {
							this.newVenture.client_types.push(resp.venture.venture_client_types[x].client_type_id)
						}

					}

				})
				.catch((error) => {

				});
		},
		getQuestions() {
			apiCall({
				url: "/api/question?type=public",
				method: "GET",
			})
				.then((resp) => {
					this.questions = resp;
					this.totalQuestions();
					this.getAnswers()
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},

		getAnswers() {
			apiCall({
				url: "/api/answer",
				method: "GET",
			})
				.then((resp) => {
					this.answers = resp
					this.assignAnwers()
				})
				.catch((error) => {

				});
		},
		getTeam() {
			apiCall({
				url: "/api/venture-team-member?venture_id=" + this.newVenture.id,
				method: "GET",
			})
				.then((resp) => {
					if (resp.length != 0) {
						this.teamMembers = resp
					}
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Team Members";
					this.color = "error";
				});
		},
		getApplication(){
			apiCall({
				url: "/api/application?type=application",
				method: "GET",
			})
				.then((resp) => {
					if (resp.status == 200) {
						this.newApplication.marketing_source_id = resp.application.marketing_source_id
						this.newApplication.terms_conditions = resp.application.terms_conditions
						this.newApplication.advertMedia = resp.application.advertMedia
						this.newApplication.otherSpecify = resp.application.otherSpecify
						this.newApplication.contribution = resp.application.contribution
						this.newApplication.dietary_concerns = resp.application.dietary_concerns
					}
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Team Members";
					this.color = "error";
				});
		},

		checkProgressArray() {
			var found = false;
			for (var i = 0; i < this.progress.length; i++) {
				if (this.progress[i].id == this.$route.params.id) {
					found = true;
				}
			}
			if (found == false) {
				this.progressData.id = parseInt(this.$route.params.id)
				let progressCopy = this.progress;
				progressCopy.push(Object.assign({}, this.progressData));
				this.updateprogress(progressCopy);
			}
		},
		checkOther(questionId, option, question) {
			if (option.option == "Other") {
				this.otherQuestions[questionId] = true;
			} else {
				this.otherQuestions[questionId] = false;
				//this.openEnded[questionId] = null;
			}
			if (option.option == "Yes"
				|| option.option == "Referred by a friend"
				|| option.option == "Yes, all of them"

			) {
				const index = this.questions.indexOf(question);
				if (this.childQuestions[questionId] === undefined) {
					this.questionCount += this.questions[index].child_questions.length
				}

				if (this.questions[index].child_questions.length != 0) {
					this.childQuestions[questionId] = this.questions[index].child_questions

				}


			} else if (option.option == "No"
				|| option.option == "Event (at school or in college)"
				|| option.option == "Relative"
				|| option.option == "Other"
				|| option.option == "No, none of them"
				|| option.option == "Majority of them"
				|| option.option == "A few of them"
				|| option.option == "Not sure"
				|| option.option == "Not Comfortable Disclosing"


			) {
				const index = this.questions.indexOf(question);
				if (this.questions[index].child_questions.length != 0) {
					this.childQuestions[questionId] = []
					if (question.question_type.name == 'Single Select Multiple Choice Question') {
						for (let i = 0; i < question.child_questions.length; i++) {
							if (this.singleSelect[question.child_questions[i].id] != null) {
								this.singleSelect[question.child_questions[i].id] = null
								this.questionCount -= 1
							}
						}
					} else if (question.question_type.name == 'Multi Part Single Select Multiple Choice Question') {
						for (let m = 0; m < question.multi_part_questions.length; m++) {
							this.multiPartSingleAnswers[question.multi_part_questions[m].id] = null
						}
					}
					// else if(question.question_type.name == 'Multi Select Multiple Choice Questions'){
					//   for (let k=0; k<question.multi_choice_options.length; k++){
					//     this.multiSelects[question.multi_part_questions[m].id] = false
					//   }
					// }
				}
			}
			this.calculateProgress()
		},
		assignAnwers() {
			if (this.answers.length != 0) {
				this.openEnded = this.answers[0];
				this.singleSelect = this.answers[1];
				this.multiPartSingleAnswers = this.answers[2];
				this.multiPartMultiAnswers = this.answers[3];
				this.multiSelects = this.answers[4];
			}
			this.calculateProgress()
		},
		trackInputField(questionId) {
			if (this.openEnded[questionId] == "") {
				this.openEnded[questionId] = null
			}
			this.calculateProgress()
		},
		totalQuestions() {
			var total = 0;
			for (var i = 0; i < this.questions.length; i++) {
				if (this.questions[i].question_type.name == 'Single Select Multiple Choice Question' || this.questions[i].question_type.name == 'Open Ended' || this.questions[i].question_type.name == 'Multi Select Multiple Choice Questions') {
					total = total + 1;
				} else if (this.questions[i].question_type.name == 'Multi Part Single Select Multiple Choice Question' || this.questions[i].question_type.name == 'Multi Part Multi Select Multiple Choice Question') {
					total = total + this.questions[i].multi_part_questions.length;
				}
			}
			this.questionCount = total
		},
		calculateProgress() {
			this.$nextTick(() => {
				let singles = Object.values(this.singleSelect)
				let openEndedKeys = Object.keys(this.openEnded)
				//let openEndedValues = Object.values(this.openEnded)
				let multiPartSingles = Object.values(this.multiPartSingleAnswers)
				let multiPartMultis = this.multiPartMultiAnswers
				let multis = this.multiSelects

				var answers = 0
				for (var i = 0; i < singles.length; i++) {
					if (singles[i] != null) {
						answers = answers + 1
					}
				}

				for (var i = 0; i < this.questions.length; i++) {
					if (this.questions[i].child_questions.length != 0) {
						for (var x = 0; x < this.questions[i].child_questions.length; x++) {
							if (this.questions[i].child_questions[x].question_type.name == 'Open Ended') {
								if (this.openEnded[this.questions[i].child_questions[x].id] != null) {
									answers = answers + 1
								}
							}
						}
					}
				}

				for (var i = 0; i < openEndedKeys.length; i++) {

					let a = this.questions.find((question) => question.id == openEndedKeys[i]);
					if (a && Object.values(a.question_type)[1] == "Open Ended") {

						if (this.openEnded[a.id] != null) {
							answers = answers + 1
						}
					}
				}

				for (var i = 0; i < multiPartSingles.length; i++) {
					if (multiPartSingles[i] != null) {
						answers = answers + 1
					}
				}

				for (var i = 0; i < this.questions.length; i++) {
					if (this.questions[i].question_type.name == 'Multi Part Multi Select Multiple Choice Question') {
						let multiPartQuestions = this.questions[i].multi_part_questions
						for (var x = 0; x < multiPartQuestions.length; x++) {
							let multipartOptions = multiPartQuestions[x].multi_part_options
							for (var y = 0; y < multipartOptions.length; y++) {
								if (multiPartMultis.includes(multipartOptions[y].id)) {
									answers = answers + 1
									break;
								}
							}
						}
					} else if (this.questions[i].question_type.name == 'Multi Select Multiple Choice Questions') {
						let multiOptions = this.questions[i].multi_choice_options
						for (var z = 0; z < multiOptions.length; z++) {
							if (multis.includes(multiOptions[z].id)) {
								answers = answers + 1
								break;
							}
						}
					}
				}
				this.progressBar = parseInt((answers / this.questionCount) * 100)
				if (this.progressBar > 100) {
					this.progressBar = 100
				}
				if (this.progressBar >= 25 && this.progressBar <= 26) {
					this.message = "You're on the right track keep going!";
					this.color = "red darken-1";
					this.snackbar = true;
				} else if (this.progressBar >= 50 && this.progressBar <= 51) {
					this.message = "This module is 50% done keep going!";
					this.color = "orange darken-1";
					this.snackbar = true;
				} else if (this.progressBar >= 75 && this.progressBar <= 76) {
					this.message = "You're almost there well done!";
					this.color = "orange darken-5";
					this.snackbar = true;
				} else if (this.progressBar == 100) {
					this.message = "Congratulations on finishing this module!";
					this.color = "green darken-5";
					this.snackbar = true;
				}
			});
		},
		progressColor() {
			if (this.progressBar <= 8) {
				return "red darken-1";
			} else if (this.progressBar >= 9 && this.progressBar <= 16) {
				return "red darken-2";
			} else if (this.progressBar >= 17 && this.progressBar <= 24) {
				return "red darken-3";
			} else if (this.progressBar >= 25 && this.progressBar <= 32) {
				return "red darken-4";
			} else if (this.progressBar >= 33 && this.progressBar <= 40) {
				return "orange darken-1";
			} else if (this.progressBar >= 41 && this.progressBar <= 48) {
				return "orange darken-2";
			} else if (this.progressBar >= 49 && this.progressBar <= 56) {
				return "orange darken-3";
			} else if (this.progressBar >= 57 && this.progressBar <= 64) {
				return "orange darken-4";
			} else if (this.progressBar >= 65 && this.progressBar <= 72) {
				return "green darken-1";
			} else if (this.progressBar >= 73 && this.progressBar <= 80) {
				return "green darken-2";
			} else if (this.progressBar >= 81 && this.progressBar <= 88) {
				return "green darken-3";
			} else if (this.progressBar >= 89 && this.progressBar <= 100) {
				return "green darken-4";
			}
		},
		saveAnswers() {
			confirm("Are you sure you want to Submit your Answers?") && (this.confirm = true);
			if (this.confirm) {
				this.applicationAnswers[0] = this.openEnded;
				this.applicationAnswers[1] = this.singleSelect;
				this.applicationAnswers[2] = this.multiPartSingleAnswers;
				this.applicationAnswers[3] = this.multiPartMultiAnswers;
				this.applicationAnswers[4] = this.multiSelects;

				this.application.answers = this.applicationAnswers

				this.loading = true;

				apiCall({ url: "/api/answer", data: this.application, method: "POST" })
					.then((resp) => {
						window.scrollTo(0, 0);
						this.message = resp.message;
						this.color = "green";
						this.loading = false;
						this.snackbar = true;
						this.confirm = false;

						this.e1 = 4

						this.getTeam()
					})
					.catch((error) => {
						this.message = "An error occurred.";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					});
			}
		},

		saveTeamMembers() {
			this.ventureTeamMember.venture_id = this.newVenture.id
			this.ventureTeamMember.venture_team_members = this.teamMembers

			this.loading = true;

			apiCall({ url: "/api/venture-team-member", data: this.ventureTeamMember, method: "POST" })
				.then((resp) => {
					window.scrollTo(0, 0);
					this.message = "Team Members Added Successfully";
					this.color = "green";
					this.loading = false;
					this.snackbar = true;
					this.confirm = false;

					this.e1 = 5

					this.getApplication()
				})
				.catch((error) => {
					this.message = "An error occurred. Please Try Again";
					this.color = "error";
					this.loading = false;
					this.snackbar = true;
				});
		},
		submitApplication() {
			if (this.newApplication.marketing_source_id == null) {
				this.message = "Kindly indicate where you first learnt about the Program";
				this.color = "error";
				this.loading = false;
				this.snackbar = true;
			} else if (this.newApplication.terms_conditions == null) {
				this.message = "Kindly agree to the Terms and Conditions of the Program";
				this.color = "error";
				this.loading = false;
				this.snackbar = true;
			} else {
				confirm("Are you sure you want to Submit your Application?") && (this.confirm = true);
				if (this.confirm) {
					this.loading = true;
					apiCall({ url: "/api/application", data: this.newApplication, method: "POST" })
						.then((resp) => {
							window.scrollTo(0, 0);
							this.message = "Application Received Successfully";
							this.color = "green";
							this.loading = false;
							this.snackbar = true;
							this.confirm = false;

							this.completeApplication = true
						})
						.catch((error) => {
							this.message = "An error occurred. Please Try Again";
							this.color = "error";
							this.loading = false;
							this.snackbar = true;
						});
				}
			}
		},
		deleteCertificate(type) {
			confirm("Are You Sure You Want to Delete File?") && (this.delete = true);
			if (this.delete) {
				this.certLoading = true;
				if(type == 'CR12'){
					apiCall({ 
						url: "/api/venture?type=cr12Delete", 
						data: this.newVenture,
						method: "POST",
					 })
					.then((resp) => {
						this.message = "Certificate Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						this.pdfViewDialog = false;
						this.$nextTick(() => {
							this.newVenture.cr12 = null
						});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						
					});
				}else if(type == 'TCC'){
					apiCall({ 
						url: "/api/venture?type=tccDelete", 
						data: this.newVenture,
						method: "POST",
					 })
					.then((resp) => {
						this.message = "Certificate Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						this.pdfViewDialog = false;
						this.$nextTick(() => {
							this.newVenture.tax_compliance_certificate = null
						});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						
					});
				}else if(type == 'AFA'){
					apiCall({ 
						url: "/api/venture?type=afaDelete", 
						data: this.newVenture,
						method: "POST",
					 })
					.then((resp) => {
						this.message = "Accounts Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						this.pdfViewDialog = false;
						this.$nextTick(() => {
							this.newVenture.audited_financial_accounts = null
						});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						
					});
				}else{
					apiCall({ 
						url: "/api/venture?type=registrationCertificateDelete", 
						data: this.newVenture,
						method: "POST",
					 })
					.then((resp) => {
						this.message = "Certificate Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						this.pdfViewDialog = false;
						this.$nextTick(() => {
							this.newVenture.registration_certificate = null
						});
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.certLoading = false;
						this.delete = false;
						
					});
				}
				
			}
		},
		autoFillEmailAddress(){
			this.newVenture.email_address = null
			if(this.afEmailAddress == true){
				this.newVenture.email_address = this.newUser.email
			}			
		},
		autoFillPhoneNumber(){
			this.newVenture.phone_number = null
			if(this.afPhoneNumber == true){
				this.newVenture.phone_number = this.newUser.phone
			}
		},
		checkIfAllowed(type, value){
			if(type == 'gender'){
				let a = this.formData.genders.find((gender) => gender.id == value);
				if(a != null){
					if(a.name == 'Female'){	
						this.valid = true;
						return true;
					}
				}
			}
			if(type == 'ownership'){
				if(value == 1){
					this.valid = true;
					return true;
				}
			}
			if(type == 'nationality'){
				let a = this.formData.countries.find((country) => country.id == value);
				if(a != null){
					if(a.name == 'Kenya'){		
						this.valid = true;			
						return true;
					}
				}
			}

							
			this.message = "Please refer to the Eligibility Criteria";
			this.color = "error";
			this.snackbar = true;
			this.valid = false;

			return false;
		},
	},
	computed: {
		...mapGetters([
			"darkState",
			"getProfile"
		]),
		formatDateOfBirth() {
			if (this.newUser.dob != null) {
				const d = new Date(this.newUser.dob);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatDateOfIssuance() {
			if (this.newUser.ncplwd_doi != null) {
				const d = new Date(this.newUser.ncplwd_doi);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				(today.getFullYear() - 17) +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>
