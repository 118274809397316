import apiCall from '@/utils/api'

const state = {
	videos: [],
	allVideos: [],
    videoPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    videoLoader: false
};

const getters = {
	videos: (state) => state.videos,
	allVideos: (state) => state.allVideos,
    videoLoader: (state) => state.videoLoader,
    videoPagination: (state) => state.videoPagination
}

const actions = {
	async fetchAllVideos({commit}, page) {
		const response = await apiCall({url: `/api/video?type=all`, method: 'GET' });
		commit('setAllVideos', response)
        commit('stopLoader', response)
	},
    async fetchVideos({commit}, page) {
		const response = await apiCall({url: `/api/video?page=${page}`, method: 'GET' });
		commit('setVideos', response)
        commit('stopLoader', response)
	},
    async filterVideos({commit, state},resp){
		commit('setVideos', resp)
	},
	async startVideoLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopVideoLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setVideos: (state, videos) => {
		state.videos = videos.data
		state.videoPagination.current_page = videos.current_page
		state.videoPagination.total = videos.total
		state.videoPagination.per_page = videos.per_page
	},
	setAllVideos: (state, videos) => {
		state.allVideos = videos
	},
    startLoader: (state) => state.videoLoader = true,
	stopLoader: (state) => state.videoLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
