const state = {
	quickStats: [],
	quickStatsLoader: false,
};

const getters = {
	quickStats: (state) => state.quickStats,
	quickStatsLoader: (state) => state.quickStatsLoader,
};

const actions = {
	async updateQuickStats({commit}, resp) {
		commit('updateQuickStats', resp)
		commit('stopQuickStatsLoader', resp)
	},
	
	async startQuickStatsLoader({commit, state},resp){
		commit('startQuickStatsLoader', resp)
	},
	async stopQuickStatsLoader({commit, state},resp){
		commit('stopQuickStatsLoader', resp)
	},	
};

const mutations = {
	updateQuickStats: (state, quickStats) => (state.quickStats = quickStats),
	startQuickStatsLoader: (state) => state.quickStatsLoader = true,
	stopQuickStatsLoader: (state) => state.quickStatsLoader = false,	
};

export default {
	state,
	getters,
	actions,
	mutations
}