<template>
	<div class="pastCohorts" v-if="$can('past_cohort_view')">
		<v-dialog
			transition="dialog-bottom-transition"
			max-width="600"
			v-model="imageDialog"
		>
			<v-card max-width="600">
			<v-toolbar flat>
				Upload Image
				<v-spacer></v-spacer>
				<v-btn icon @click="imageDialog = false">
				<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
	
			<v-form ref="imageForm" v-model="imageValid" lazy-validation>
				<v-layout column>
				<v-flex xs12>
					<v-file-input
					class="mx-2 mt-3"
					:loading="imageLoading"
					:rules="fileRules"
					filled
					dense
					show-size
					chips
					accept="image/*"
					v-model="imageFile"
					label="Select Image"
					></v-file-input>
				</v-flex>
				<v-flex xs12>
					<div align="right" class="mb-3 mx-3">
					<v-btn
						depressed
						block
						class="primary text-none"
						@click="uploadImage"
						:loading="imageLoading"
						:disabled="!imageValid"
					>
						Submit <v-icon right dark>mdi-upload</v-icon>
					</v-btn>
					</div>
				</v-flex>
				</v-layout>
			</v-form>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>Past Cohorts</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()"
												v-on:keyup.enter="search" v-model="searchTerm"
												@input="enableSearch()"></v-text-field>
										</div>

									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block
												@click="search" :loading="searchLoader"
												:disabled="searchButtonDisabled">
												Search
												<v-icon right class="white--text">mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none"
												@click="changeView('create')" v-if="$can('past_cohort_create')">
												Add Cohort
												<v-icon right class="white--text">mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="pastCohortLoader" height="1" indeterminate
								color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="pastCohorts.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Past Cohort(s) Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">#</th>
														<th class="text-left text--text">Name</th>
														<th class="text-left text--text">No. of Venture(s)</th>
														<th class="text-left text--text">Active</th>
														<!-- <th class="text-left text--text">Date Added</th> -->
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in pastCohorts" :key="item.id">
														<td>
															{{ index + 1 }}
														</td>
														<td>{{ item.name }} </td>
														<td>{{ item.competitors.length }}</td>
														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<!-- <td>
															{{
		item.created_at | moment("DD/MM/YYYY - hh:mm a")
	}}
														</td> -->
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('past_cohort_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text"
																			@click="showPastCohort(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('past_cohort_edit')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text"
																			@click="editPastCohort(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('past_cohort_archive')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text"
																			@click="deletePastCohort(item)"
																			:loading="loading && pastCohortIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top
																	v-if="$can('past_cohort_activate') && item.active == 0">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text"
																			@click="activatePastCohort(item)"
																			:loading="activateLoading && pastCohortIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate Cohort</span>
																</v-tooltip>
																<v-tooltip top
																	v-if="$can('past_cohort_deactivate') && item.active == 1">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text"
																			@click="deactivatePastCohort(item)"
																			:loading="deactivateLoading && pastCohortIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate Cohort</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text"
																			@click="viewLog(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>

										<template v-for="(pastCohort, index) in pastCohorts">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title primary--text ml-3">
																				<b>{{ pastCohort.name }} </b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && pastCohortIndex == pastCohort.id
																				" icon @click="deletePastCohort(pastCohort)" v-if="$can('past_cohort_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>No. of Venture(s)</b>
																			</div>
																			<div>{{ pastCohort.competitors.length }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="pastCohort.active == 1">Yes
																				</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																



																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn
																							v-if="$can('past_cohort_view')"
																							depressed class="
																							primary
																							text-none
																							white--text
																							" block @click="showPastCohort(pastCohort)">View
																							<v-icon right> mdi-eye
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="blue text-none white--text"
																							block
																							@click="editPastCohort(pastCohort)"
																							v-if="$can('past_cohort_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="green text-none white--text"
																							block
																							@click="activatePastCohort(pastCohort)"
																							:loading="activateLoading && pastCohortIndex == pastCohort.id"
																							v-if="$can('past_cohort_activate') && pastCohort.active == 0">Activate
																							Cohort
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="red text-none white--text"
																							block
																							@click="deactivatePastCohort(pastCohort)"
																							:loading="deactivateLoading && pastCohortIndex == pastCohort.id"
																							v-if="$can('past_cohort_deactivate') && pastCohort.active == 1">Deactivate
																							Cohort
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')"
																							depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(pastCohort)">Log
																							<v-icon right>
																								mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="pastCohortPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ pastCohortPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newPastCohort.id == null">Add Cohort</div>
								<div v-else>Edit Cohort</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-5">
												<v-flex xs12 md4>
													<div class="pa-3">
														<div>Name<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense
															v-model="newPastCohort.name"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>


											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
									:loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedPastCohort.name }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="saleTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab @click="activeTab = 'competitors'">
										<div class="text-none primary--text">
											Ventures ({{ assignedPastCohort.competitors.length }})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab == 'details'" class="mx-3 mt-7">
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Name:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedPastCohort.name }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Ventures:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedPastCohort.competitors.length }}
												</v-flex>
											</v-layout>
										</v-flex>
										<!-- <v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Date Created:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedPastCohort.created_at | moment("DD/MM/YYYY - hh:mm a") }}
												</v-flex>
											</v-layout>
										</v-flex> -->
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Active:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													<div v-if="assignedPastCohort.active == 1">Yes</div>
													<div v-else>No</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</div>
								<div v-if="activeTab == 'competitors'" class="mx-1">
									<div v-if="competitorView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<div v-if="competitorLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																competitorPagination.total |
																formatNumber }}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field header" outlined dense
																	label="Search" append-icon="mdi-undo-variant"
																	@click:append="
																		resetCompetitorSearch()
																		" v-on:keyup.enter="competitorSearch
																		" v-model="competitorSearchTerm" @input="
																		enableCompetitorSearch()
																		"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="secondary white--text text-none" depressed
																	block @click="competitorSearch" :loading="competitorSearchLoader
																	" :disabled="competitorSearchButtonDisabled">
																	Search
																	<v-icon class="white--text" small right>mdi-magnify</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn block depressed class="primary text-none" @click="changeCompetitorView('create')
																	" v-if="$can('competitor_create')">
																	Add Competitor
																	<v-icon
																		class="white--text" small right>mdi-plus-circle-outline</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="competitors.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Competitor Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>

																<template v-slot:default>
																	<thead class="header">
																		<tr class="secondary">
																			<th class="text-left text--text">Name</th>
																			<th class="text-left text--text">Status</th>
																			<th class="text-right text--text">Actions
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="item in competitors" :key="item.id">
																			<td>
																				{{ item.name }}
																			</td>
																			<td>
																				<div v-if="item.active == 1">
																					Active
																				</div>
																				<div v-else>Inactive</div>
																			</td>
																			<td>
																				<div align="right">
																					<v-btn v-if="$can('competitor_view')"
																						icon class="
																								button
																								mr-1
																								primary--text
																							" @click="showCompetitor(item)">
																						<v-icon small> mdi-eye </v-icon>
																					</v-btn>
																					<v-btn v-if="$can('competitor_edit')"
																						icon
																						class="button mr-1 success--text"
																						@click="editCompetitor(item)">
																						<v-icon small> mdi-pencil
																						</v-icon>
																					</v-btn>
																					<v-btn v-if="$can(
																						'competitor_archive'
																					)
																						" icon class="button mr-1 error--text" @click="
																						deleteCompetitor(
																							item
																						)
																						" :loading="loading &&
																						competitorIndex ==
																						item.id
																						">
																						<v-icon small> mdi-delete
																						</v-icon>
																					</v-btn>
																					<v-tooltip top v-if="$can(
																							'competitor_activate'
																						) && item.active == 0
																							">
																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 red--text"
																								@click="
																									activateCompetitor(
																										item
																									)
																									" :loading="activateLoading &&
																									competitorIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch-off-outline
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Activate Competitor</span>
																					</v-tooltip>
																					<v-tooltip top v-if="$can(
																						'competitor_deactivate'
																					) && item.active == 1
																						">

																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 green--text"
																								@click="
																									deactivateCompetitor(
																										item
																									)
																									" :loading="deactivateLoading &&
																									competitorIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Deactivate Competitor</span>
																					</v-tooltip>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>

																<template v-for="(
                                      competitor, index
                                    ) in competitors">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																				style="border-radius: 25px;" outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
		competitor.name
	}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																										class="red--text"
																										:loading="loading &&
		competitorIndex ==
		competitor.id
		" icon @click="
		deleteCompetitor(
			competitor
		)
		" v-if="$can(
			'competitor_archive'
		)
		">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="competitor.active ==
		1
		">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12
																											v-if="$can('competitor_view')">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="primary text-none white--text mt-1"
																													block
																													@click="
																														showCompetitor(
																															competitor
																														)
																														">View
																													<v-icon
																														right>
																														mdi-eye
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can('competitor_edit')">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="success text-none white--text mt-1"
																													block
																													@click="
																														editCompetitor(
																															competitor
																														)
																														">Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'competitor_activate'
	) && competitor.active == 0
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
		competitorIndex ==
		competitor.id
		" @click="
		activateCompetitor(
			competitor
		)
		">Activate Competitor
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'competitor_deactivate'
	) && competitor.active == 1
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
		competitorIndex ==
		competitor.id
		" @click="
		deactivateCompetitor(
			competitor
		)
		">Deactivate Competitor
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="competitorLength"
														total-visible="10" v-model="competitorPagination.current_page
		" @input="changeCompetitorPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="competitorView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="secondary text--text">
												<v-spacer></v-spacer>
												<div v-if="newCompetitor.id == null">
													Add Competitor
												</div>
												<div v-else>Edit Competitor</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
		changeCompetitorView('default')
		">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="competitorForm" lazy-validation>
													<v-layout row wrap class="mt-5">

														<v-flex xs12 md6>
															<div class="mx-1 mt-5">
																<div>
																	Name<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																	outlined dense v-model="newCompetitor.name"
																	:rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md6>
															<div class="mx-1 mt-5">
																<div>
																	Link
																</div>
																<v-text-field class="text_field background my-2"
																	outlined dense v-model="newCompetitor.link"
																></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12>
															<div class="mx-1 mt-5">
																<div>Description<span class="error--text">*</span></div>
																<div class="black--text">
																	<ckeditor :editor="editor" v-model="newCompetitor.description" :config="editorConfig"
																		:rules="inputRules">
																	</ckeditor>
																</div>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>X URL</div>
																<v-text-field outlined dense v-model="newCompetitor.x_url" class="text_field background"
																	prepend-inner-icon="mdi-twitter"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>LinkedIn URL</div>
																<v-text-field outlined dense v-model="newCompetitor.linkedin_url" class="text_field background"
																	prepend-inner-icon="mdi-linkedin"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>Facebook URL</div>
																<v-text-field outlined dense v-model="newCompetitor.facebook_url" class="text_field background"
																	prepend-inner-icon="mdi-facebook"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>YouTube URL</div>
																<v-text-field outlined dense v-model="newCompetitor.youtube_url" class="text_field background"
																	prepend-inner-icon="mdi-youtube"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>Instagram URL</div>
																<v-text-field outlined dense v-model="newCompetitor.instagram_url" class="text_field background"
																	prepend-inner-icon="mdi-instagram"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>TikTok URL</div>
																<v-text-field outlined dense v-model="newCompetitor.tiktok_url" class="text_field background"
																	prepend-inner-icon="mdi-alpha-t"></v-text-field>
															</div>
														</v-flex>

													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveCompetitor"
													:disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save </v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
									<div v-if="competitorView == 'show'">
										<v-card outlined class="mt-5">
											<v-card-title class="tableHeader text--text">
												{{ assignedCompetitor.name }}
												<v-spacer></v-spacer>
												<v-btn icon @click="changeCompetitorView('default')">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												
												


												<div class="mt-2 pa-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<v-layout column>
																<v-flex xs12>
																	<div align="center" class="mt-5">
																		<v-avatar size="200" class="primary title white--text" v-if="assignedCompetitor.image == null">
																			{{ assignedCompetitor.name[0] }}
																		</v-avatar>
																		<v-img v-else max-width="320" :src="path + '/storage/competitor_pics/' + assignedCompetitor.image" style="border-radius: 25px;"/>
																		
																	</div>
																</v-flex>
																<v-flex xs12>
																	<div align="center">
																		<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
																			Upload Pic
																		</v-btn>
																	</div>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 md8>
															<v-layout row wrap class="mt-5">
														<v-flex xs12 md6>
															<v-layout column class="ml-6">
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Name:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.name }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Website Link:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.link }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>LinkedIn:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.linkedin_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Youtube:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.youtube_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Active:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			<div v-if="assignedCompetitor.active == 1">Yes</div>
																			<div v-else>No</div>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 md6>
															<v-layout column class="ml-6">
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>X:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.x_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Facebook:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.facebook_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>Instagram:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.instagram_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-flex xs12 class="mt-5">
																	<v-layout row wrap>
																		<v-flex xs12 md3>
																			<div><b>TikTok:</b></div>
																		</v-flex>
																		<v-flex xs12 md9>
																			{{ assignedCompetitor.tiktok_url }}
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 class="mt-10 ml-6">
															<v-layout row wrap>
																<v-flex xs12>
																	<div><b>Description</b></div>
																</v-flex>
																<v-flex xs12>
																	<div v-html="assignedCompetitor.description"></div>
																</v-flex>
															</v-layout>
														</v-flex>
													</v-layout>
														</v-flex>
													</v-layout>													
												</div>
											</v-card-text>
										</v-card>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			competitorView: "default",
			activeTab: 'details',
			saleTab: null,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			competitorLoader: false,
			imageLoader: false,

			competitorDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			pastCohortIndex: null,
			competitorIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			searchTerm: null,
			competitorSearchTerm: null,

			searchLoader: false,
			competitorSearchLoader: false,

			searchButtonDisabled: false,
			competitorSearchButtonDisabled: false,

			filteredPastCohorts: false,
			filteredCompetitors: false,

			newPastCohort: {
				id: null,
				name: null,
			},
			newCompetitor: {
				id: null,
				past_cohort_id: null,
				name: null,
				description: null,
				link: null,
				x_url: null,
				linkedin_url: null,
				facebook_url: null,
				youtube_url: null,
				instagram_url: null,
				tiktok_url: null,
			},

			assignedPastCohort: null,
			assignedCompetitor: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\PastCohort",
			},

			competitors: [],
			competitorPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},

		};
	},
	created() {
		if (this.$can("past_cohort_view")) {
			this.startPastCohortLoader();
			this.fetchPastCohorts(this.pastCohortPagination.current_page);
			this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchPastCohorts",
			"startPastCohortLoader",
			"stopPastCohortLoader",
			"filterPastCohorts",

			"filterLogData",
			"openLogDataModel",
		]),
		closeCompetitorDate() {
			this.competitorDatePicker = false;
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},
		enableCompetitorSearch() {
			this.competitorSearchButtonDisabled = false;
		},


		resetSearch() {
			this.searchTerm = null;
			this.filteredPastCohorts = true;
			this.startPastCohortLoader();
			this.fetchPastCohorts(1);
		},
		resetCompetitorSearch() {
			this.competitorSearchTerm = null;
			this.filteredCompetitors = false;
			this.competitorLoader = false;
			this.changeCompetitorPage();
		},

		showPastCohort(pastCohort) {
			this.assignedPastCohort = pastCohort;

			this.changeCompetitorPage();
			this.changeView("show");
		},
		showCompetitor(competitor) {
			this.assignedCompetitor = competitor;

			this.changeCompetitorView("show");
		},

		editPastCohort(pastCohort) {
			this.lockEmail = true;
			this.newPastCohort.id = pastCohort.id;
			this.newPastCohort.name = pastCohort.name;

			this.changeView("create");
		},
		editCompetitor(competitor) {
			this.newCompetitor.id = competitor.id;
			this.newCompetitor.past_cohort_id = competitor.past_cohort_id;
			this.newCompetitor.name = competitor.name;
			this.newCompetitor.description = competitor.description;
			this.newCompetitor.link = competitor.link;
			this.newCompetitor.x_url = competitor.x_url;
			this.newCompetitor.linkedin_url = competitor.linkedin_url;
			this.newCompetitor.facebook_url = competitor.facebook_url;
			this.newCompetitor.youtube_url = competitor.youtube_url;
			this.newCompetitor.instagram_url = competitor.instagram_url;
			this.newCompetitor.tiktok_url = competitor.tiktok_url;

			this.changeCompetitorView("create");
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
			this.imageLoading = true;
			let formData = new FormData();
			
			formData.append("id", this.assignedCompetitor.id)	
			formData.append("files", this.imageFile, this.imageFile.name);
	
			apiCall({
				url: "/api/competitor?type=image",
				data: formData,
				method: "POST",
			})
				.then((resp) => {
				this.snackbar = true;
				this.message = "Image Uploaded Successfully";
				this.color = "success";
				this.changeCompetitorPage()
				this.imageLoading = false;
				this.imageDialog = false;
				this.imageFile = null
				this.assignedCompetitor = resp
				})
				.catch((error) => {
				this.imageLoading = false;
				});
			}
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newPastCohort.id != null) {
					confirm("Are You Sure You Want to Update PastCohort") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/past-cohort/" + this.newPastCohort.id,
							data: this.newPastCohort,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "PastCohort Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchPastCohorts(this.pastCohortPagination.current_page);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are You Sure You Want to Create PastCohort?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/past-cohort",
							data: this.newPastCohort,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.alertType = "error";
								} else {
									this.message = "PastCohort Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchPastCohorts(1);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchPastCohorts(1);
							});
					}
				}
			}
		},
		saveCompetitor() {
			if (this.$refs.competitorForm.validate()) {
				if (this.newCompetitor.id != null) {
					if(this.newCompetitor.description == null){
						this.message = "Please add a description";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are you sure you want to update competitor?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newCompetitor.past_cohort_id = this.assignedPastCohort.id
						apiCall({
							url: "/api/competitor/" + this.newCompetitor.id,
							data: this.newCompetitor,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Competitor Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeCompetitorView("default");

								this.$nextTick(() => {
									this.competitors = resp.data;
									this.competitorPagination.current_page = resp.current_page;
									this.competitorPagination.total = resp.total;
									this.competitorPagination.per_page = resp.per_page;
								});
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
					}
					
				} else {
					if(this.newCompetitor.description == null){
						this.message = "Please add a description";
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are you sure you want to add Competitor?") &&
							(this.confirm = true);
						if (this.confirm) {
							this.loading = true;
							this.newCompetitor.past_cohort_id = this.assignedPastCohort.id;
							apiCall({
								url: "/api/competitor",
								data: this.newCompetitor,
								method: "POST",
							})
								.then((resp) => {
									this.message = "Competitor Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.confirm = false;
									this.newCompetitor.pastCohort_id = null;
									this.changeCompetitorView("default");
									this.changeCompetitorPage();
								})
								.catch((error) => {
									this.message = "An Error Occurred";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
								});
						}
					}
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startPastCohortLoader();
				apiCall({
					url: "/api/past-cohort?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterPastCohorts(resp);
						this.searchLoader = false;
						this.stopPastCohortLoader();
						this.filteredPastCohorts = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopPastCohortLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		competitorSearch() {
			if (this.competitorSearchTerm == null) {
				this.competitorSearchButtonDisabled = true;
			} else {
				this.competitorSearchLoader = true;
				this.competitorSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/competitor?type=search&search=" +
						this.competitorSearchTerm +
						"&pastCohort_id=" +
						this.assignedPastCohort.id,
					method: "GET",
				})
					.then((resp) => {
						this.competitors = resp.data;
						this.competitorPagination.current_page =
							resp.current_page;
						this.competitorPagination.total = resp.total;
						this.competitorPagination.per_page = resp.per_page;

						this.competitorSearchLoader = false;
						this.filteredCompetitors = true;
					})
					.catch((error) => {
						this.competitorSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.lockEmail = false;
				this.newPastCohort.id = null;
				this.newPastCohort.name = null;
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},
		changeCompetitorView(view) {
			if (view == "default") {
				this.newCompetitor.id = null;
				this.newCompetitor.past_cohort_id = null;
				this.newCompetitor.name = null;
				this.newCompetitor.description = null;
				this.newCompetitor.link = null;
				this.newCompetitor.x_url = null;
				this.newCompetitor.linkedin_url = null;
				this.newCompetitor.facebook_url = null;
				this.newCompetitor.youtube_url = null;
				this.newCompetitor.instagram_url = null;
				this.newCompetitor.tiktok_url = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.competitorView = view;
		},

		changePage() {
			this.startPastCohortLoader();
			if (this.filteredPastCohorts == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/past-cohort?page=" +
						this.pastCohortPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterPastCohorts(resp);
						this.stopPastCohortLoader();
					})
					.catch((error) => {
						this.stopPastCohortLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchPastCohorts(this.pastCohortPagination.current_page);
			}
		},
		changeCompetitorPage() {
			this.competitorLoader = true;
			if (this.filteredCompetitors == true) {
				this.competitorSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/competitor?pastCohort_id=" +
						this.assignedPastCohort.id +
						"&page=" +
						this.competitorPagination.current_page +
						"&type=search&search=" +
						this.competitorSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.competitorLoader = false;

						this.competitors = resp.data;
						this.competitorPagination.current_page =
							resp.current_page;
						this.competitorPagination.total = resp.total;
						this.competitorPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.competitorLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/competitor?pastCohort_id=" +
						this.assignedPastCohort.id +
						"&page=" +
						this.competitorPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.competitorLoader = false;

						this.competitors = resp.data;
						this.competitorPagination.current_page =
							resp.current_page;
						this.competitorPagination.total = resp.total;
						this.competitorPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.competitorLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},

		deletePastCohort(item) {
			confirm("Are You Sure You Want to Delete PastCohort?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.pastCohortIndex = item.id;
				apiCall({ url: "/api/past-cohort/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "PastCohort Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.pastCohortIndex = null;
						this.fetchPastCohorts(this.pastCohortPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.pastCohortIndex = null;
						console.log(error.response);
					});
			}
		},
		deleteCompetitor(item) {
			confirm("Are You Sure You Want to Remove Competitor?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.competitorIndex = item.id;
				apiCall({
					url: "/api/competitor/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Competitor Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.competitorIndex = null;
						this.changeCompetitorPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.competitorIndex = null;
						console.log(error);
					});
			}
		},

		activatePastCohort(item) {
			confirm("Are You Sure You Want to Activate PastCohort?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.pastCohortIndex = item.id;
				this.newPastCohort.id = item.id;
				apiCall({
					url: "/api/past-cohort?type=activate",
					data: this.newPastCohort,
					method: "POST"
				})
					.then((resp) => {
						this.message = "PastCohort Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.pastCohortIndex = null;
						this.newPastCohort.id = null;
						this.fetchPastCohorts(this.pastCohortPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.pastCohortIndex = null;
						this.newPastCohort.id = null;
						console.log(error.response);
					});
			}
		},
		activateCompetitor(item) {
			confirm(
				"Are You Sure You Want to Activate Competitor?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.competitorIndex = item.id;
				this.newCompetitor.id = item.id;
				apiCall({
					url: "/api/competitor?type=activate",
					data: this.newCompetitor,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Competitor Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.competitorIndex = null;
						this.newCompetitor.id = null;
						this.changeCompetitorPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.competitorIndex = null;
						this.newCompetitor.id = null;
						console.log(error);
					});
			}
		},

		deactivatePastCohort(item) {
			confirm("Are You Sure You Want to Deactivate PastCohort?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.pastCohortIndex = item.id;
				this.newPastCohort.id = item.id;
				apiCall({
					url: "/api/past-cohort?type=deactivate",
					data: this.newPastCohort,
					method: "POST"
				})
					.then((resp) => {
						this.message = "PastCohort  Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.pastCohortIndex = null;
						this.newPastCohort.id = null;
						this.fetchPastCohorts(this.pastCohortPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.pastCohortIndex = null;
						this.newPastCohort.id = null;
						console.log(error);
					});
			}
		},
		deactivateCompetitor(item) {
			confirm(
				"Are You Sure You Want to Deactivate Competitor?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.competitorIndex = item.id;
				this.newCompetitor.id = item.id;
				apiCall({
					url: "/api/competitor?type=deactivate",
					data: this.newCompetitor,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Competitor Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.competitorIndex = null;
						this.newCompetitor.id = null;
						this.changeCompetitorPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.competitorIndex = null;
						this.newCompetitor.id = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/past-cohort?type=adminFormData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["pastCohorts", "pastCohortPagination", "pastCohortLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.pastCohortPagination.total / this.pastCohortPagination.per_page
			);
		},
		competitorLength: function () {
			return Math.ceil(
				this.competitorPagination.total /
				this.competitorPagination.per_page
			);
		},
		imageLength: function () {
			return Math.ceil(
				this.imagePagination.total /
				this.imagePagination.per_page
			);
		},
		formatCompetitorDate() {
			if (this.newCompetitor.date != null) {
				const d = new Date(this.newCompetitor.date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>