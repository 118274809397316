<template>
    <div class="welcome">
        <v-dialog 
            transition="dialog-bottom-transition"
            max-width="1500"
            v-model="dialog"
        >
            <v-card v-if="assignedNewsFeed!=null">
                <v-toolbar color="white" elevation="0">
                    <v-btn icon class="background ml-1" @click="previousNewsFeed()" v-if="newsFeedIndex!=0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="newsFeedIndex==0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" @click="nextNewsFeed()" v-if="(newsFeedIndex + 1) != publicNewsFeeds.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="(newsFeedIndex + 1) == publicNewsFeeds.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <div class="ml-2">{{ assignedNewsFeed.subject }}</div>
                    <v-spacer></v-spacer>
                    <v-btn icon class="background mr-1" @click="dialog = false">
                        <v-icon class="primary--text">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-layout row wrap class="my-5">
                        <v-flex xs12 md3>
                            <div>
                                <v-img max-height="280" contain :src="path + '/storage/news_feed_pics/' + assignedNewsFeed.image" class="mt-6">

                                </v-img>
                            </div>
                            <div align="center" class="secondary--text mt-5"><b class="primary--text">{{ assignedNewsFeed.author }}</b> • {{ assignedNewsFeed.publish_date }}</div>
                            <div align="center" class="my-5">
                                <a target="_blank" :href="assignedNewsFeed.source">
                                    <v-btn depressed class="primary white--text text-none">
                                        View Source
                                        <v-icon right>mdi-newspaper</v-icon>
                                    </v-btn>
                                </a>
                            </div>
                        </v-flex>
                        <v-flex xs12 md9>
                            <div class="mx-5 mt-5">
                                <v-layout column>
                                    
                                    <v-flex xs12>
                                        <div style="
                                            margin: 4px, 4px;
                                            padding: 4px;
                                            width: auto;
                                            height: 76vh;
                                            overflow-x: hidden;
                                            overflow-y: scroll;
                                            text-align: justify;
                                        ">
                                            <p v-html="assignedNewsFeed.body"></p>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12>
                                        <div class="my-10">
                                            <v-flex xs12>
                                                <v-layout row wrap>
                                                    <v-flex xs3>

                                                    </v-flex>
                                                    <v-flex xs4>
                                                        <v-divider class="primary mx-10"></v-divider>
                                                        <v-divider class="secondary"></v-divider>
                                                        <v-divider class="primary mx-10"></v-divider>
                                                    </v-flex>
                                                    <v-flex xs3>

                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/News Feed.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        News Feed
                    </div>

                </v-img>
            </v-flex>
            <v-progress-linear v-if="newsFeedLoader" height="2" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="white">
                <v-container class="my-16">
                    <div v-if="publicNewsFeeds.length!=0">
                        <v-layout column class="my-16">                        
                                <template v-for="(newsfeed, index) in publicNewsFeeds">
                                    <v-flex xs12 class="mt-2">
                                        <v-card class="d-flex flex-column" color="grey lighten-4"
                                            elevation="0">
                                            <v-card-text>
                                                <v-layout row wrap>
                                                    <v-flex xs12 md3>
                                                        <v-img contain :src="path + '/storage/news_feed_pics/' + newsfeed.image"/>
                                                    </v-flex>
                                                    <v-flex xs12 md9>
                                                        <div class="mx-10">
                                                            <div>
                                                                <a style="text-decoration: none" class="black--text title"
                                                                    @click="expandNewsFeed(newsfeed, index)">{{ newsfeed.subject }}</a>
                                                            </div>
                                                            <div class="font-weight-light mb-2 ">
                                                                <div class="secondary--text"><b class="primary--text">{{ newsfeed.author }}</b> • {{ newsfeed.publish_date }}</div>
                                                            </div>
                                                            <div class="my-10">
                                                                <v-flex xs12>
                                                                    <v-layout row wrap>
                                                                        <v-flex xs3>

                                                                        </v-flex>
                                                                        <v-flex xs4>
                                                                            <v-divider class="primary mx-10"></v-divider>
                                                                            <v-divider class="secondary"></v-divider>
                                                                            <v-divider class="primary mx-10"></v-divider>
                                                                        </v-flex>
                                                                        <v-flex xs3>

                                                                        </v-flex>
                                                                    </v-layout>
                                                                </v-flex>
                                                            </div>
                                                            <div class="font-weight-light mb-2">                                                        
                                                                <v-clamp autoresize :max-lines="4">{{ removeTags(newsfeed.body) }}
                                                                </v-clamp>
                                                            </div>
                                                            <div align="center">
                                                                <v-btn depressed class="primary white--text text-none my-5" @click="expandNewsFeed(newsfeed, index)">
                                                                    Read More
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </v-flex>
                                                </v-layout>
                                            </v-card-text>
                                        </v-card>                                 
                                    </v-flex>
                                </template>
                            
                            <v-flex xs12 class="mt-10">
                                <v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="publicNewsFeedPagination.current_page" @input="changePage()" circle>
								</v-pagination>
                            </v-flex>
                            <v-flex xs12>
                                <div v-if="length != 0" align="center" class="mt-5">
                                    <b class="primary--text">Total: </b>{{ publicNewsFeedPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-else>
                        <v-layout column>
                            <v-flex xs12 class="mt-13">
                                <v-layout row wrap>
                                    <v-flex xs12 md3>
                                        <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image"
                                        ></v-skeleton-loader>
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <v-skeleton-loader
                                            v-bind="attrs"
                                            type="article, actions"
                                            ></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md3>
                                        <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image"
                                        ></v-skeleton-loader>
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <v-skeleton-loader
                                            v-bind="attrs"
                                            type="article, actions"
                                            ></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md3>
                                        <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image"
                                        ></v-skeleton-loader>
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <v-skeleton-loader
                                            v-bind="attrs"
                                            type="article, actions"
                                            ></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md3>
                                        <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image"
                                        ></v-skeleton-loader>
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <v-skeleton-loader
                                            v-bind="attrs"
                                            type="article, actions"
                                            ></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12 class="mt-5">
                                <v-layout row wrap>
                                    <v-flex xs12 md3>
                                        <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image"
                                        ></v-skeleton-loader>
                                    </v-flex>
                                    <v-flex xs12 md9>
                                        <v-skeleton-loader
                                            v-bind="attrs"
                                            type="article, actions"
                                            ></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import VClamp from "vue-clamp";

export default {
    components: {
        VClamp
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            dialog: false,

            newsFeedIndex: 0,
            assignedNewsFeed: null
        };
    },
    mounted() {
        window.onscroll = () => {

        };
    },
    created() {
        window.scrollTo(0, 0);
        this.startNewsFeedLoader()
        this.fetchPublicNewsFeeds(this.publicNewsFeedPagination.current_page)
    },
    methods: {
        ...mapActions([
            'fetchPublicNewsFeeds',
            'startNewsFeedLoader',
            'stopNewsFeedLoader'
        ]),
        previousNewsFeed(){
            this.newsFeedIndex -= 1
            this.assignedNewsFeed = this.publicNewsFeeds[this.newsFeedIndex]
        },
        nextNewsFeed(){
            this.newsFeedIndex += 1
            this.assignedNewsFeed = this.publicNewsFeeds[this.newsFeedIndex]
        },

        expandNewsFeed(newsFeed, index) {
            this.dialog = true
            this.assignedNewsFeed = newsFeed
            this.newsFeedIndex = index
        },
        changePage(){
            window.scrollTo(0, 0);
            this.startNewsFeedLoader();
			this.fetchPublicNewsFeeds(this.publicNewsFeedPagination.current_page);
        },
        removeTags(str) {
            if ((str===null) || (str===''))
                return false;
            else
                str = str.toString();
            return str.replace( /(<([^>]+)>)/ig, '');
        }
    },
    computed: {
        ...mapGetters(["darkState", "publicNewsFeeds", "publicNewsFeedPagination", "newsFeedLoader"]),
        length: function () {
			return Math.ceil(
				this.publicNewsFeedPagination.total / this.publicNewsFeedPagination.per_page
			);
		},
    },
};
</script>
