import apiCall from '../../utils/api'

const state = {
	contactCardShow: false,
    faqCardShow: false,
};

const getters = {
	contactCardState: (state) => state.contactCardShow,
    faqCardState: (state) => state.faqCardShow,
};

const actions = {
	async changeContactCardState({commit}) {
		commit('updateContactCard')
	},
    async changeFAQCardState({commit}) {
		commit('updateFAQCard')
	},
};

const mutations = {
	updateContactCard: (state) => (state.contactCardShow = !state.contactCardShow),
    updateFAQCard: (state) => (state.faqCardShow = !state.faqCardShow)
};

export default {
	state,
	getters,
	actions,
	mutations
}