<template>
    <div class="faqs">
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/FAQs.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        FAQs
                    </div>
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <v-layout column>
                        <v-flex xs12 class="mt-16">
                            <div align="center" class="display-3 font-weight-black primary--text mt-16">Got Queries?</div>
                            <div align="center" class="display-1 secondary--text mt-10">Here are Answers to the Frequently
                                Asked Questions</div>
                        </v-flex>
                        <v-flex xs12 class="mt-15">
                            <v-layout row wrap>
                                <v-flex xs5>

                                </v-flex>
                                <v-flex xs2>
                                    <v-divider class="primary mx-10"></v-divider>
                                    <v-divider class="secondary"></v-divider>
                                    <v-divider class="primary mx-10"></v-divider>
                                </v-flex>
                                <v-flex xs5>

                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="my-16">
                            <v-layout row wrap>
                                <v-flex xs12 md6>
                                    <div class="pa-5">
                                        <template>
                                            <v-expansion-panels flat>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Who is funding the Women Entrepreneurship Incubator Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The Women Entrepreneurship Incubator Program (WEIP) is funded by USAID
                                                        </div>
                                                        <!-- <p>
                                                            <b>About Standard Chartered</b>
                                                        </p>
                                                        <div class="mt-9">
                                                            
                                                        </div> -->
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">                                       
                                                        <div class="font-weight-medium">Who is implementing the Women Entrepreneurship Incubator Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            WEIP is implemented by Strathmore University, specifically through its @iBizAfrica Incubation Centre and Strathmore Enterprise Development and Innovation Centre (SEDIC).
                                                        </div>
                                                        <p>
                                                            <b>About @iBizAfrica</b>
                                                        </p>
                                                        <div class="mt-9">
                                                            @iBizAfrica is the business incubation centre at Strathmore
                                                            University which seeks to nurture the development, growth and
                                                            success of startups. We attain this through building the
                                                            potential of the youth to develop ICT solutions and businesses
                                                            that work for the common good in society.
                                                        </div>
                                                        <div class="mt-9">
                                                            @iBizAfrica implements the entrepreneurship theme of
                                                            @iLabAfrica, the research and innovation centre at Strathmore
                                                            University.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Where can I access the Application Form?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The application form can be accessed <a href="/#/cohort-3-4-5" style="text-decoration: none;">Here</a>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Who are we looking for?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            WEIP is seeking <span class="font-weight-black primary--text">women-owned Small and Growing Businesses (WO-SGBs)</span> operating in key sectors such as <span class="font-weight-black primary--text">agribusiness and agro-processing, textiles & apparel, healthcare, and water & sanitation</span>.
                                                        </div>
                                                        <!-- <div class="font-weight-thin">
                                                            The principle founder must be:
                                                        </div>
                                                        <div>
                                                            <ol>
                                                                <li>A woman.</li>
                                                                <li>Above 18 years of age.</li>
                                                                <li>Of Kenyan nationality or permanent residency.</li>
                                                            </ol>
                                                        </div> -->
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Which counties will the WEIP program be running in?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The program will be run in Kiambu, Nakuru, & Nairobi counties. Participants will be required to participate in person.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">Must the enterprises be located in the above mentioned counties?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            No, the enterprise could be located outside the specified counties but the founder must be available to participate in the program in-person, when hosted in the respective counties.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What are the eligibility criteria?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The eligibility criteria can be accessed <a href="/#/cohort-3-4-5" style="text-decoration: none;">Here</a>
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When is the application deadline?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <!-- The application deadline is 16th May 2024 -->
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What are the benefits of applying to the WEIP program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            Participants will receive <span class="font-weight-black primary--text">tailored business development support</span>, access to <span class="font-weight-black primary--text">mentorship, capacity-building</span> opportunities, and the chance to secure <span class="font-weight-black primary--text">co-investment grants</span> to scale their businesses.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </template>
                                    </div>
                                </v-flex>
                                <v-flex xs12 md6>
                                    <div class="pa-5">
                                        <template>
                                            <v-expansion-panels flat>
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What is the Women Entrepreneurship Incubator Program?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            WEIP is a transformative initiative aimed at supporting women-owned small and growing businesses across Kenya by providing resources, mentorship, and financial support to help entrepreneurs scale and succeed.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">How long is the incubation period?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <!-- The incubation period is 12 weeks. -->
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                                
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When does the program begin?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <!-- The program is starting on 3rd June 2024. -->
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">When does the programme end?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="primary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            <!-- The program is ending on 26th September 2024 -->
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What specialized skill will I learn during the programme?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            Participants will gain skills in business management, financial literacy, strategic planning, and sector-specific knowledge tailored to agribusiness and agro-processing, textiles, healthcare, and water & sanitation.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                <v-expansion-panel>
                                                    <v-expansion-panel-header color="#EAEAEA">
                                                        <div class="font-weight-medium">What is the WEIP Alumni Network?</div>
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content color="#FCFCFC">
                                                        <v-divider class="secondary"></v-divider>
                                                        <div class="my-10 font-weight-thin">
                                                            The WEIP Alumni Network connects participants, both current and alumnae, with a broader community of women entrepreneurs, investors, and industry leaders, providing ongoing networking and partnership opportunities after the program concludes.
                                                        </div>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>

                                                
                                            </v-expansion-panels>
                                        </template>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="mb-16">
                            <div align="center">
                                <a :href="
                                    'mailto: adminibiz@strathmore.edu'
                                ">
                                <v-btn large depressed class="primary text-none">
                                    <v-icon left>mdi-gmail</v-icon>adminibiz@strathmore.edu
                                </v-btn>
                                </a>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>

        </v-layout>
    </div>
</template>
<style>
/* <-- remove scoped here if you have it*/
.v-carousel__controls {
    background: #fff !important;
    border-radius: 25px;
}

.v-carousel__controls__item {
    color: #007932 !important;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            drawer: false,

            absolute: true,
            overlay: true,
            opacity: 0.3,
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.changeHeaderColor("white");
        this.changeTitleColor("red--text");
        this.changeButtonColor("red--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "changeHeaderColor",
            "changeTitleColor",
            "changeButtonColor"
        ]),
        changeColor() {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.changeHeaderColor("white");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            } else {
                this.changeHeaderColor("white");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            }
        },
    },
    computed: {
        ...mapGetters(["darkState"]),
    },
};
</script>
