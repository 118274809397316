<template>
    <div class="welcome">
      <v-layout column>
            <v-flex xs12>
                <v-img
                    dark
                    height="250"
                    src="img/Cohort 1 & 2.jpg"
                    >
                    <div align="center" class="font-weight-black display-1 mt-16 hidden-sm-and-down">
                        Women Entrepreneurship Incubator Program
                    </div>
                    <div align="center" class="font-weight-black title mt-3 hidden-md-and-up">
                        Women Entrepreneurship Incubator Program
                    </div>
                    <div align="center" class="mt-2">
                        WEIP
                    </div>
                    <div align="center" class="mt-2">
                        <v-btn depressed class="secondary mt-5" v-if="!isAuthenticated" @click="changeLoginCardState">
                            Apply Now! <v-icon right>mdi-file-document-plus</v-icon>
                        </v-btn>
                        <v-btn depressed class="secondary mt-5" router to="/cohort-3-4-5/apply-now" v-if="isAuthenticated">
                            Apply Now! <v-icon right>mdi-file-document-plus</v-icon>
                        </v-btn>
                    </div>
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <div align="left" class="mt-15">
                        <div class="headline font-weight-black">
                            What is the Women Entrepreneurship Incubator Program?
                        </div>
                        <p class="grey--text text--darken-1">
                            The USAID Women Entrepreneurship Incubator Program (WEIP) is a transformative initiative aimed at supporting Women-Owned Small and Growing Businesses (WO-SGBs) across Kenya by providing resources, mentorship, and financial support to help entrepreneurs scale and succeed.
                        </p>
                        <div class="headline font-weight-black">
                            Who are we looking for?
                        </div>
                        <p class="grey--text text--darken-1">
                            We are seeking to recruit the best entrepreneurial minds, focusing on Women-Owned Small and Growing Businesses (WO-SGBs) as key drivers of innovation and economic growth operating in Kiambu, Nakuru & Nairobi.
                        </p>
                        <!-- <p class="grey--text text--darken-1">
                            The principle founder must be:
                            <ol class="grey--text text--darken-1">
                                <li>A woman.</li>
                                <li>Above 18 years of age.</li>
                                <li>Of <b>Kenyan</b> nationality or permanent residency.</li>
                            </ol>
                        </p> -->
                        
                        <div class="headline font-weight-black mt-5">
                            Eligibility criteria
                        </div>
                        <div class="title font-weight-black primary--text mt-5">
                            Management and Ownership Criteria
                        </div>
                        <ol class="grey--text text--darken-1 mt-2">
                            <li>Women must own <b>51% and above</b> of the share capital.</li>
                            <li>The business must be women-led, controlled, and managed.</li>
                            <li>The business must be in one of these key sectors: <b>agribusiness and agro-processing, textiles & apparel, healthcare, and water & sanitation</b>.</li>
                        </ol>
                        <div class="title font-weight-black primary--text mt-5">
                            Micro enterprise
                        </div>
                        <ol class="grey--text text--darken-1">
                            <li>Must have formal registration (at least a trading license).</li>
                            <li>Operated for <b>1-2 years.</b></li>
                            <li>Employ fewer than 10 people (with at least 1 permanent employee).</li>
                            <li>Annual turnover: Below <b>Ksh 1 million.</b></li>
                            <li>Must have a valid <b>Tax Compliance Certificate</b> and a physical address or premise.</li>                                                                          
                        </ol>
                        <div class="mt-4" style="font-style: italic">
                            <b>For manufacturing enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery must not exceed <b>Ksh 10 million</b>.</li>
                            </ul>
                            <br>
                            <b>For service and farming enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery must not exceed <b>Ksh 5 million</b>.</li>
                            </ul>
                        </div>
                        <div class="title font-weight-black primary--text mt-5">
                            Small enterprise
                        </div>
                        <ol class="grey--text text--darken-1">
                            <li>Must have formal registration and all required business certificates/permits.</li>
                            <li>Operated for <b>3 years or more</b>.</li>
                            <li>Employ <b>10-49 people</b>, with the ability to retain at least 30% of employees.</li>
                            <li>Annual turnover: Between <b>Ksh 1 million and Ksh 5 million</b>.</li>
                            <li>Must have a valid <b>Tax Compliance Certificate</b> and a permanent business premise</li>
                        </ol>
                        <div class="mt-4" style="font-style: italic">
                            <b>For manufacturing enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery should be between <b>Ksh 10 million and Ksh 50 million</b>.</li>
                            </ul>
                            <br>
                            <b>For service and farming enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery should be between <b>Ksh 5 million and Ksh 20 million</b>.</li>
                            </ul>
                        </div>
                        <div class="title font-weight-black primary--text mt-5">
                            Medium enterprise
                        </div>
                        <ol class="grey--text text--darken-1">
                            <li>Must be formally registered with an incorporation certificate.</li>
                            <li>Provide <b>3 years</b> of audited financial accounts.</li>
                            <li>Operational for at least <b>3-5 years</b>, with clear operational structures and systems in place (e.g., HR management, payroll, finance).</li>
                            <li>Employ <b>50-99 people</b>.</li>
                            <li>Annual turnover: Above <b>Ksh 5 million.</b></li>
                            <li>Investment in plant and machinery for manufacturing should exceed <b>Ksh 50 million</b>; for service and farming enterprises, it should exceed <b>Ksh 20 million</b>.</li>
                            <li>Must have a valid <b>Tax Compliance Certificate</b> and a permanent business premises.</li>                       
                        </ol>
                        <div class="mt-4" style="font-style: italic">
                            <b>For manufacturing enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery should exceed <b>Ksh 50 million</b>.</li>
                            </ul>
                            <br>
                            <b>For service and farming enterprises:</b>
                            <ul>
                                <li>Investment in plant and machinery should exceed <b>Ksh 20 million</b>.</li>
                            </ul>
                        </div>

                        <div class="headline font-weight-black mt-5">
                            Program benefits
                        </div>
                        <ol class="grey--text text--darken-1">
                            <li><b>Access to Capacity Building:</b> Participants will receive comprehensive training tailored to their business needs, focusing on areas such as financial management, business development, and strategic planning.</li>
                            <li><b>Business Advisory:</b> Entrepreneurs will receive expert guidance on how to grow and sustain their businesses, addressing challenges specific to their sector.</li>
                            <li><b>Mentorship:</b> Women entrepreneurs will be paired with experienced mentors who provide personalized coaching to help them navigate the complexities of scaling their businesses.</li>
                            <li><b>Access to Co-Investment Grants:</b> Participants will have the opportunity to secure co-investment grants to scale their businesses, with a total of $1.25 million in co-investments available across the program</li>
                        </ol>
                        <div align="center" class="mt-2">
                            <v-btn depressed class="secondary mt-5" v-if="!isAuthenticated" @click="changeLoginCardState">
                                Apply Now! <v-icon right>mdi-file-document-plus</v-icon>
                            </v-btn>
                            <v-btn depressed class="secondary mt-5" router to="/cohort-3-4-5/apply-now" v-if="isAuthenticated">
                                Apply Now! <v-icon right>mdi-file-document-plus</v-icon>
                            </v-btn>
                        </div>
                        <div class="my-10 mx-5">
                            <v-divider></v-divider>
                        </div>
                        <div class="headline font-weight-black mt-5">
                            Terms and conditions
                        </div>
                        <p class="grey--text text--darken-1 mb-15">
                            <ul>
                                <li>Successful completion of the <span style="font-weight: bolder;">Women Entrepreneurship Incubator Program (WEIP)</span> is compulsory for all participants.</li>
                                <li>Attendance and active participation in all scheduled workshops, mentoring sessions, and program activities are required to maintain eligibility for funding and program benefits.</li>
                                <li>Participants must commit to all <span style="font-weight: bolder;">in-person or virtual meetings</span> as deemed necessary by the program organizers.</li>
                                <li>Compliance with all applicable <span style="font-weight: bolder;">business regulations and legal requirements</span> is expected throughout the program duration.</li>
                            </ul>     
                        </p>
                    </div>
                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,
    };
  },
  mounted() {
    
  },
  created() {
    window.scrollTo(0, 0);
   
  },
  methods: {
    ...mapActions([
        "changeLoginCardState"
    ]),
      
  },
  computed: {
    ...mapGetters([
        "darkState",
        "isAuthenticated"
    ]),
  },
};
</script>
