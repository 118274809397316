import apiCall from '@/utils/api'

const state = {
	noApplications: [],
    noApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    noApplicationLoader: false,

	personalLevelApplications: [],
    personalLevelApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    personalLevelApplicationLoader: false,

	ventureLevelApplications: [],
    ventureLevelApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    ventureLevelApplicationLoader: false,

	problemLevelApplications: [],
    problemLevelApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    problemLevelApplicationLoader: false,

	teamLevelApplications: [],
    teamLevelApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    teamLevelApplicationLoader: false,

    termsLevelApplications: [],
    termsLevelApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    termsLevelApplicationLoader: false,
};

const getters = {
	noApplications: (state) => state.noApplications,
    noApplicationLoader: (state) => state.noApplicationLoader,
    noApplicationPagination: (state) => state.noApplicationPagination,

	personalLevelApplications: (state) => state.personalLevelApplications,
    personalLevelApplicationLoader: (state) => state.personalLevelApplicationLoader,
    personalLevelApplicationPagination: (state) => state.personalLevelApplicationPagination,

	ventureLevelApplications: (state) => state.ventureLevelApplications,
    ventureLevelApplicationLoader: (state) => state.ventureLevelApplicationLoader,
    ventureLevelApplicationPagination: (state) => state.ventureLevelApplicationPagination,

	problemLevelApplications: (state) => state.problemLevelApplications,
    problemLevelApplicationLoader: (state) => state.problemLevelApplicationLoader,
    problemLevelApplicationPagination: (state) => state.problemLevelApplicationPagination,

	teamLevelApplications: (state) => state.teamLevelApplications,
    teamLevelApplicationLoader: (state) => state.teamLevelApplicationLoader,
    teamLevelApplicationPagination: (state) => state.teamLevelApplicationPagination,

    termsLevelApplications: (state) => state.termsLevelApplications,
    termsLevelApplicationLoader: (state) => state.termsLevelApplicationLoader,
    termsLevelApplicationPagination: (state) => state.termsLevelApplicationPagination,
}

const actions = {
	async fetchNoApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=noApplication&page=${page}`, method: 'GET' });
		commit('setNoApplications', response)
        commit('stopNoApplicationLoader', response)
	},
    async filterNoApplications({commit, state},resp){
		commit('setNoApplications', resp)
	},
	async startNoApplicationLoader({commit, state},resp){
		commit('startNoApplicationLoader', resp)
	},
	async stopNoApplicationLoader({commit, state},resp){
		commit('stopNoApplicationLoader', resp)
	},

	async fetchPersonalLevelApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=personalLevel&page=${page}`, method: 'GET' });
		commit('setPersonalLevelApplications', response)
        commit('stopPersonalLevelApplicationLoader', response)
	},
    async filterPersonalLevelApplications({commit, state},resp){
		commit('setPersonalLevelApplications', resp)
	},
	async startPersonalLevelApplicationLoader({commit, state},resp){
		commit('startPersonalLevelApplicationLoader', resp)
	},
	async stopPersonalLevelApplicationLoader({commit, state},resp){
		commit('stopPersonalLevelApplicationLoader', resp)
	},

	async fetchVentureLevelApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=ventureLevel&page=${page}`, method: 'GET' });
		commit('setVentureLevelApplications', response)
        commit('stopVentureLevelApplicationLoader', response)
	},
    async filterVentureLevelApplications({commit, state},resp){
		commit('setVentureLevelApplications', resp)
	},
	async startVentureLevelApplicationLoader({commit, state},resp){
		commit('startVentureLevelApplicationLoader', resp)
	},
	async stopVentureLevelApplicationLoader({commit, state},resp){
		commit('stopVentureLevelApplicationLoader', resp)
	},

	async fetchProblemLevelApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=problemLevel&page=${page}`, method: 'GET' });
		commit('setProblemLevelApplications', response)
        commit('stopProblemLevelApplicationLoader', response)
	},
    async filterProblemLevelApplications({commit, state},resp){
		commit('setProblemLevelApplications', resp)
	},
	async startProblemLevelApplicationLoader({commit, state},resp){
		commit('startProblemLevelApplicationLoader', resp)
	},
	async stopProblemLevelApplicationLoader({commit, state},resp){
		commit('stopProblemLevelApplicationLoader', resp)
	},

	async fetchTeamLevelApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=teamLevel&page=${page}`, method: 'GET' });
		commit('setTeamLevelApplications', response)
        commit('stopTeamLevelApplicationLoader', response)
	},
    async filterTeamLevelApplications({commit, state},resp){
		commit('setTeamLevelApplications', resp)
	},
	async startTeamLevelApplicationLoader({commit, state},resp){
		commit('startTeamLevelApplicationLoader', resp)
	},
	async stopTeamLevelApplicationLoader({commit, state},resp){
		commit('stopTeamLevelApplicationLoader', resp)
	},

    async fetchTermsLevelApplications({commit}, page) {
		const response = await apiCall({url: `/api/track-application?type=teamLevel&page=${page}`, method: 'GET' });
		commit('setTermsLevelApplications', response)
        commit('stopTermsLevelApplicationLoader', response)
	},
    async filterTermsLevelApplications({commit, state},resp){
		commit('setTermsLevelApplications', resp)
	},
	async startTermsLevelApplicationLoader({commit, state},resp){
		commit('startTermsLevelApplicationLoader', resp)
	},
	async stopTermsLevelApplicationLoader({commit, state},resp){
		commit('stopTermsLevelApplicationLoader', resp)
	},
};

const mutations = {
	setNoApplications: (state, noApplications) => {
		state.noApplications = noApplications.data
		state.noApplicationPagination.current_page = noApplications.current_page
		state.noApplicationPagination.total = noApplications.total
		state.noApplicationPagination.per_page = noApplications.per_page
	},
    startNoApplicationLoader: (state) => state.noApplicationLoader = true,
	stopNoApplicationLoader: (state) => state.noApplicationLoader = false,

	setPersonalLevelApplications: (state, personalLevelApplications) => {
		state.personalLevelApplications = personalLevelApplications.data
		state.personalLevelApplicationPagination.current_page = personalLevelApplications.current_page
		state.personalLevelApplicationPagination.total = personalLevelApplications.total
		state.personalLevelApplicationPagination.per_page = personalLevelApplications.per_page
	},
    startPersonalLevelApplicationLoader: (state) => state.noApplicationLoader = true,
	stopPersonalLevelApplicationLoader: (state) => state.noApplicationLoader = false,

	setVentureLevelApplications: (state, ventureLevelApplications) => {
		state.ventureLevelApplications = ventureLevelApplications.data
		state.ventureLevelApplicationPagination.current_page = ventureLevelApplications.current_page
		state.ventureLevelApplicationPagination.total = ventureLevelApplications.total
		state.ventureLevelApplicationPagination.per_page = ventureLevelApplications.per_page
	},
    startVentureLevelApplicationLoader: (state) => state.noApplicationLoader = true,
	stopVentureLevelApplicationLoader: (state) => state.noApplicationLoader = false,

	setProblemLevelApplications: (state, problemLevelApplications) => {
		state.problemLevelApplications = problemLevelApplications.data
		state.problemLevelApplicationPagination.current_page = problemLevelApplications.current_page
		state.problemLevelApplicationPagination.total = problemLevelApplications.total
		state.problemLevelApplicationPagination.per_page = problemLevelApplications.per_page
	},
    startProblemLevelApplicationLoader: (state) => state.noApplicationLoader = true,
	stopProblemLevelApplicationLoader: (state) => state.noApplicationLoader = false,

	setTeamLevelApplications: (state, teamLevelApplications) => {
		state.teamLevelApplications = teamLevelApplications.data
		state.teamLevelApplicationPagination.current_page = teamLevelApplications.current_page
		state.teamLevelApplicationPagination.total = teamLevelApplications.total
		state.teamLevelApplicationPagination.per_page = teamLevelApplications.per_page
	},
    startTeamLevelApplicationLoader: (state) => state.teamLevelApplicationLoader = true,
	stopTeamLevelApplicationLoader: (state) => state.teamLevelApplicationLoader = false,

    setTermsLevelApplications: (state, termsLevelApplications) => {
		state.termsLevelApplications = termsLevelApplications.data
		state.termsLevelApplicationPagination.current_page = termsLevelApplications.current_page
		state.termsLevelApplicationPagination.total = termsLevelApplications.total
		state.termsLevelApplicationPagination.per_page = termsLevelApplications.per_page
	},
    startTermsLevelApplicationLoader: (state) => state.teamLevelApplicationLoader = true,
	stopTermsLevelApplicationLoader: (state) => state.teamLevelApplicationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
