<template>
    <div class="welcome">
      <v-layout column>
            <v-flex xs12>
                <v-img
                    dark
                    height="250"
                    src="img/About.jpg"
                    >
                    <div align="center" class="font-weight-black display-1 mt-16">
                        About
                    </div>
                    <!-- <div align="center" class="mt-2">
                        Embracing equity in Women-Owned startups
                    </div> -->
                    
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <div align="center" class="mt-15">
                        <p class="grey--text text--darken-1">
                            The USAID Women Entrepreneurship Incubator Program (WEIP) is Kenya’s leading initiative for empowering Women-Owned Small and Growing Businesses (WO-SGBs). The program aligns with calls for increased diversity in entrepreneurship, offering women the opportunity to develop leadership and business excellence. WEIP is an initiative funded by USAID in partnership with Strathmore University, providing a comprehensive program to support female entrepreneurs in scaling their businesses.
                        </p>
                        <p class="grey--text text--darken-1">
                            Combining world-class business development support with local and international expertise, WEIP delivers a holistic incubation program. The focus is on immersive learning, personalized mentorship, access to capital, and strategic partnerships to help women-led businesses succeed in key sectors such as agribusiness and agro-processing, textiles & apparel, healthcare, and water & sanitation.
                        </p>
                    </div>
                </v-container>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <v-layout column>
                        <v-flex xs12 class="mt-7">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-15">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-earth
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Incubation Component
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            The methodology for the incubator program will address the following core components of business development.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/incubation.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="secondary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <v-img src="img/learning.jpg" style="border-radius: 20px 4px 4px 4px;" class="mx-3"> </v-img>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-13">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-school
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Immersive Learning
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Entrepreneurs receive in-depth evaluations of their business operations, allowing the curriculum to be tailored to meet the unique needs of each venture.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-left-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-14">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-account-child-circle
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Mentorship & Coaching
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Experienced mentors provide ongoing guidance, facilitating customized coaching sessions that address specific challenges faced by each business.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/mentor.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="secondary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <v-img src="img/finance.jpg" style="border-radius: 20px 4px 4px 4px;" class="mx-3"> </v-img>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-11">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-cash-100
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Access to Investment 
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Participants benefit from over <span style="font-weight: bolder;">$1.25 million in co-investment grants</span>, with <span style="font-weight: bolder;">45 women-led businesses</span> receiving sub-grants. This financial support empowers women to scale their ventures and expand their impact.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-left-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8 mb-16">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-14">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-google-circles-communities
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Networking
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Entrepreneurs gain access to a wide network of investors, industry leaders, and partners within and outside their sectors, creating sustainable partnerships for business growth.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/networking.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
                
            </v-flex>
            <v-flex xs12 class="background">
                <v-container class="my-16">
                    <v-layout row wrap class="mx-1 my-2">
                    <v-flex xs12 md12 color="#525355" class="accent pa-8">
                            <div class="title white--text mt-7 font-weight-black">
                                Eligibility Criteria
                            </div>
                            <p class="white--text mt-3">
                                We are seeking to recruit the best entrepreneurial minds, focusing on Women-Owned Small and Growing Businesses (WO-SGBs) as key drivers of innovation and economic growth operating in Kiambu, Nakuru & Nairobi.
                            </p>
                            <p class="white--text mt-3">
                                The Eligibility criteria is as follows:
                            </p>
                            <p class="white--text mt-3 mb-7">
                                <span class="h1 font-weight-black pt-5">
                                    Management and Ownership Criteria:
                                </span>
                                <ul class="text--darken-1 mt-2">
                                    <li>Women must own <b>51% and above</b> of the share capital.</li>
                                    <li>The business must be women-led, controlled, and managed.</li>
                                    <li>The business must be in one of these key sectors: <b>agribusiness and agro-processing, textiles & apparel, healthcare, and water & sanitation</b>.</li>
                                </ul>
                                <br>
                            </p>

                            <div class="mt-10 mb-16">
                                <v-layout row wrap class="mb-16">
                                <v-flex xs12 md4>
                                    <v-card elevation="0" class="primary mx-2 my-1" shaped height="580">
                                    <v-card-text>
                                        <v-layout column class="my-2">
                                            <v-flex xs12>
                                                <div align="center" class="white--text mt-5 title"
                                                style="font-family: 'SC Prosper Sans Regular';">
                                                Micro enterprise
                                                </div>
                                            </v-flex>
                                        <v-flex xs12>
                                            <div align="left" class="white--text mt-5">
                                                <ul>
                                                    <li>Must have formal registration (at least a trading license).</li>
                                                    <li>Operated for <b>1-2 years.</b></li>
                                                    <li>Employ fewer than 10 people (with at least 1 permanent employee).</li>
                                                    <li>Annual turnover: Below <b>Ksh 1 million.</b></li>
                                                    <li>Must have a valid <b>Tax Compliance Certificate</b> and a physical address or premise</li>                         
                                                </ul>
                                                <br>
                                                <div style="font-style: italic; padding-top: 105px;">
                                                    <b>For manufacturing enterprises:</b>
                                                    <ul>
                                                        <li>Investment in plant and machinery must not exceed <b>Ksh 10 million</b>.</li>
                                                    </ul>
                                                    <br>
                                                    <b>For service and farming enterprises:</b>
                                                    <ul>
                                                        <li>Investment in plant and machinery must not exceed <b>Ksh 5 million</b>.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 md4>
                                    <v-card elevation="0" class="secondary mx-2 my-1" shaped height="580">
                                    <v-card-text>
                                        <v-layout column class="my-2">
                                            <v-flex xs12>
                                                <div align="center" class="white--text mt-5 title"
                                                style="font-family: 'SC Prosper Sans Regular';">
                                                Small enterprise
                                                </div>
                                            </v-flex>
                                            <v-flex xs12>
                                                <div align="left" class="white--text mt-5">                                                    
                                                    <ul>
                                                        <li>Must have formal registration and all required business certificates/permits.</li>
                                                        <li>Operated for <b>3 years or more</b>.</li>
                                                        <li>Employ <b>10-49 people</b>, with the ability to retain at least 30% of employees.</li>
                                                        <li>Annual turnover: Between <b>Ksh 1 million and Ksh 5 million</b>.</li>
                                                        <li>Must have a valid <b>Tax Compliance Certificate</b> and a permanent business premise</li>
                                                    </ul>
                                                    <br>
                                                    <div style="font-style: italic; padding-top: 60px;">
                                                        <b>For manufacturing enterprises:</b>
                                                        <ul>
                                                            <li>Investment in plant and machinery should be between <b>Ksh 10 million and Ksh 50 million</b>.</li>
                                                        </ul>
                                                        <br>
                                                        <b>For service and farming enterprises:</b>
                                                        <ul>
                                                            <li>Investment in plant and machinery should be between <b>Ksh 5 million and Ksh 20 million</b>.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                    </v-card>
                                </v-flex>
                                <v-flex xs12 md4>
                                    <v-card elevation="0" class="goldie mx-2 my-1" shaped height="580">
                                    <v-card-text>
                                        <v-layout column class="my-2">
                                        <v-flex xs12>
                                            <div align="center" class="white--text mt-5 title"
                                            style="font-family: 'SC Prosper Sans Regular';">
                                            Medium enterprise
                                            </div>
                                        </v-flex>
                                        <v-flex xs12>
                                            <div align="left" class="white--text mt-5">
                                                <ul>
                                                    <li>Must be formally registered with an incorporation certificate.</li>
                                                    <li>Provide <b>3 years</b> of audited financial accounts.</li>
                                                    <li>Operational for at least <b>3-5 years</b>, with clear operational structures and systems in place (e.g., HR management, payroll, finance).</li>
                                                    <li>Employ <b>50-99 people</b>.</li>
                                                    <li>Annual turnover: Above <b>Ksh 5 million.</b></li>
                                                    <li>Must have a valid <b>Tax Compliance Certificate</b> and a permanent business premises.</li>
                                                </ul>
                                                <br>
                                                <div class="mt-4" style="font-style: italic">
                                                    <b>For manufacturing enterprises:</b>
                                                    <ul>
                                                        <li>Investment in plant and machinery should exceed <b>Ksh 50 million</b>.</li>
                                                    </ul>
                                                    <br>
                                                    <b>For service and farming enterprises:</b>
                                                    <ul>
                                                        <li>Investment in plant and machinery should exceed <b>Ksh 20 million</b>.</li>
                                                    </ul>
                                                </div>                                             
                                            </div>
                                        </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                    </v-card>
                                </v-flex>
                                </v-layout>
                            </div>
                        
                    </v-flex>
                    <v-flex xs12 md12 class="white pa-16">
                            <div class="title black--text mt-7 font-weight-black">
                                Program Benefits
                            </div>
                            <p class="black--text mt-3">
                                <ul>
                                    <li><b>Access to Capacity Building:</b> Participants will receive comprehensive training tailored to their business needs, focusing on areas such as financial management, business development, and strategic planning.</li>
                                    <li><b>Business Advisory:</b> Entrepreneurs will receive expert guidance on how to grow and sustain their businesses, addressing challenges specific to their sector.</li>
                                    <li><b>Mentorship:</b> Women entrepreneurs will be paired with experienced mentors who provide personalized coaching to help them navigate the complexities of scaling their businesses.</li>
                                    <li><b>Access to Co-Investment Grants:</b> Participants will have the opportunity to secure co-investment grants to scale their businesses, with a total of $1.25 million in co-investments available across the program​</li>
                                </ul>
                            </p>
                            <div class="title black--text mt-7 font-weight-black">
                                Terms & Conditions
                            </div>
                            <p>
                                <ul>
                                    <li>Successful completion of the <span style="font-weight: bolder;">Women Entrepreneurship Incubator Program (WEIP)</span> is compulsory for all participants.</li>
                                    <li>Attendance and active participation in all scheduled workshops, mentoring sessions, and program activities are required to maintain eligibility for funding and program benefits.</li>
                                    <li>Participants must commit to all <span style="font-weight: bolder;">in-person or virtual meetings</span> as deemed necessary by the program organizers.</li>
                                    <li>Compliance with all applicable <span style="font-weight: bolder;">business regulations and legal requirements</span> is expected throughout the program duration.</li>
                                </ul>                                
                            </p>
                        
                    </v-flex>
                </v-layout>
                </v-container>

            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.changeHeaderColor("white");
    this.changeTitleColor("red--text");
    this.changeButtonColor("red--text hidden-sm-and-down text-none caption");
  },
  methods: {
    ...mapActions([
        "changeHeaderColor",
        "changeTitleColor",
        "changeButtonColor"
    ]),
      changeColor() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          this.changeHeaderColor("white");
          this.changeTitleColor("black--text");
          this.changeButtonColor("black--text hidden-sm-and-down text-none caption");
        } else {
          this.changeHeaderColor("white");
          this.changeTitleColor("white--text");
          this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
        }
      },
  },
  computed: {
    ...mapGetters(["darkState"]),
  },
};
</script>
