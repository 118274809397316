import apiCall from '@/utils/api'

const state = {
	competitors: [],
	allCompetitors: [],
    competitorPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    competitorLoader: false
};

const getters = {
	competitors: (state) => state.competitors,
	allCompetitors: (state) => state.allCompetitors,
    competitorLoader: (state) => state.competitorLoader,
    competitorPagination: (state) => state.competitorPagination
}

const actions = {
	async fetchAllCompetitors({commit}, page) {
		const response = await apiCall({url: `/api/competitor?type=all`, method: 'GET' });
		commit('setAllCompetitors', response)
        commit('stopLoader', response)
	},
    async fetchCompetitors({commit}, page) {
		const response = await apiCall({url: `/api/competitor?page=${page}`, method: 'GET' });
		commit('setCompetitors', response)
        commit('stopLoader', response)
	},
    async filterCompetitors({commit, state},resp){
		commit('setCompetitors', resp)
	},
	async startCompetitorLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopCompetitorLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setCompetitors: (state, competitors) => {
		state.competitors = competitors.data
		state.competitorPagination.current_page = competitors.current_page
		state.competitorPagination.total = competitors.total
		state.competitorPagination.per_page = competitors.per_page
	},
	setAllCompetitors: (state, competitors) => {
		state.allCompetitors = competitors
	},
    startLoader: (state) => state.competitorLoader = true,
	stopLoader: (state) => state.competitorLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
