<template>
    <div class="TermsOfUse">
        <v-container>
            <v-card elevation="0" class="pa-16 my-10">
                <div class="headline">
                    <b>TERMS AND CONDITIONS</b>
                </div>
                <div class="mt-5">
                    <b>Last updated September 25, 2024</b>
                </div>
                <div class="mt-10">
                    <p class="body-2 mt-5">
                        These terms and conditions ("TERMS") govern your participation in the Women Entrepreneurship Incubator Program ("THE PROGRAM") offered by USAID in partnership with Strathmore University. The Program shall be executed by the Women Entrepreneurship Incubator Program team (“THE TEAM”).  By participating in THE PROGRAM, you agree to be bound by these Terms.
                    </p>
                </div>
                <div class="title mt-10">
                    <b>TABLE OF CONTENTS</b>
                </div>
                <div class="body-2 mt-5">
                    <ol>
                        <li> <a @click="scrollMeTo('section-1')">ACCOUNT REGISTRATION AND APPLICATION PROCESS</a></li>
                        <li> <a @click="scrollMeTo('section-2')">THE PROGRAM</a></li>
                        <li> <a @click="scrollMeTo('section-3')">ELIGIBILITY</a></li>
                        <li> <a @click="scrollMeTo('section-4')">REGISTRATION</a></li>
                        <li> <a @click="scrollMeTo('section-5')">PROGRAM PARTICIPATION</a></li>
                        <li> <a @click="scrollMeTo('section-6')">INTELLECTUAL PROPERTY</a></li>
                        <li> <a @click="scrollMeTo('section-7')">LIABILITY</a></li>
                        <li> <a @click="scrollMeTo('section-8')">MODIFICATION AND TERMINATION</a></li>
                        <li> <a @click="scrollMeTo('section-9')">GOVERNING LAW</a></li>
                        <li> <a @click="scrollMeTo('section-10')">CONTACT US</a></li>
                    </ol>
                </div>
                <div class="title mt-10" ref="section-1">
                    <b>1. ACCOUNT REGISTRATION AND APPLICATION PROCESS</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>1.1 You must be 18 years or older to register for an account on the Women Entrepreneurship Incubator website.</li>
                            <li>1.2 You agree to provide accurate and complete information during the registration and application process and to keep your account information updated.</li>
                            <li>1.3 You are responsible for maintaining the confidentiality of your account login credentials and for all activities that occur under your account.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-2">
                    <b>2. THE PROGRAM</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        By applying to the program, you commit to attend all the physical and virtual sessions of the program for the stipulated period. You also agree to abide by all general guidelines of the program provided to your company or business and all team members engaged before and during the program.
                    </p>
                </div>
                <div class="title mt-10" ref="section-3">
                    <b>3. ELIGIBILITY</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        The Program is open to individuals who meet the eligibility criteria specified by USAID and Strathmore University.
                    </p>
                </div>
                <div class="title mt-10" ref="section-4">
                    <b>4. REGISTRATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>4.1 You may be required to register for the Program by providing accurate and complete information as requested by the team.</li>
                            <li>4.2 You agree to keep your registration information updated and to notify the WEIP Team of any changes or inaccuracies.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-5">
                    <b>5. PROGRAM PARTICIPATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>5.1 By participating in the Program, you agree to comply with all rules, guidelines, and instructions provided by the team.</li>
                            <li>5.2 You agree to conduct yourself in a respectful and professional manner during all Program activities and interactions with other participants and organizers.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-6">
                    <b>6. INTELLECTUAL PROPERTY</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>6.1 All intellectual property rights related to the Program, including but not limited to materials, content, and presentations, are owned by USAID, Strathmore University or its licensors.</li>
                            <li>6.2 You may not reproduce, distribute, modify, or create derivative works based on Program materials without the express written consent of the Team.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-7">
                    <b>7. LIABILITIES</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>7.1 USAID and Strathmore University shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with your participation in the Program.</li>
                            <li>7.2 You agree to indemnify and hold harmless USAID, Strathmore University, its affiliates, and their respective officers, directors, employees, and agents from any claims, damages, or liabilities arising out of your participation in the Program.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-8">
                    <b>8. MODIFICATION AND TERMINATION</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        <ul>
                            <li>8.1 The Team reserves the right to modify, suspend, or terminate the Program, or any part thereof, at any time and for any reason without prior notice.</li>
                            <li>8.2 The Team reserves the right to disqualify any participant who violates these Terms or engages in any conduct that USAID and/or Strathmore University deem to be inappropriate or detrimental to the Program.</li>
                        </ul>
                    </p>
                </div>
                <div class="title mt-10" ref="section-9">
                    <b>9. GOVERNING LAW</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        These Terms shall be governed by and construed in accordance with the laws of Kenya, without regard to its conflict of law provisions.
                    </p>
                </div>
                <div class="title mt-10" ref="section-10">
                    <b>10. CONTACT US</b>
                </div>
                <div>
                    <p class="body-2 mt-5">
                        If you have any questions about these Terms, please contact us at adminibiz@strathmore.edu
                    </p>
                </div>
        </v-card>
    </v-container>
</div></template>
<script>
export default {
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        }
    }
}
</script>