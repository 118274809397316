import apiCall from '@/utils/api'

const state = {
	galleries: [],
	allGalleries: [],
    galleryPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    galleryLoader: false
};

const getters = {
	galleries: (state) => state.galleries,
	allGalleries: (state) => state.allGalleries,
    galleryLoader: (state) => state.galleryLoader,
    galleryPagination: (state) => state.galleryPagination
}

const actions = {
	async fetchAllGalleries({commit}, page) {
		const response = await apiCall({url: `/api/gallery?type=all`, method: 'GET' });
		commit('setAllGalleries', response)
        commit('stopLoader', response)
	},
    async fetchGalleries({commit}, page) {
		const response = await apiCall({url: `/api/gallery?page=${page}`, method: 'GET' });
		commit('setGalleries', response)
        commit('stopLoader', response)
	},
    async filterGalleries({commit, state},resp){
		commit('setGalleries', resp)
	},
	async startGalleryLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopGalleryLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setGalleries: (state, galleries) => {
		state.galleries = galleries.data
		state.galleryPagination.current_page = galleries.current_page
		state.galleryPagination.total = galleries.total
		state.galleryPagination.per_page = galleries.per_page
	},
	setAllGalleries: (state, galleries) => {
		state.allGalleries = galleries
	},
    startLoader: (state) => state.galleryLoader = true,
	stopLoader: (state) => state.galleryLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
