import apiCall from '@/utils/api'

const state = {
	submittedApplications: [],
    submittedApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    submittedApplicationLoader: false,

	reviewApplications: [],
    reviewApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    reviewApplicationLoader: false,

	rejectedApplications: [],
    rejectedApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    rejectedApplicationLoader: false,

	selectedForRatingApplications: [],
    selectedForRatingApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    selectedForRatingApplicationLoader: false,

	shortlistedApplications: [],
    shortlistedApplicationPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    shortlistedApplicationLoader: false,
};

const getters = {
	submittedApplications: (state) => state.submittedApplications,
    submittedApplicationLoader: (state) => state.submittedApplicationLoader,
    submittedApplicationPagination: (state) => state.submittedApplicationPagination,

	reviewApplications: (state) => state.reviewApplications,
    reviewApplicationLoader: (state) => state.reviewApplicationLoader,
    reviewApplicationPagination: (state) => state.reviewApplicationPagination,

	rejectedApplications: (state) => state.rejectedApplications,
    rejectedApplicationLoader: (state) => state.rejectedApplicationLoader,
    rejectedApplicationPagination: (state) => state.rejectedApplicationPagination,

	selectedForRatingApplications: (state) => state.selectedForRatingApplications,
    selectedForRatingApplicationLoader: (state) => state.selectedForRatingApplicationLoader,
    selectedForRatingApplicationPagination: (state) => state.selectedForRatingApplicationPagination,

	shortlistedApplications: (state) => state.shortlistedApplications,
    shortlistedApplicationLoader: (state) => state.shortlistedApplicationLoader,
    shortlistedApplicationPagination: (state) => state.shortlistedApplicationPagination,
}

const actions = {
	async fetchSubmittedApplications({commit}, page) {
		const response = await apiCall({url: `/api/application?type=submittedSearch&page=${page}`, method: 'GET' });
		commit('setSubmittedApplications', response)
        commit('stopSubmittedApplicationLoader', response)
	},
    async filterSubmittedApplications({commit, state},resp){
		commit('setSubmittedApplications', resp)
	},
	async startSubmittedApplicationLoader({commit, state},resp){
		commit('startSubmittedApplicationLoader', resp)
	},
	async stopSubmittedApplicationLoader({commit, state},resp){
		commit('stopSubmittedApplicationLoader', resp)
	},

	async fetchReviewApplications({commit}, page) {
		const response = await apiCall({url: `/api/application?type=reviewSearch&page=${page}`, method: 'GET' });
		commit('setReviewApplications', response)
        commit('stopReviewApplicationLoader', response)
	},
    async filterReviewApplications({commit, state},resp){
		commit('setReviewApplications', resp)
	},
	async startReviewApplicationLoader({commit, state},resp){
		commit('startReviewApplicationLoader', resp)
	},
	async stopReviewApplicationLoader({commit, state},resp){
		commit('stopReviewApplicationLoader', resp)
	},

	async fetchRejectedApplications({commit}, page) {
		const response = await apiCall({url: `/api/application?type=rejectedSearch&page=${page}`, method: 'GET' });
		commit('setRejectedApplications', response)
        commit('stopRejectedApplicationLoader', response)
	},
    async filterRejectedApplications({commit, state},resp){
		commit('setRejectedApplications', resp)
	},
	async startRejectedApplicationLoader({commit, state},resp){
		commit('startRejectedApplicationLoader', resp)
	},
	async stopRejectedApplicationLoader({commit, state},resp){
		commit('stopRejectedApplicationLoader', resp)
	},

	async fetchSelectedForRatingApplications({commit}, page) {
		const response = await apiCall({url: `/api/application?type=selectedForRatingSearch&page=${page}`, method: 'GET' });
		commit('setSelectedForRatingApplications', response)
        commit('stopSelectedForRatingApplicationLoader', response)
	},
    async filterSelectedForRatingApplications({commit, state},resp){
		commit('setSelectedForRatingApplications', resp)
	},
	async startSelectedForRatingApplicationLoader({commit, state},resp){
		commit('startSelectedForRatingApplicationLoader', resp)
	},
	async stopSelectedForRatingApplicationLoader({commit, state},resp){
		commit('stopSelectedForRatingApplicationLoader', resp)
	},

	async fetchShortlistedApplications({commit}, page) {
		const response = await apiCall({url: `/api/application?type=shortlistedSearch&page=${page}`, method: 'GET' });
		commit('setShortlistedApplications', response)
        commit('stopShortlistedApplicationLoader', response)
	},
    async filterShortlistedApplications({commit, state},resp){
		commit('setShortlistedApplications', resp)
	},
	async startShortlistedApplicationLoader({commit, state},resp){
		commit('startShortlistedApplicationLoader', resp)
	},
	async stopShortlistedApplicationLoader({commit, state},resp){
		commit('stopShortlistedApplicationLoader', resp)
	},
};

const mutations = {
	setSubmittedApplications: (state, submittedApplications) => {
		state.submittedApplications = submittedApplications.data
		state.submittedApplicationPagination.current_page = submittedApplications.current_page
		state.submittedApplicationPagination.total = submittedApplications.total
		state.submittedApplicationPagination.per_page = submittedApplications.per_page
	},
    startSubmittedApplicationLoader: (state) => state.submittedApplicationLoader = true,
	stopSubmittedApplicationLoader: (state) => state.submittedApplicationLoader = false,

	setReviewApplications: (state, reviewApplications) => {
		state.reviewApplications = reviewApplications.data
		state.reviewApplicationPagination.current_page = reviewApplications.current_page
		state.reviewApplicationPagination.total = reviewApplications.total
		state.reviewApplicationPagination.per_page = reviewApplications.per_page
	},
    startReviewApplicationLoader: (state) => state.submittedApplicationLoader = true,
	stopReviewApplicationLoader: (state) => state.submittedApplicationLoader = false,

	setRejectedApplications: (state, rejectedApplications) => {
		state.rejectedApplications = rejectedApplications.data
		state.rejectedApplicationPagination.current_page = rejectedApplications.current_page
		state.rejectedApplicationPagination.total = rejectedApplications.total
		state.rejectedApplicationPagination.per_page = rejectedApplications.per_page
	},
    startRejectedApplicationLoader: (state) => state.submittedApplicationLoader = true,
	stopRejectedApplicationLoader: (state) => state.submittedApplicationLoader = false,

	setSelectedForRatingApplications: (state, selectedForRatingApplications) => {
		state.selectedForRatingApplications = selectedForRatingApplications.data
		state.selectedForRatingApplicationPagination.current_page = selectedForRatingApplications.current_page
		state.selectedForRatingApplicationPagination.total = selectedForRatingApplications.total
		state.selectedForRatingApplicationPagination.per_page = selectedForRatingApplications.per_page
	},
    startSelectedForRatingApplicationLoader: (state) => state.submittedApplicationLoader = true,
	stopSelectedForRatingApplicationLoader: (state) => state.submittedApplicationLoader = false,

	setShortlistedApplications: (state, shortlistedApplications) => {
		state.shortlistedApplications = shortlistedApplications.data
		state.shortlistedApplicationPagination.current_page = shortlistedApplications.current_page
		state.shortlistedApplicationPagination.total = shortlistedApplications.total
		state.shortlistedApplicationPagination.per_page = shortlistedApplications.per_page
	},
    startShortlistedApplicationLoader: (state) => state.shortlistedApplicationLoader = true,
	stopShortlistedApplicationLoader: (state) => state.shortlistedApplicationLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
