<template>
  <div class="programs" v-if="$can('program_view')">
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-container>
      <v-layout column>
        <div v-if="view == 'default'">
          <v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
            <v-flex xs12 class="mt-10 mb-2">
              <div class="mx-5">
                <v-layout row wrap>
                  <v-flex xs12 md4>
                    <h1>Programs</h1>
                  </v-flex>
                  <v-flex xs12 md4>
                    <div class="pa-1">
                      <v-text-field class="text_field header" outlined dense label="Search"
                        append-icon="mdi-undo-variant" @click:append="resetSearch()" v-on:keyup.enter="search"
                        v-model="searchTerm" @input="enableSearch()"></v-text-field>
                    </div>

                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn class="secondary white--text text-none" depressed block @click="search"
                        :loading="searchLoader" :disabled="searchButtonDisabled">
                        Search
                        <v-icon right>mdi-magnify</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs12 md2>
                    <div class="pa-1">
                      <v-btn block depressed class="primary text-none" @click="changeView('create')"
                        v-if="$can('program_create')">
                        Add Program
                        <v-icon right>mdi-plus-circle-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <div class="mx-5 mt-9">
              <v-progress-linear v-if="programLoader" height="1" indeterminate color="primary"></v-progress-linear>
            </div>
            <v-divider class="mx-5"></v-divider>         
            <v-flex xs12 class="mt-5">
              <div v-if="programs.length == 0">
                <v-card elevation="0">
                  <v-layout row wrap>
                    <v-flex xs12 md1>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center">
                            <v-icon large class="text--text">
                              mdi-alert-circle-outline
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                    <v-flex xs12 md11>
                      <v-container fill-height fluid>
                        <v-row align="center" justify="center">
                          <v-col class="text-center"> No Program Found </v-col>
                        </v-row>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
              <div v-else>
                <div class="hidden-sm-and-down">
                  <v-card elevation="0" class="pa-2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead class="header">
                          <tr class="tableHeader">
                            <th class="text-left text--text">Name</th>
                            <th class="text-left text--text">Application Deadline</th>
                            <th class="text-left text--text">Start Date</th>
                            <th class="text-left text--text">End Date</th>

                            <th class="text-left text--text">Active</th>
                            <th class="text-right text--text">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in programs" :key="item.id">
                            <td>
                              {{ item.name }}
                            </td>
                            <td>
                              {{ item.application_deadline }}
                            </td>
                            <td>{{ item.start_date }}</td>
                            <td>{{ item.end_date }}</td>

                            <td>
                              <div v-if="item.active == 1">Yes</div>
                              <div v-else>No</div>
                            </td>
                            <td>
                              <div align="right">
                                <v-tooltip top v-if="$can('program_view')">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 universal--text" @click="showProgram(item)">
                                      <v-icon small> mdi-eye </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>View</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('program_edit')">

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 success--text" @click="editProgram(item)"
                                      v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-pencil </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('program_archive')">

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 error--text" @click="deleteProgram(item)"
                                      :loading="loading && programIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>delete</span>
                                </v-tooltip>

                                <v-tooltip top v-if="$can('program_activate') && item.active == 0">

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 red--text" @click="activateProgram(item)"
                                      :loading="activateLoading && programIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch-off-outline
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Activate Program</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('program_deactivate') && item.active == 1">

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 green--text" @click="deactivateProgram(item)"
                                      :loading="deactivateLoading && programIndex == item.id" v-bind="attrs" v-on="on">
                                      <v-icon small> mdi-toggle-switch </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Deactivate Program</span>
                                </v-tooltip>
                                <v-tooltip top v-if="$can('log_view')">

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="button mr-1 purple--text" @click="viewLog(item)" v-bind="attrs"
                                      v-on="on">
                                      <v-icon small> mdi-script-text </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Log</span>
                                </v-tooltip>

                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>

                </div>
                <div class="hidden-md-and-up">
                  <v-layout column>

                    <template v-for="(program, index) in programs">
                      <div :key="index">
                        <v-flex xs12 class="mb-2">
                          <v-card elevation="0">
                            <div class="pa-5">
                              <v-layout column>
                                <v-flex xs12>
                                  <v-layout row wrap>
                                    <v-flex xs11>
                                      <div class="title primary--text ml-3">
                                        <b>{{ program.name }}</b>
                                      </div>
                                    </v-flex>
                                    <v-flex xs1>
                                      <v-btn depressed class="red--text" :loading="loading && programIndex == program.id
    " icon @click="deleteProgram(program)" v-if="$can('program_archive')">
                                        <v-icon> mdi-delete </v-icon>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-divider class="mt-2"></v-divider>
                                <v-flex xs12 class="mt-1">
                                  <v-layout column>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Application Deadline</b>
                                      </div>
                                      <div>{{ program.application_deadline }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Start Date</b>
                                      </div>
                                      <div>{{ program.start_date }}</div>
                                    </v-flex>
                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>End Date</b>
                                      </div>
                                      <div>{{ program.end_date }}</div>
                                    </v-flex>

                                    <v-flex xs12 class="mt-1">
                                      <div>
                                        <b>Active</b>
                                      </div>
                                      <div>
                                        <div v-if="program.active == 1">Yes</div>
                                        <div v-else>No</div>
                                      </div>
                                    </v-flex>
                                    
                                    <v-flex xs12 class="mt-5">
                                      <v-layout row wrap>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn v-if="$can('program_view')" depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showProgram(program)">View
                                              <v-icon right> mdi-eye </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="blue text-none white--text" block
                                              @click="editProgram(program)" v-if="$can('program_edit')">Edit
                                              <v-icon right>
                                                mdi-border-color
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="green text-none white--text" block
                                              @click="activateProgram(program)"
                                              :loading="activateLoading && programIndex == program.id"
                                              v-if="$can('program_activate') && program.active == 0">Activate
                                              Program
                                              <v-icon right>
                                                mdi-toggle-switch-off-outline
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn depressed class="red text-none white--text" block
                                              @click="deactivateProgram(program)"
                                              :loading="deactivateLoading && programIndex == program.id"
                                              v-if="$can('program_deactivate') && program.active == 1">Deactivate
                                              Program
                                              <v-icon right>
                                                mdi-toggle-switch
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                        <v-flex xs12>
                                          <div class="pa-1">
                                            <v-btn v-if="$can('log_view')" depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(program)">Log
                                              <v-icon right> mdi-script-text
                                              </v-icon>
                                            </v-btn>
                                          </div>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-card>
                        </v-flex>
                      </div>
                    </template>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-card>

          <v-layout column class="mt-5">
            <v-flex xs12>
              <div align="center">
                <v-pagination v-if="length != 0" :length="length" total-visible="10"
                  v-model="programPagination.current_page" @input="changePage()" circle>
                </v-pagination>
              </div>
            </v-flex>
            <v-flex xs12>
              <div v-if="length != 0" align="center" class="mt-5">
                <b class="primary--text">Total: </b>{{ programPagination.total | formatNumber }}
              </div>
            </v-flex>
          </v-layout>
        </div>
        <div v-if="view == 'create'">
          <v-flex class="mt-5">
            <v-card outlined>
              <v-card-title class="tableHeader text--text">
                <v-spacer></v-spacer>
                <div v-if="newProgram.id == null">Add Program</div>
                <div v-else>Edit Program</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="changeView('default')">
                  <v-icon class="text--text"> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-layout column>
                    <v-flex xs12>
                      <v-layout row wrap class="mt-5">
                        <v-flex xs12 md8>
                          <div class="mt-3 mx-1">
                            <div>Program<span class="error--text">*</span></div>
                            <v-text-field class="text_field background" outlined dense v-model="newProgram.name"
                              :rules="inputRules"></v-text-field>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                          <div class="mt-3 mx-1">
                            <div>Application Deadline<span class="error--text">*</span></div>
                            <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="applicationDeadlineDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatApplicationDeadlineDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newProgram.application_deadline" :min="dateToday" @change="closeApplicationDeadlineDate">
                            </v-date-picker>
                          </v-menu>
                          </div>
                        </v-flex>
                        <v-flex xs12 md4>
                        <div class="mx-1 mt-5">
                          <div>
                            Start Date<span class="error--text">*</span>
                          </div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="startDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatStartDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newProgram.start_date" :min="dateToday" :max="newProgram.end_date" @change="closeStartDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                      <v-flex xs12 md4>
                        <div class="mx-1 mt-5">
                          <div>
                            End Date<span class="error--text">*</span>
                          </div>
                          <v-menu :close-on-content-click="false" max-width="290" transition="scale-transition"
                            v-model="endDatePicker" offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined dense :value="formatEndDate" slot="activator" readonly v-bind="attrs"
                                v-on="on" class="text_field background" append-icon="mdi-calendar"></v-text-field>
                            </template>
                            <v-date-picker v-model="newProgram.end_date" :min="newProgram.start_date" @change="closeEndDate">
                            </v-date-picker>
                          </v-menu>
                        </div>
                      </v-flex>
                        
                      </v-layout>
                    </v-flex>
                  </v-layout>

                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
                  Save
                  <v-icon right> mdi-content-save </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </div>
        <div v-if="view == 'show'">
          <v-card outlined class="mt-5">
            <v-card-title class="tableHeader text--text">
              View Program
              <v-spacer></v-spacer>
              <v-btn icon @click="changeView('default')">
                <v-icon class="text--text"> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <div class="pa-5">
                <v-layout column>
                  <v-flex xs12 class="mt-10">
                    <v-layout row wrap>
                      <v-flex xs6>
                        <b>Start Date:</b>
                      </v-flex>
                      <v-flex xs6>
                        <div>
                          {{ assignedProgram.start_date }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <v-layout row wrap>
                      <v-flex xs6>
                        <b>End Date:</b>
                      </v-flex>
                      <v-flex xs6>
                        <div>
                          {{ assignedProgram.end_date }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="mt-5">
                    <v-layout row wrap>
                      <v-flex xs6>
                        <b>Deadline Date:</b>
                      </v-flex>
                      <v-flex xs6>
                        <div>
                          {{ assignedProgram.application_deadline }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-layout>
    </v-container>
    <logData v-if="logDataModel" v-model="logDataModel" />
  </div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
  components: {
    logData,
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      view: "default",
      loading: false,
      activateLoading: false,
      deactivateLoading: false,

      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [function (editor) {
          editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new uploadAdapter(loader);
          }
        }],
        language: 'en',
      },

      applicationDeadlineDatePicker: false,
      startDatePicker: false,
      endDatePicker: false,
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      monthNames: [
        { name: "January" },
        { name: "February" },
        { name: "March" },
        { name: "April" },
        { name: "May" },
        { name: "June" },
        { name: "July" },
        { name: "August" },
        { name: "September" },
        { name: "October" },
        { name: "November" },
        { name: "December" },
      ],

      valid: true,
      message: "",
      color: "",
      delete: false,
      activate: false,
      confirm: false,
      programIndex: null,
      snackbar: false,
      inputRules: [
        (v) => !!v || "Input is required"
      ],
      searchTerm: null,
      searchLoader: false,
      searchButtonDisabled: false,
      filteredPrograms: false,
      newProgram: {
        id: null,
        name: null,
        application_deadline: null,
        start_date: null,
        end_date: null,
      },
      assignedProgram: null,
      formData: null,
      logData: {
        message: null,
        model: "App\\Models\\Program",
      },
    };
  },
  created() {
    if (this.$can("program_view")) {
      this.startProgramLoader();
      this.fetchPrograms(this.programPagination.current_page);
      // this.getFormData();
    }
  },
  methods: {
    ...mapActions([
      "fetchPrograms",
      "startProgramLoader",
      "stopProgramLoader",
      "filterPrograms",

      "filterLogData",
      "openLogDataModel",
    ]),
    enableSearch() {
      this.searchButtonDisabled = false;
    },
    closeApplicationDeadlineDate() {
      this.applicationDeadlineDatePicker = false;
    },
    closeStartDate() {
      this.startDatePicker = false;
    },
    closeEndDate() {
      this.endDatePicker = false;
    },
    resetSearch() {
      this.searchTerm = null;
      this.filteredPrograms = true;
      this.startProgramLoader();
      this.fetchPrograms(1);
    },
    showProgram(program) {
      this.assignedProgram = program;
      this.changeView("show");
    },
    editProgram(program) {
      this.newProgram.id = program.id;
      this.newProgram.name = program.name;
      this.newProgram.application_deadline = program.application_deadline;
      this.newProgram.program = program.program;
      this.newProgram.start_date = program.start_date;
      this.newProgram.end_date = program.end_date;

      this.changeView("create");
    },
    viewLog(data) {
      this.logData.message = data.id;
      this.filterLogData(this.logData);
      this.openLogDataModel();
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.newProgram.id != null) {
          confirm("Are You Sure You Want to Update Program") && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/program/" + this.newProgram.id,
              data: this.newProgram,
              method: "PUT",
            })
              .then((resp) => {
                this.message = "Program Updated Successfully";
                this.color = "orange";
                this.loading = false;
                this.snackbar = true;
                this.confirm = false;
                this.changeView("default");
                this.fetchPrograms(this.programPagination.current_page);
              })
              .catch((error) => {
                this.message = "An Error Occurred";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
              });
          }
        } else {
          confirm("Are You Sure You Want to Create Program?") && (this.confirm = true);
          if (this.confirm) {
            this.loading = true;
            apiCall({
              url: "/api/program",
              data: this.newProgram,
              method: "POST",
            })
              .then((resp) => {
                if (resp.status == 409) {
                  this.message = resp.message
                  this.color = "error";
                  this.loading = false;
                  this.snackbar = true;
                  this.alertType = "error";
                } else {
                  this.message = "Program Added Successfully";
                  this.color = "success";
                  this.loading = false;
                  this.snackbar = true;
                  this.changeView("default");
                  this.fetchPrograms(1);
                }
              })
              .catch((error) => {
                this.message = "An Error Occurred Email";
                this.color = "error";
                this.loading = false;
                this.snackbar = true;
                this.changeView("default");
                this.fetchPrograms(1);
              });
          }
        }
      }
    },
    search() {
      if (this.searchTerm == null) {
        this.searchButtonDisabled = true;
      } else {
        this.searchLoader = true;
        this.searchButtonDisabled = false;
        this.startProgramLoader();
        apiCall({
          url: "/api/program?type=search&search=" + this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterPrograms(resp);
            this.searchLoader = false;
            this.stopProgramLoader();
            this.filteredPrograms = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.searchLoader = false;
            this.stopProgramLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      }
    },
    changeView(view) {
      if (view == "default") {
        this.lockEmail = false;
        this.newProgram.id = null;
        this.newProgram.name = null;
        this.newProgram.application_deadline = null;
        this.newProgram.program = null;
        this.newProgram.start_date = null;
        this.newProgram.end_date = null;
      } else if (view == "create") {
        this.getFormData();
      } else if (view == "show") {
      }
      this.view = view;
    },
    changePage() {
      this.startProgramLoader();
      if (this.filteredPrograms == true) {
        this.searchButtonDisabled = false;
        apiCall({
          url:
            "/api/program?page=" +
            this.programPagination.current_page +
            "&type=search&search=" +
            this.searchTerm,
          method: "GET",
        })
          .then((resp) => {
            this.filterPrograms(resp);
            this.stopProgramLoader();
          })
          .catch((error) => {
            this.stopProgramLoader();
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
          });
      } else {
        this.fetchPrograms(this.programPagination.current_page);
      }
    },
    deleteProgram(item) {
      confirm("Are You Sure You Want to Delete Program?") && (this.delete = true);
      if (this.delete) {
        this.loading = true;
        this.programIndex = item.id;
        apiCall({ url: "/api/program/" + item.id, method: "DELETE" })
          .then((resp) => {
            this.message = "Program Deleted Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.programIndex = null;
            this.fetchPrograms(this.programPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.loading = false;
            this.delete = false;
            this.programIndex = null;
            console.log(error.response);
          });
      }
    },
    activateProgram(item) {
      confirm("Are You Sure You Want to Activate Program?") && (this.activate = true);
      if (this.activate) {
        this.activateLoading = true;
        this.programIndex = item.id;
        this.newProgram.id = item.id;
        apiCall({
          url: "/api/program?type=activate",
          data: this.newProgram,
          method: "POST"
        })
          .then((resp) => {
            this.message = "Program Program Activated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.programIndex = null;
            this.newProgram.id = null;
            this.fetchPrograms(this.programPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.activateLoading = false;
            this.activate = false;
            this.programIndex = null;
            this.newProgram.id = null;
            console.log(error.response);
          });
      }
    },
    deactivateProgram(item) {
      confirm("Are You Sure You Want to Deactivate Program?") && (this.activate = true);
      if (this.activate) {
        this.deactivateLoading = true;
        this.programIndex = item.id;
        this.newProgram.id = item.id;
        apiCall({
          url: "/api/program?type=deactivate",
          data: this.newProgram,
          method: "POST"
        })
          .then((resp) => {
            this.message = "Program Program Deactivated Succesfully";
            this.color = "success";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.programIndex = null;
            this.newProgram.id = null;
            this.fetchPrograms(this.programPagination.current_page);
          })
          .catch((error) => {
            this.message = "An Error Occurred";
            this.color = "error";
            this.snackbar = true;
            this.deactivateLoading = false;
            this.activate = false;
            this.programIndex = null;
            this.newProgram.id = null;
            console.log(error);
          });
      }
    },
    getFormData() {
      apiCall({
        url: "/api/program?type=formData",
        method: "GET",
      })
        .then((resp) => {
          this.formData = resp;
        })
        .catch((error) => {
          this.message = "An Error Occurred; Couldn't Get Form Data";
          this.color = "error";
        });
    },
  },
  computed: {
    ...mapGetters(["programs", "programPagination", "programLoader", "logDataModel"]),
    
    formatApplicationDeadlineDate() {
      if (this.newProgram.application_deadline != null) {
        const d = new Date(this.newProgram.application_deadline);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatStartDate() {
      if (this.newProgram.start_date != null) {
        const d = new Date(this.newProgram.start_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    formatEndDate() {
      if (this.newProgram.end_date != null) {
        const d = new Date(this.newProgram.end_date);
        const year = d.getFullYear();
        const date = d.getDate();
        const month = d.getMonth();

        return date + " " + this.monthNames[month].name + " " + year;
      }
    },
    dateToday() {
      var today = new Date();

      var timestamp =
        today.getFullYear() +
        "-" +
        this.months[today.getMonth()] +
        "-" +
        ("0" + today.getDate()).slice(-2);
      return timestamp;
    },

    length: function () {
      return Math.ceil(
        this.programPagination.total / this.programPagination.per_page
      );
    },
  },
};
</script>