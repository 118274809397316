<template>
	<div class="galleries" v-if="$can('gallery_view')">
		<v-dialog
			transition="dialog-bottom-transition"
			max-width="600"
			v-model="imageDialog"
		>
			<v-card max-width="600">
			<v-toolbar flat>
				Upload Image
				<v-spacer></v-spacer>
				<v-btn icon @click="imageDialog = false">
				<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
	
			<v-form ref="imageForm" v-model="imageValid" lazy-validation>
				<v-layout column>
				<v-flex xs12>
					<v-file-input
					class="mx-2 mt-3"
					:loading="imageLoading"
					:rules="fileRules"
					filled
					dense
					show-size
					chips
					accept="image/*"
					v-model="imageFile"
					label="Select Image"
					></v-file-input>
				</v-flex>
				<v-flex xs12>
					<div align="right" class="mb-3 mx-3">
					<v-btn
						depressed
						block
						class="primary text-none"
						@click="uploadImage"
						:loading="imageLoading"
						:disabled="!imageValid"
					>
						Submit <v-icon right dark>mdi-upload</v-icon>
					</v-btn>
					</div>
				</v-flex>
				</v-layout>
			</v-form>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>Galleries</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()"
												v-on:keyup.enter="search" v-model="searchTerm"
												@input="enableSearch()"></v-text-field>
										</div>

									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block
												@click="search" :loading="searchLoader"
												:disabled="searchButtonDisabled">
												Search
												<v-icon right>mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none"
												@click="changeView('create')" v-if="$can('gallery_create')">
												Add Gallery
												<v-icon right>mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="galleryLoader" height="1" indeterminate
								color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="galleries.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Gallery Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">#</th>
														<th class="text-left text--text">Name</th>
														<th class="text-left text--text">No. of Album(s)</th>
														<th class="text-left text--text">Active</th>
														<th class="text-left text--text">Date Added</th>
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in galleries" :key="item.id">
														<td>
															{{ index + 1 }}
														</td>
														<td>{{ item.name }} </td>
														<td>{{ item.albums.length }}</td>
														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<td>
															{{
		item.created_at | moment("DD/MM/YYYY - hh:mm a")
	}}
														</td>
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('gallery_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text"
																			@click="showGallery(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('gallery_edit')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text"
																			@click="editGallery(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('gallery_archive')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text"
																			@click="deleteGallery(item)"
																			:loading="loading && galleryIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top
																	v-if="$can('gallery_activate') && item.active == 0">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text"
																			@click="activateGallery(item)"
																			:loading="activateLoading && galleryIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate Gallery</span>
																</v-tooltip>
																<v-tooltip top
																	v-if="$can('gallery_deactivate') && item.active == 1">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text"
																			@click="deactivateGallery(item)"
																			:loading="deactivateLoading && galleryIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate Gallery</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text"
																			@click="viewLog(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>

										<template v-for="(gallery, index) in galleries">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title text--text ml-3">
																				<b>{{ gallery.name }} </b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && galleryIndex == gallery.id
		" icon @click="deleteGallery(gallery)" v-if="$can('gallery_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>No. of Album(s)</b>
																			</div>
																			<div>{{ gallery.albums.length }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="gallery.active == 1">Yes
																				</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Date Added</b>
																			</div>
																			<div>
																				{{
		gallery.created_at
		| moment("DD/MM/YYYY - hh:mm a")
	}}
																			</div>
																		</v-flex>



																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn
																							v-if="$can('gallery_view')"
																							depressed class="
																							primary
																							text-none
																							white--text
																							" block @click="showGallery(gallery)">View
																							<v-icon right> mdi-eye
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="blue text-none white--text"
																							block
																							@click="editGallery(gallery)"
																							v-if="$can('gallery_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="green text-none white--text"
																							block
																							@click="activateGallery(gallery)"
																							:loading="activateLoading && galleryIndex == gallery.id"
																							v-if="$can('gallery_activate') && gallery.active == 0">Activate
																							Gallery
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="red text-none white--text"
																							block
																							@click="deactivateGallery(gallery)"
																							:loading="deactivateLoading && galleryIndex == gallery.id"
																							v-if="$can('gallery_deactivate') && gallery.active == 1">Deactivate
																							Gallery
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')"
																							depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(gallery)">Log
																							<v-icon right>
																								mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="galleryPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ galleryPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newGallery.id == null">Add Gallery</div>
								<div v-else>Edit Gallery</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-5">
												<v-flex xs12 md4>
													<div class="pa-3">
														<div>Name<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense
															v-model="newGallery.name"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>


											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
									:loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedGallery.name }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="saleTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab @click="activeTab = 'albums'">
										<div class="text-none primary--text">
											Albums ({{ assignedGallery.albums.length }})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab == 'details'" class="mx-3 mt-7">
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Name:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedGallery.name }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Albums:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedGallery.albums.length }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Date Created:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedGallery.created_at | moment("DD/MM/YYYY - hh:mm a") }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Active:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													<div v-if="assignedGallery.active == 1">Yes</div>
													<div v-else>No</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</div>
								<div v-if="activeTab == 'albums'" class="mx-1">
									<div v-if="albumView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<div v-if="albumLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																albumPagination.total |
																formatNumber }}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field header" outlined dense
																	label="Search" append-icon="mdi-undo-variant"
																	@click:append="
																		resetAlbumSearch()
																		" v-on:keyup.enter="albumSearch
																		" v-model="albumSearchTerm" @input="
																		enableAlbumSearch()
																		"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="secondary white--text text-none" depressed
																	block @click="albumSearch" :loading="albumSearchLoader
																	" :disabled="albumSearchButtonDisabled">
																	Search
																	<v-icon right small class="white--text">mdi-magnify</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn block depressed class="primary text-none" @click="changeAlbumView('create')
																	" v-if="$can('album_create')">
																	Add Album
																	<v-icon right small
																		class="white--text">mdi-plus-circle-outline</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="albums.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Album Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>

																<template v-slot:default>
																	<thead class="header">
																		<tr class="secondary">
																			<th class="text-left text--text">Name</th>
																			<th class="text-right text--text">Date</th>
																			<th class="text-left text--text">Date Added
																			</th>
																			<th class="text-left text--text">Status</th>
																			<th class="text-right text--text">Actions
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="item in albums" :key="item.id">
																			<td>
																				{{ item.name }}
																			</td>

																			<td>
																				<div align="right">
																					{{ item.date | moment("DD/MM/YYYY")
																					}}
																				</div>
																			</td>
																			<td>
																				{{ item.created_at | moment("DD/MM/YYYY - hh: mm a") }}
																			</td>
																			<td>
																				<div v-if="item.active == 1">
																					Active
																				</div>
																				<div v-else>Inactive</div>
																			</td>
																			<td>
																				<div align="right">
																					<v-btn v-if="$can('album_view')"
																						icon class="
																								button
																								mr-1
																								primary--text
																							" @click="showAlbum(item)">
																						<v-icon small> mdi-eye </v-icon>
																					</v-btn>
																					<v-btn v-if="$can('album_edit')"
																						icon
																						class="button mr-1 success--text"
																						@click="editAlbum(item)">
																						<v-icon small> mdi-pencil
																						</v-icon>
																					</v-btn>
																					<v-btn v-if="$can(
																						'album_archive'
																					)
																						" icon class="button mr-1 error--text" @click="
																						deleteAlbum(
																							item
																						)
																						" :loading="loading &&
																						albumIndex ==
																						item.id
																						">
																						<v-icon small> mdi-delete
																						</v-icon>
																					</v-btn>
																					<v-tooltip top v-if="$can(
																							'album_activate'
																						) && item.active == 0
																							">
																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 red--text"
																								@click="
																									activateAlbum(
																										item
																									)
																									" :loading="activateLoading &&
																									albumIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch-off-outline
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Activate Album</span>
																					</v-tooltip>
																					<v-tooltip top v-if="$can(
																						'album_deactivate'
																					) && item.active == 1
																						">

																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 green--text"
																								@click="
																									deactivateAlbum(
																										item
																									)
																									" :loading="deactivateLoading &&
																									albumIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Deactivate Album</span>
																					</v-tooltip>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>

																<template v-for="(
                                      album, index
                                    ) in albums">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																				style="border-radius: 25px;" outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
		album.name
	}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																										class="red--text"
																										:loading="loading &&
		albumIndex ==
		album.id
		" icon @click="
		deleteAlbum(
			album
		)
		" v-if="$can(
			'album_archive'
		)
		">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
		album.date |
		moment("DD/MM/YYYY")
	}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="album.status ==
		1
		">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
		album.created_at
		|
		moment("DD/MM/YYYY")
	}}
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12
																											v-if="$can('album_edit')">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="blue darken-1 text-none white--text mt-1"
																													block
																													@click="
		editAlbum(
			album
		)
		">Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'album_activate'
	) && album.status == 0
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
		albumIndex ==
		album.id
		" @click="
		activateAlbum(
			album
		)
		">Activate Album
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'album_deactivate'
	) && album.status == 1
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
		albumIndex ==
		album.id
		" @click="
		deactivateAlbum(
			album
		)
		">Deactivate Album
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="albumLength"
														total-visible="10" v-model="albumPagination.current_page
		" @input="changeAlbumPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="albumView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="secondary text--text">
												<v-spacer></v-spacer>
												<div v-if="newAlbum.id == null">
													Add Album
												</div>
												<div v-else>Edit Album</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
		changeAlbumView('default')
		">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="albumForm" lazy-validation>
													<v-layout row wrap class="mt-5">

														<v-flex xs12 md4>
															<div class="pa-3">
																<div>
																	Name<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																	outlined dense v-model="newAlbum.name"
																	:rules="inputRules"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md3>
															<div class="pa-3">
																<div>
																	Date
																</div>
																<v-menu :close-on-content-click="false" max-width="290"
																	transition="scale-transition"
																	v-model="albumDatePicker" offset-y>

																	<template v-slot:activator="{ on, attrs }">
																		<v-text-field outlined dense
																			:value="formatAlbumDate" slot="activator"
																			readonly v-bind="attrs" v-on="on"
																			class="text_field background my-2"></v-text-field>
																	</template>
																	<v-date-picker v-model="newAlbum.date"
																		:max="dateToday" @change="closeAlbumDate">
																	</v-date-picker>
																</v-menu>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveAlbum"
													:disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save </v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
									<div v-if="albumView == 'show'">
										<v-card outlined class="mt-5">
											<v-card-title class="tableHeader text--text">
												{{ assignedAlbum.name }}
												<v-spacer></v-spacer>
												<v-btn icon @click="changeAlbumView('default')">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<div class="pa-3 mt-5">
													<v-layout column>
														<v-flex xs12>
															<v-layout row wrap>
																<template v-for="(image, index) in images">
																	<v-flex xs12 md4 :key="index">
																		<v-img max-height="200" :src="path+'/storage/'+image.name" style="border-radius: 25px;" class="mx-1 my-1">
																			<div align="right" class="mr-2">
																				<v-btn icon color="red" @click="deleteImage(image)" :loading="loading &&
																					imageIndex ==
																					image.id
																					"																		
																				><v-icon>mdi-delete</v-icon></v-btn>
																			</div>
																		</v-img>
																	</v-flex>
																</template>
																<v-flex xs12 md4>
																	<a>
																		<v-card elevation="0" class="mx-1 my-1 primary" @click="imageDialog = true"
																			style="border-radius: 25px;" height="192"
																			>
																			<div
																				class="d-flex flex-column fill-height justify-center align-center text-white">
																				<v-icon large
																					color="white">mdi-image-plus</v-icon>
																			</div>
																		</v-card>
																	</a>
																</v-flex>
															</v-layout>
														</v-flex>
														<v-flex xs12 class="mt-7">
															<v-pagination v-if="length != 0" :length="imageLength" total-visible="10"
																v-model="imagePagination.current_page" @input="changeImagePage()" circle>
															</v-pagination>
														</v-flex>
													</v-layout>
													
												</div>
											</v-card-text>
										</v-card>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			albumView: "default",
			activeTab: 'details',
			saleTab: null,

			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			albumLoader: false,
			imageLoader: false,

			albumDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			galleryIndex: null,
			albumIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			images: [],

			searchTerm: null,
			albumSearchTerm: null,

			searchLoader: false,
			albumSearchLoader: false,

			searchButtonDisabled: false,
			albumSearchButtonDisabled: false,

			filteredGalleries: false,
			filteredAlbums: false,

			newGallery: {
				id: null,
				name: null,
			},
			newAlbum: {
				id: null,
				gallery_id: null,
				name: null,
				date: null
			},
			newImage: {
				id: null,
				album_id: null,
				name: null,
			},

			assignedGallery: null,
			assignedAlbum: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Gallery",
			},

			albums: [],
			albumPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			imagePagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},

		};
	},
	created() {
		if (this.$can("gallery_view")) {
			this.startGalleryLoader();
			this.fetchGalleries(this.galleryPagination.current_page);
			this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchGalleries",
			"startGalleryLoader",
			"stopGalleryLoader",
			"filterGalleries",

			"filterLogData",
			"openLogDataModel",
		]),
		closeAlbumDate() {
			this.albumDatePicker = false;
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},
		enableAlbumSearch() {
			this.albumSearchButtonDisabled = false;
		},


		resetSearch() {
			this.searchTerm = null;
			this.filteredGalleries = true;
			this.startGalleryLoader();
			this.fetchGalleries(1);
		},
		resetAlbumSearch() {
			this.albumSearchTerm = null;
			this.filteredAlbums = false;
			this.albumLoader = false;
			this.changeAlbumPage();
		},

		showGallery(gallery) {
			this.assignedGallery = gallery;

			this.changeAlbumPage();
			this.changeView("show");
		},
		showAlbum(album) {
			this.assignedAlbum = album;

			this.changeImagePage();
			this.changeImageView("show");
		},

		editGallery(gallery) {
			this.lockEmail = true;
			this.newGallery.id = gallery.id;
			this.newGallery.name = gallery.name;

			this.changeView("create");
		},
		editAlbum(album) {
			this.newAlbum.id = album.id;
			this.newAlbum.gallery_id = album.gallery_id;
			this.newAlbum.name = album.name;
			this.newAlbum.date = album.date;

			this.changeAlbumView("create");
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
			this.imageLoading = true;
			let formData = new FormData();
			
			formData.append("album_id", this.assignedAlbum.id)	
			formData.append("files", this.imageFile, this.imageFile.name);
	
			apiCall({
				url: "/api/image",
				data: formData,
				method: "POST",
			})
				.then((resp) => {
				this.snackbar = true;
				this.message = "Image Uploaded Successfully";
				this.color = "success";
				this.changeImagePage()
				this.imageLoading = false;
				this.imageDialog = false;
				this.imageFile = null
				})
				.catch((error) => {
				this.imageLoading = false;
				});
			}
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newGallery.id != null) {
					confirm("Are You Sure You Want to Update Gallery") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/gallery/" + this.newGallery.id,
							data: this.newGallery,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Gallery Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchGalleries(this.galleryPagination.current_page);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are You Sure You Want to Create Gallery?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/gallery",
							data: this.newGallery,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.alertType = "error";
								} else {
									this.message = "Gallery Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchGalleries(1);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchGalleries(1);
							});
					}
				}
			}
		},
		saveAlbum() {
			if (this.$refs.albumForm.validate()) {
				if (this.newAlbum.id != null) {
					confirm("Are you sure you want to update album?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/album/" + this.newAlbum.id,
							data: this.newAlbum,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Album Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeAlbumView("default");
								this.changeAlbumPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {


					confirm("Are you sure you want to add Album?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newAlbum.gallery_id = this.assignedGallery.id;
						apiCall({
							url: "/api/album",
							data: this.newAlbum,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Album Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newAlbum.gallery_id = null;
								this.changeAlbumView("default");
								this.changeAlbumPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startGalleryLoader();
				apiCall({
					url: "/api/gallery?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterGalleries(resp);
						this.searchLoader = false;
						this.stopGalleryLoader();
						this.filteredGalleries = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopGalleryLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		albumSearch() {
			if (this.albumSearchTerm == null) {
				this.albumSearchButtonDisabled = true;
			} else {
				this.albumSearchLoader = true;
				this.albumSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/album?type=search&search=" +
						this.albumSearchTerm +
						"&gallery_id=" +
						this.assignedGallery.id,
					method: "GET",
				})
					.then((resp) => {
						this.albums = resp.data;
						this.albumPagination.current_page =
							resp.current_page;
						this.albumPagination.total = resp.total;
						this.albumPagination.per_page = resp.per_page;

						this.albumSearchLoader = false;
						this.filteredAlbums = true;
					})
					.catch((error) => {
						this.albumSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.lockEmail = false;
				this.newGallery.id = null;
				this.newGallery.name = null;
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},
		changeAlbumView(view) {
			if (view == "default") {
				this.newAlbum.id = null;
				this.newAlbum.gallery_id = null;
				this.newAlbum.name = null;
				this.newAlbum.date = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.albumView = view;
		},
		changeImageView(view) {
			if (view == "default") {
				this.newImage.id = null;
				this.newImage.album_id = null;
				this.newImage.name = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.albumView = view;
		},

		changePage() {
			this.startGalleryLoader();
			if (this.filteredGalleries == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/gallery?page=" +
						this.galleryPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterGalleries(resp);
						this.stopGalleryLoader();
					})
					.catch((error) => {
						this.stopGalleryLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchGalleries(this.galleryPagination.current_page);
			}
		},
		changeAlbumPage() {
			this.albumLoader = true;
			if (this.filteredAlbums == true) {
				this.albumSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/album?gallery_id=" +
						this.assignedGallery.id +
						"&page=" +
						this.albumPagination.current_page +
						"&type=search&search=" +
						this.albumSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.albumLoader = false;

						this.albums = resp.data;
						this.albumPagination.current_page =
							resp.current_page;
						this.albumPagination.total = resp.total;
						this.albumPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.albumLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/album?gallery_id=" +
						this.assignedGallery.id +
						"&page=" +
						this.albumPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.albumLoader = false;

						this.albums = resp.data;
						this.albumPagination.current_page =
							resp.current_page;
						this.albumPagination.total = resp.total;
						this.albumPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.albumLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeImagePage() {
			this.imageLoader = true;
			apiCall({
				url:
					"/api/image?album_id=" +
					this.assignedAlbum.id +
					"&page=" +
					this.imagePagination.current_page,
				method: "GET",
			})
				.then((resp) => {
					this.imageLoader = false;

					this.images = resp.data;
					this.imagePagination.current_page =
						resp.current_page;
					this.imagePagination.total = resp.total;
					this.imagePagination.per_page = resp.per_page;
				})
				.catch((error) => {
					console.log(error);
					this.imageLoader = false;
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
				});
		},

		deleteGallery(item) {
			confirm("Are You Sure You Want to Delete Gallery?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.galleryIndex = item.id;
				apiCall({ url: "/api/gallery/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Gallery Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.galleryIndex = null;
						this.fetchGalleries(this.galleryPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.galleryIndex = null;
						console.log(error.response);
					});
			}
		},
		deleteAlbum(item) {
			confirm("Are You Sure You Want to Remove Album?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.albumIndex = item.id;
				apiCall({
					url: "/api/album/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Album Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.albumIndex = null;
						this.changeAlbumPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.albumIndex = null;
						console.log(error);
					});
			}
		},
		deleteImage(item) {
			confirm("Are You Sure You Want to Remove Image?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.imageIndex = item.id;
				apiCall({
					url: "/api/image/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Image Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.imageIndex = null;
						this.changeImagePage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.imageIndex = null;
						console.log(error);
					});
			}
		},

		activateGallery(item) {
			confirm("Are You Sure You Want to Activate Gallery?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.galleryIndex = item.id;
				this.newGallery.id = item.id;
				apiCall({
					url: "/api/gallery?type=activate",
					data: this.newGallery,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Gallery Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.galleryIndex = null;
						this.fetchGalleries(this.galleryPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.galleryIndex = null;
						console.log(error.response);
					});
			}
		},
		activateAlbum(item) {
			confirm(
				"Are You Sure You Want to Activate Album?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.albumIndex = item.id;
				this.newAlbum.id = item.id;
				apiCall({
					url: "/api/album?type=activate",
					data: this.newAlbum,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Album Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.albumIndex = null;
						this.newAlbum.id = null;
						this.changeAlbumPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.albumIndex = null;
						this.newAlbum.id = null;
						console.log(error);
					});
			}
		},

		deactivateGallery(item) {
			confirm("Are You Sure You Want to Deactivate Gallery?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.galleryIndex = item.id;
				this.newGallery.id = item.id;
				apiCall({
					url: "/api/gallery?type=deactivate",
					data: this.newGallery,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Gallery  Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.galleryIndex = null;
						this.fetchGalleries(this.galleryPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.galleryIndex = null;
						console.log(error);
					});
			}
		},
		deactivateAlbum(item) {
			confirm(
				"Are You Sure You Want to Deactivate Album?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.albumIndex = item.id;
				this.newAlbum.id = item.id;
				apiCall({
					url: "/api/album?type=deactivate",
					data: this.newAlbum,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Album Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.albumIndex = null;
						this.newAlbum.id = null;
						this.changeAlbumPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.albumIndex = null;
						this.newAlbum.id = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/gallery?type=adminFormData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["galleries", "galleryPagination", "galleryLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.galleryPagination.total / this.galleryPagination.per_page
			);
		},
		albumLength: function () {
			return Math.ceil(
				this.albumPagination.total /
				this.albumPagination.per_page
			);
		},
		imageLength: function () {
			return Math.ceil(
				this.imagePagination.total /
				this.imagePagination.per_page
			);
		},
		formatAlbumDate() {
			if (this.newAlbum.date != null) {
				const d = new Date(this.newAlbum.date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>