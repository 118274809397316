import Vue from 'vue'
import Vuex from 'vuex'

import header from './modules/header'

import notifications from './modules/notifications'
import darkMode from './modules/darkMode'

import statistics from './modules/statistics'
import role from './modules/role'
import users from './modules/users'
import permission from './modules/permission'
import user from './modules/user'
import auth from './modules/auth'
import log from './modules/log'
import logData from './modules/logData'

import abilities from './modules/abilities'
import loginCard from './modules/loginCard'

import question from './modules/question'
import program from './modules/program'
import application from './modules/application'
import trackApplication from './modules/trackApplication'
import group from './modules/group'
import contactUs from './modules/contactUs'

import feedback from './modules/feedback'

import gallery from './modules/gallery'
import image from './modules/image'
import story from './modules/story'
import video from './modules/video'
import team from './modules/team'
import newsFeed from './modules/newsFeed'
import pastCohort from './modules/pastCohort'
import competitor from './modules/competitor'

import VuexPersist from 'vuex-persist'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility

const vuexLocalStorage = new VuexPersist({
  key: 'sc-wit', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [
    vuexLocalStorage.plugin,
    abilityPlugin
  ],

  modules: {
    header,
    notifications,

    statistics,
    role,
    users,
    user,
    auth,
    permission,
    log,
    logData,
    abilities,
    loginCard,
    darkMode,

    question,
    program,
    application,
    trackApplication,
    group,
    contactUs,

    feedback,

    gallery,
    image,
    story,
    video,
    team,
    newsFeed,
    pastCohort,
    competitor,
  },
  strict: false,
})