import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        //dark: true,
        themes: {
            light: {
                primary: '#0A3161',
                secondary: '#B31942',   //#CFA645
                accent: '#525355',
                error: '#b71c1c',
                background: "#F7F7F7",
                header: "#fff",
                button: "#EEEEEE",
                universal: '#1e85f1',
                nav: '#1e85f1',
                excel: '#1D6F42',
                pdf: "#f40f02",
                text: '#FFF',
                tableHeader: '#B31942',
                goldie: "#C29B44",
                
            },
            dark: {
                primary: '#0A3161',
                secondary: '#B31942',
                accent: '#F7F7F7',
                error: '#b71c1c',
                background: "#121212",
                header: "#272727",
                button: "292929",
                universal: '#1e85f1',
                nav: '#0f1e3d',
                excel: '#1D6F42',
                pdf: "#f40f02",
                text: '#B31942',
                tableHeader: '#121212',
                goldie: "#C29B44",
            },
        },
    },
});
