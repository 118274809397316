import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import dotenv from 'dotenv'
import router from './router'
import store from './store'
import {ability} from '@/store'
import { abilitiesPlugin } from '@casl/vue'
import Echo from "laravel-echo";
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

Vue.config.productionTip = false
Vue.use(abilitiesPlugin, ability)

dotenv.config()

window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  //wsHost: window.location.hostname,
  wsHost: '127.0.0.1',
  wsPort: 6001,
  wssPort: 6001,
  enabledTransports: ['ws','wss'],
  disableStats:true,
  forceTLS: false,
  authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
  auth: {
    headers: {
      'Authorization': localStorage.getItem('user-token')
    }
  },
  
  
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
