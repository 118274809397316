import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/redirector')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: require("@/views/Dashboard.vue").default,
  },
  {
    path: "/redirector",
    name: "Redirector",
    component: require("@/views/Redirector.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/dashboard',
    name: 'AdminDashboard',
    component: require("@/views/admin/dashboard.vue").default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: require('@/views/Profile.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/roles',
    name: 'Roles',
    component: require('@/views/accessControl/role.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: require('@/views/accessControl/user.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/logs',
    name: 'Log',
    component: require('@/views/accessControl/log.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/questions',
    name: 'Question',
    component: require('@/views/admin/question.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/programs',
    name: 'Program',
    component: require('@/views/admin/program.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/groups',
    name: 'Group',
    component: require('@/views/admin/group.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/applications',
    name: 'Application',
    component: require('@/views/admin/application.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/track-applications',
    name: 'TrackApplication',
    component: require('@/views/admin/trackApplication.vue').default,
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: '/cohort-7/apply-now',
  //   name: 'ApplyNow',
  //   component: require('@/views/ApplyNow.vue').default,
  //   beforeEnter: ifAuthenticated,
  // },

  {
    path: '/contact-us-admin',
    name: 'contactUsAdmin',
    component: require('@/views/admin/contactUsAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/gallery-admin',
    name: 'galleryAdmin',
    component: require('@/views/admin/galleryAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/story-admin',
    name: 'storyAdmin',
    component: require('@/views/admin/storyAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/team-admin',
    name: 'teamAdmin',
    component: require('@/views/admin/teamAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/news-feed-admin',
    name: 'newsFeedAdmin',
    component: require('@/views/admin/newsFeedAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/past-cohort-admin',
    name: 'pastCohortAdmin',
    component: require('@/views/admin/pastCohortAdmin.vue').default,
    beforeEnter: ifAuthenticated,
  },

  {
    path: '/about',
    name: 'About',
    component: require("@/views/About.vue").default,
  },
  {
    path: '/past-cohorts/:slug',
    name: 'pastCohort',
    component: require("@/views/pastCohort.vue").default,
  },


  // {
  //   path: '/cohort-6',
  //   name: 'Cohort6',
  //   component: require("@/views/Cohort6.vue").default,
  // },
  // {
  //   path: '/cohort-7',
  //   name: 'Cohort7',
  //   component: require("@/views/Cohort7.vue").default,
  // },
  {
    path: '/news-feed',
    name: 'NewsFeed',
    component: require("@/views/NewsFeed.vue").default,
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: require("@/views/Gallery.vue").default,
  },
  {
    path: '/stories',
    name: 'Stories',
    component: require("@/views/Stories.vue").default,
  },
  {
    path: '/team',
    name: 'Team',
    component: require("@/views/Team.vue").default,
  },
  {
    path: '/trainers',
    name: 'Trainers',
    component: require("@/views/Trainers.vue").default,
  },
  {
    path: '/coaches',
    name: 'Coaches',
    component: require("@/views/Coaches.vue").default,
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: require("@/views/FAQs.vue").default,
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: require("@/views/TermsAndConditions.vue").default,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPass',
    component: require("@/components/auth/resetPass.vue").default,
    beforeEnter: ifNotAuthenticated,
  },
  { path: "*", 
    name: 'PageNotFound',
    component: require("@/views/PageNotFound.vue").default,
  },
  {
    path: '/cohort-1and2',
    name: 'Cohort1',
    component: require("@/views/weipCohort1.vue").default,
  },
  {
    path: '/cohort-1and2/apply-now',
    name: 'ApplyNow',
    component: require('@/views/ApplyNow.vue').default,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/cohort-3-4-5',
    name: 'Cohort345',
    component: require("@/views/weipCohort3to5.vue").default,
  },
  {
    path: '/cohort-3-4-5/apply-now',
    name: 'ApplyNow',
    component: require('@/views/ApplyNow.vue').default,
    beforeEnter: ifAuthenticated,
  },
]

const router = new VueRouter({
  routes
})

export default router
