<template>
	<div class="stories" v-if="$can('story_view')">
		<v-dialog
			transition="dialog-bottom-transition"
			max-width="600"
			v-model="imageDialog"
		>
			<v-card max-width="600">
			<v-toolbar flat>
				Upload Image
				<v-spacer></v-spacer>
				<v-btn icon @click="imageDialog = false">
				<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
	
			<v-form ref="imageForm" v-model="imageValid" lazy-validation>
				<v-layout column>
				<v-flex xs12>
					<v-file-input
					class="mx-2 mt-3"
					:loading="imageLoading"
					:rules="fileRules"
					filled
					dense
					show-size
					chips
					accept="image/*"
					v-model="imageFile"
					label="Select Image"
					></v-file-input>
				</v-flex>
				<v-flex xs12>
					<div align="right" class="mb-3 mx-3">
					<v-btn
						depressed
						block
						class="primary text-none"
						@click="uploadImage"
						:loading="imageLoading"
						:disabled="!imageValid"
					>
						Submit <v-icon right dark>mdi-upload</v-icon>
					</v-btn>
					</div>
				</v-flex>
				</v-layout>
			</v-form>
			</v-card>
		</v-dialog>
        <v-dialog
			transition="dialog-bottom-transition"
			max-width="600"
			v-model="videoDialog"
		>
			<v-card max-width="600">
			<v-toolbar flat>
				Upload Video
				<v-spacer></v-spacer>
				<v-btn icon @click="videoDialog = false">
				<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
	
			<v-form ref="imageForm" v-model="imageValid" lazy-validation>
				<v-layout column>
				<v-flex xs12>
					<v-file-input
					class="mx-2 mt-3"
					:loading="imageLoading"
					:rules="fileRules"
					filled
					dense
					show-size
					chips
					accept="video/*"
					v-model="imageFile"
					label="Select Video"
					></v-file-input>
				</v-flex>
				<v-flex xs12>
					<div align="right" class="mb-3 mx-3">
					<v-btn
						depressed
						block
						class="primary text-none"
						@click="uploadImage"
						:loading="imageLoading"
						:disabled="!imageValid"
					>
						Submit <v-icon right dark>mdi-upload</v-icon>
					</v-btn>
					</div>
				</v-flex>
				</v-layout>
			</v-form>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>Stories</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()"
												v-on:keyup.enter="search" v-model="searchTerm"
												@input="enableSearch()"></v-text-field>
										</div>

									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block
												@click="search" :loading="searchLoader"
												:disabled="searchButtonDisabled">
												Search
												<v-icon right>mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none"
												@click="changeView('create')" v-if="$can('story_create')">
												Add Story
												<v-icon right>mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="storyLoader" height="1" indeterminate
								color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="stories.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Story Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">#</th>
														<th class="text-left text--text">Name</th>
														<th class="text-left text--text">No. of Video(s)</th>
														<th class="text-left text--text">Active</th>
														<th class="text-left text--text">Date Added</th>
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in stories" :key="item.id">
														<td>
															{{ index + 1 }}
														</td>
														<td>{{ item.name }} </td>
														<td>{{ item.videos.length }}</td>
														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<td>
															{{
		item.created_at | moment("DD/MM/YYYY - hh:mm a")
	}}
														</td>
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('story_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text"
																			@click="showStory(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('story_edit')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text"
																			@click="editStory(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('story_archive')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text"
																			@click="deleteStory(item)"
																			:loading="loading && storyIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top
																	v-if="$can('story_activate') && item.active == 0">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text"
																			@click="activateStory(item)"
																			:loading="activateLoading && storyIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate Story</span>
																</v-tooltip>
																<v-tooltip top
																	v-if="$can('story_deactivate') && item.active == 1">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text"
																			@click="deactivateStory(item)"
																			:loading="deactivateLoading && storyIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate Story</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text"
																			@click="viewLog(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>

										<template v-for="(story, index) in stories">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title primary--text ml-3">
																				<b>{{ story.name }} </b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && storyIndex == story.id
		" icon @click="deleteStory(story)" v-if="$can('story_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>No. of Video(s)</b>
																			</div>
																			<div>{{ story.videos.length }}</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="story.active == 1">Yes
																				</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Date Added</b>
																			</div>
																			<div>
																				{{
		story.created_at
		| moment("DD/MM/YYYY - hh:mm a")
	}}
																			</div>
																		</v-flex>



																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn
																							v-if="$can('story_view')"
																							depressed class="
																							primary
																							text-none
																							white--text
																							" block @click="showStory(story)">View
																							<v-icon right> mdi-eye
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="blue text-none white--text"
																							block
																							@click="editStory(story)"
																							v-if="$can('story_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="green text-none white--text"
																							block
																							@click="activateStory(story)"
																							:loading="activateLoading && storyIndex == story.id"
																							v-if="$can('story_activate') && story.active == 0">Activate
																							Story
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="red text-none white--text"
																							block
																							@click="deactivateStory(story)"
																							:loading="deactivateLoading && storyIndex == story.id"
																							v-if="$can('story_deactivate') && story.active == 1">Deactivate
																							Story
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')"
																							depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(story)">Log
																							<v-icon right>
																								mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="storyPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ storyPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newStory.id == null">Add Story</div>
								<div v-else>Edit Story</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-5">
												<v-flex xs12 md8>
													<div class="mx-1 mt-5">
														<div>Name<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense
															v-model="newStory.name"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>
                                                <v-flex xs12 md4>
                                                    <div class="mx-1 mt-5">
                                                        <div>Year</div>
                                                        <v-select :items="years" outlined dense v-model="newStory.year"
                                                            prepend-inner-icon="mdi-calendar" class="background login_field"></v-select>
                                                    </div>
                                                </v-flex>
											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
									:loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedStory.name }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="saleTab">
									<v-tabs-slider color="primary"></v-tabs-slider>

									<v-tab @click="activeTab = 'details'">
										<div class="text-none primary--text">Details</div>
									</v-tab>
									<v-tab @click="activeTab = 'videos'">
										<div class="text-none primary--text">
											Videos ({{ assignedStory.videos.length }})
										</div>
									</v-tab>
								</v-tabs>
								<div v-if="activeTab == 'details'" class="mx-3 mt-7">
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Name:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedStory.name }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Videos:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedStory.videos.length }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Date Created:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													{{ assignedStory.created_at | moment("DD/MM/YYYY - hh:mm a") }}
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12 class="mt-5">
											<v-layout row wrap>
												<v-flex xs12 md3>
													<div><b>Active:</b></div>
												</v-flex>
												<v-flex xs12 md9>
													<div v-if="assignedStory.active == 1">Yes</div>
													<div v-else>No</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>
								</div>
								<div v-if="activeTab == 'videos'" class="mx-1">
									<div v-if="videoView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<div v-if="videoLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{
																videoPagination.total |
																formatNumber }}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field header" outlined dense
																	label="Search" append-icon="mdi-undo-variant"
																	@click:append="
																		resetVideoSearch()
																		" v-on:keyup.enter="videoSearch
																		" v-model="videoSearchTerm" @input="
																		enableVideoSearch()
																		"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn class="secondary white--text text-none" depressed
																	block @click="videoSearch" :loading="videoSearchLoader
																	" :disabled="videoSearchButtonDisabled">
																	Search
																	<v-icon right small class="white--text">mdi-magnify</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1" align="right">
																<v-btn block depressed class="primary text-none" @click="changeVideoView('create')
																	" v-if="$can('video_create')">
																	Add Video
																	<v-icon right small
																		class="white--text">mdi-plus-circle-outline</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="videos.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Video Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>

																<template v-slot:default>
																	<thead class="header">
																		<tr class="secondary">
																			<th class="text-left text--text">Name</th>
																			<th class="text-right text--text">Date</th>
																			<th class="text-left text--text">Date Added
																			</th>
																			<th class="text-left text--text">Status</th>
																			<th class="text-right text--text">Actions
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="item in videos" :key="item.id">
																			<td>
																				{{ item.name }}
																			</td>

																			<td>
																				<div align="right">
																					{{ item.date | moment("DD/MM/YYYY")
																					}}
																				</div>
																			</td>
																			<td>
																				{{ item.created_at | moment("DD/MM/YYYY - hh: mm a") }}
																			</td>
																			<td>
																				<div v-if="item.active == 1">
																					Active
																				</div>
																				<div v-else>Inactive</div>
																			</td>
																			<td>
																				<div align="right">
																					<v-btn v-if="$can('video_view')"
																						icon class="
																								button
																								mr-1
																								primary--text
																							" @click="showVideo(item)">
																						<v-icon small> mdi-eye </v-icon>
																					</v-btn>
																					<v-btn v-if="$can('video_edit')"
																						icon
																						class="button mr-1 success--text"
																						@click="editVideo(item)">
																						<v-icon small> mdi-pencil
																						</v-icon>
																					</v-btn>
																					<v-btn v-if="$can(
																						'video_archive'
																					)
																						" icon class="button mr-1 error--text" @click="
																						deleteVideo(
																							item
																						)
																						" :loading="loading &&
																						videoIndex ==
																						item.id
																						">
																						<v-icon small> mdi-delete
																						</v-icon>
																					</v-btn>
																					<v-tooltip top v-if="$can(
																							'video_activate'
																						) && item.active == 0
																							">
																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 red--text"
																								@click="
																									activateVideo(
																										item
																									)
																									" :loading="activateLoading &&
																									videoIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch-off-outline
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Activate Video</span>
																					</v-tooltip>
																					<v-tooltip top v-if="$can(
																						'video_deactivate'
																					) && item.active == 1
																						">

																						<template
																							v-slot:activator="{ on, attrs }">
																							<v-btn icon
																								class="button mr-1 green--text"
																								@click="
																									deactivateVideo(
																										item
																									)
																									" :loading="deactivateLoading &&
																									videoIndex ==
																									item.id
																									" v-bind="attrs" v-on="on">
																								<v-icon small>
																									mdi-toggle-switch
																								</v-icon>
																							</v-btn>
																						</template>
																						<span>Deactivate Video</span>
																					</v-tooltip>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>

																<template v-for="(
                                      video, index
                                    ) in videos">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0"
																				style="border-radius: 25px;" outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div
																										class="title primary--text">
																										<b>{{
																											video.name
																										}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed
																										class="red--text"
																										:loading="loading &&
																											videoIndex ==
																											video.id
																											" icon @click="
																											deleteVideo(
																												video
																											)
																											" v-if="$can(
																												'video_archive'
																											)
																											">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider
																							class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
																												video.date |
																												moment("DD/MM/YYYY")
																											}}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Status</b>
																										</v-flex>
																										<v-flex xs8>
																											<div v-if="video.status ==
		1
		">
																												Active
																											</div>
																											<div v-else>
																												Inactive
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs4>
																											<b>Date
																												Created</b>
																										</v-flex>
																										<v-flex xs8>
																											{{
		video.created_at
		|
		moment("DD/MM/YYYY")
	}}
																										</v-flex>
																									</v-layout>
																								</v-flex>

																								<v-flex xs12
																									class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12
																											v-if="$can('video_edit')">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="blue darken-1 text-none white--text mt-1"
																													block
																													@click="
		editVideo(
			video
		)
		">Edit
																													<v-icon
																														right>
																														mdi-pencil
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'video_activate'
	) && video.active == 0
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="green darken-1 text-none white--text mt-1"
																													block
																													:loading="activateLoading &&
		videoIndex ==
		video.id
		" @click="
		activateVideo(
			video
		)
		">Activate Video
																													<v-icon
																														right>
																														mdi-toggle-switch
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																										<v-flex xs12
																											v-if="$can(
		'video_deactivate'
	) && video.active == 1
		">
																											<div>
																												<v-btn
																													rounded
																													depressed
																													class="red darken-1 text-none white--text mt-1"
																													block
																													:loading="deactivateLoading &&
		videoIndex ==
		video.id
		" @click="
		deactivateVideo(
			video
		)
		">Deactivate Video
																													<v-icon
																														right>
																														mdi-toggle-switch-off-outline
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="videoLength"
														total-visible="10" v-model="videoPagination.current_page
		" @input="changeVideoPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="videoView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="secondary text--text">
												<v-spacer></v-spacer>
												<div v-if="newVideo.id == null">
													Add Video
												</div>
												<div v-else>Edit Video</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
		changeVideoView('default')
		">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="videoForm" lazy-validation>
													<v-layout row wrap class="mt-5">

														<v-flex xs12 md6>
															<div class="pa-3">
																<div>
																	Name<span class="error--text">*</span>
																</div>
																<v-text-field class="text_field background my-2"
																	outlined dense v-model="newVideo.name"
																	:rules="inputRules"></v-text-field>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveVideo"
													:disabled="!valid" :loading="loading">
													Save
													<v-icon right> mdi-content-save </v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
									<div v-if="videoView == 'show'">
										<v-card outlined class="mt-5">
											<v-card-title class="tableHeader text--text">
												{{ assignedVideo.name }}
												<v-spacer></v-spacer>
												<v-btn icon @click="changeVideoView('default')">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<div class="pa-3 mt-5">
													<v-layout row wrap>
                                                        <v-flex xs12 md6>
                                                            <div align="center">Cover Image</div>
                                                            <v-card elevation="0" class="mx-1 my-1 primary" @click="activateDialog('image')"
																	style="border-radius: 25px;" height="600"
																	>
																	<div v-if="assignedVideo.cover_url==null"
																		class="d-flex flex-column fill-height justify-center align-center text-white">
																		<v-icon large
																			color="white">mdi-image-plus</v-icon>
																	</div>
                                                                    <div align="center" v-else>
                                                                        <v-img  max-width="100%" max-height="500" :src="path + '/storage/video_cover/' + assignedVideo.cover_url" style="border-radius: 25px;"/>
                                                                    </div>
                                                                    
																</v-card>
                                                        </v-flex>
														<v-flex xs12 md6>
                                                            <div align="center">Video</div>
                                                            <v-card elevation="0" class="mx-1 my-1 secondary" @click="activateDialog('video')"
																	style="border-radius: 25px;" height="600"
																	>
																	<div v-if="assignedVideo.video_url==null"
																		class="d-flex flex-column fill-height justify-center align-center text-white">
																		<v-icon large
																			color="white">mdi-video-plus</v-icon>
																	</div>
                                                                    <div align="center" v-else>
                                                                        <video style="border-radius: 25px;" :poster="path + '/storage/video_cover/' + assignedVideo.cover_url" :src="path + '/storage/videos/' + assignedVideo.video_url" width="100%" controls
                        autoplay></video>
                                                                    </div>
																</v-card>
                                                        </v-flex>
													</v-layout>
												</div>
											</v-card-text>
										</v-card>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			videoView: "default",
			activeTab: 'details',
			saleTab: null,

			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			videoLoader: false,
			imageLoader: false,

			videoDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			storyIndex: null,
			videoIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
            videoDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			images: [],
			videos: [],

			searchTerm: null,
			videoSearchTerm: null,

			searchLoader: false,
			videoSearchLoader: false,

			searchButtonDisabled: false,
			videoSearchButtonDisabled: false,

			filteredStories: false,
			filteredVideos: false,

			newStory: {
				id: null,
				name: null,
			},
			newVideo: {
				id: null,
				story_id: null,
				name: null,
			},
			newImage: {
				id: null,
				video_id: null,
				name: null,
			},

			assignedStory: null,
			assignedVideo: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Story",
			},

			videoPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			imagePagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
            years: [],

            uploadType: null

		};
	},
	created() {
		if (this.$can("story_view")) {
			this.startStoryLoader();
			this.fetchStories(this.storyPagination.current_page);
			this.getFormData();

            this.populateYears();
		}
	},
	methods: {
		...mapActions([
			"fetchStories",
			"startStoryLoader",
			"stopStoryLoader",
			"filterStories",

			"filterLogData",
			"openLogDataModel",
		]),
		closeVideoDate() {
			this.videoDatePicker = false;
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},
		enableVideoSearch() {
			this.videoSearchButtonDisabled = false;
		},

        activateDialog(type){
            this.uploadType = type
            if(type=='image'){
                this.imageDialog = true
            }else{
                this.videoDialog = true
            }
        },

		resetSearch() {
			this.searchTerm = null;
			this.filteredStories = true;
			this.startStoryLoader();
			this.fetchStories(1);
		},
		resetVideoSearch() {
			this.videoSearchTerm = null;
			this.filteredVideos = false;
			this.videoLoader = false;
			this.changeVideoPage();
		},

		showStory(story) {
			this.assignedStory = story;

			this.changeVideoPage();
			this.changeView("show");
		},
		showVideo(video) {
			this.assignedVideo = video;

			this.changeImagePage();
			this.changeImageView("show");
		},

		editStory(story) {
			this.lockEmail = true;
			this.newStory.id = story.id;
			this.newStory.name = story.name;

			this.changeView("create");
		},
		editVideo(video) {
			this.newVideo.id = video.id;
			this.newVideo.story_id = video.story_id;
			this.newVideo.name = video.name;
			this.newVideo.date = video.date;

			this.changeVideoView("create");
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
			this.imageLoading = true;
			let formData = new FormData();
			
			formData.append("id", this.assignedVideo.id)	
			formData.append("files", this.imageFile, this.imageFile.name);
	
			apiCall({
				url: "/api/video?type="+this.uploadType,
				data: formData,
				method: "POST",
			})
				.then((resp) => {
					this.assignedVideo = resp
				this.snackbar = true;
				this.message = "Image Uploaded Successfully";
				this.color = "success";
				this.changeImagePage()
				this.imageLoading = false;
				this.imageDialog = false;
                this.videoDialog = false
				this.imageFile = null
				})
				.catch((error) => {
				this.imageLoading = false;
				});
			}
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newStory.id != null) {
					confirm("Are You Sure You Want to Update Story") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/story/" + this.newStory.id,
							data: this.newStory,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Story Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchStories(this.storyPagination.current_page);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are You Sure You Want to Create Story?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/story",
							data: this.newStory,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.alertType = "error";
								} else {
									this.message = "Story Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchStories(1);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchStories(1);
							});
					}
				}
			}
		},
		saveVideo() {
			if (this.$refs.videoForm.validate()) {
				if (this.newVideo.id != null) {
					confirm("Are you sure you want to update video?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/video/" + this.newVideo.id,
							data: this.newVideo,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Video Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeVideoView("default");
								this.changeVideoPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {


					confirm("Are you sure you want to add Video?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						this.newVideo.story_id = this.assignedStory.id;
						apiCall({
							url: "/api/video",
							data: this.newVideo,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Video Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.newVideo.story_id = null;
								this.changeVideoView("default");
								this.changeVideoPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startStoryLoader();
				apiCall({
					url: "/api/story?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterStories(resp);
						this.searchLoader = false;
						this.stopStoryLoader();
						this.filteredStories = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopStoryLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		videoSearch() {
			if (this.videoSearchTerm == null) {
				this.videoSearchButtonDisabled = true;
			} else {
				this.videoSearchLoader = true;
				this.videoSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/video?type=search&search=" +
						this.videoSearchTerm +
						"&story_id=" +
						this.assignedStory.id,
					method: "GET",
				})
					.then((resp) => {
						this.videos = resp.data;
						this.videoPagination.current_page =
							resp.current_page;
						this.videoPagination.total = resp.total;
						this.videoPagination.per_page = resp.per_page;

						this.videoSearchLoader = false;
						this.filteredVideos = true;
					})
					.catch((error) => {
						this.videoSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.lockEmail = false;
				this.newStory.id = null;
				this.newStory.name = null;
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},
		changeVideoView(view) {
			if (view == "default") {
				this.newVideo.id = null;
				this.newVideo.story_id = null;
				this.newVideo.name = null;
				this.newVideo.date = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.videoView = view;
		},
		changeImageView(view) {
			if (view == "default") {
				this.newImage.id = null;
				this.newImage.video_id = null;
				this.newImage.name = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.videoView = view;
		},

		changePage() {
			this.startStoryLoader();
			if (this.filteredStories == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/story?page=" +
						this.storyPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterStories(resp);
						this.stopStoryLoader();
					})
					.catch((error) => {
						this.stopStoryLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchStories(this.storyPagination.current_page);
			}
		},
		changeVideoPage() {
			this.videoLoader = true;
			if (this.filteredVideos == true) {
				this.videoSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/video?story_id=" +
						this.assignedStory.id +
						"&page=" +
						this.videoPagination.current_page +
						"&type=search&search=" +
						this.videoSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.videoLoader = false;

						this.videos = resp.data;
						this.videoPagination.current_page =
							resp.current_page;
						this.videoPagination.total = resp.total;
						this.videoPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.videoLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/video?story_id=" +
						this.assignedStory.id +
						"&page=" +
						this.videoPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.videoLoader = false;

						this.videos = resp.data;
						this.videoPagination.current_page =
							resp.current_page;
						this.videoPagination.total = resp.total;
						this.videoPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.videoLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeImagePage() {
			this.imageLoader = true;
			apiCall({
				url:
					"/api/image?video_id=" +
					this.assignedVideo.id +
					"&page=" +
					this.imagePagination.current_page,
				method: "GET",
			})
				.then((resp) => {
					this.imageLoader = false;

					this.images = resp.data;
					this.imagePagination.current_page =
						resp.current_page;
					this.imagePagination.total = resp.total;
					this.imagePagination.per_page = resp.per_page;
				})
				.catch((error) => {
					console.log(error);
					this.imageLoader = false;
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
				});
		},

		deleteStory(item) {
			confirm("Are You Sure You Want to Delete Story?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.storyIndex = item.id;
				apiCall({ url: "/api/story/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Story Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.storyIndex = null;
						this.fetchStories(this.storyPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.storyIndex = null;
						console.log(error.response);
					});
			}
		},
		deleteVideo(item) {
			confirm("Are You Sure You Want to Remove Video?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.videoIndex = item.id;
				apiCall({
					url: "/api/video/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Video Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.videoIndex = null;
						this.changeVideoPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.videoIndex = null;
						console.log(error);
					});
			}
		},

		activateStory(item) {
			confirm("Are You Sure You Want to Activate Story?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.storyIndex = item.id;
				this.newStory.id = item.id;
				apiCall({
					url: "/api/story?type=activate",
					data: this.newStory,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Story Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.storyIndex = null;
						this.fetchStories(this.storyPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.storyIndex = null;
						console.log(error.response);
					});
			}
		},
		activateVideo(item) {
			confirm(
				"Are You Sure You Want to Activate Video?"
			) && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.videoIndex = item.id;
				this.newVideo.id = item.id;
				apiCall({
					url: "/api/video?type=activate",
					data: this.newVideo,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Video Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.videoIndex = null;
						this.newVideo.id = null;
						this.changeVideoPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.videoIndex = null;
						this.newVideo.id = null;
						console.log(error);
					});
			}
		},

		deactivateStory(item) {
			confirm("Are You Sure You Want to Deactivate Story?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.storyIndex = item.id;
				this.newStory.id = item.id;
				apiCall({
					url: "/api/story?type=deactivate",
					data: this.newStory,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Story  Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.storyIndex = null;
						this.fetchStories(this.storyPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.storyIndex = null;
						console.log(error);
					});
			}
		},
		deactivateVideo(item) {
			confirm(
				"Are You Sure You Want to Deactivate Video?"
			) && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.videoIndex = item.id;
				this.newVideo.id = item.id;
				apiCall({
					url: "/api/video?type=deactivate",
					data: this.newVideo,
					method: "POST",
				})
					.then((resp) => {
						this.message = "Video Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.videoIndex = null;
						this.newVideo.id = null;
						this.changeVideoPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.videoIndex = null;
						this.newVideo.id = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/story?type=adminFormData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["stories", "storyPagination", "storyLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.storyPagination.total / this.storyPagination.per_page
			);
		},
		videoLength: function () {
			return Math.ceil(
				this.videoPagination.total /
				this.videoPagination.per_page
			);
		},
		imageLength: function () {
			return Math.ceil(
				this.imagePagination.total /
				this.imagePagination.per_page
			);
		},
        populateYears() {
			var max = new Date().getFullYear();
			var min = max - 100;
			var years = [];

			for (var i = max; i >= min; i--) {
				years.push(i);
			}
			this.years = years;
		},
		formatVideoDate() {
			if (this.newVideo.date != null) {
				const d = new Date(this.newVideo.date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>