import apiCall from '@/utils/api'

const state = {
	programs: [],
	allPrograms: [],
    programPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    programLoader: false
};

const getters = {
	programs: (state) => state.programs,
	allPrograms: (state) => state.allPrograms,
    programLoader: (state) => state.programLoader,
    programPagination: (state) => state.programPagination
}

const actions = {
	async fetchAllPrograms({commit}, page) {
		const response = await apiCall({url: `/api/program?type=all`, method: 'GET' });
		commit('setAllPrograms', response)
        commit('stopLoader', response)
	},
    async fetchPrograms({commit}, page) {
		const response = await apiCall({url: `/api/program?page=${page}`, method: 'GET' });
		commit('setPrograms', response)
        commit('stopLoader', response)
	},
    async filterPrograms({commit, state},resp){
		commit('setPrograms', resp)
	},
	async startProgramLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopProgramLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setPrograms: (state, programs) => {
		state.programs = programs.data
		state.programPagination.current_page = programs.current_page
		state.programPagination.total = programs.total
		state.programPagination.per_page = programs.per_page
	},
	setAllPrograms: (state, programs) => {
		state.allPrograms = programs
	},
    startLoader: (state) => state.programLoader = true,
	stopLoader: (state) => state.programLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
